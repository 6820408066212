import { Component, computed, input, output } from '@angular/core';
import { RadioSelectEmitter, RadioSelectInformational } from "src/app/modules/shared/components/select-radio/select-radio.types";
import { SelectOption } from "src/app/services/select-option.service";
import { AbstractControl } from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";


@Component({
  selector: 'app-select-radio',
  templateUrl: './select-radio.component.html',
  styleUrls: ['./select-radio.component.css']
})
export class SelectRadioComponent {
  disabled = input(false);
  required = input(false);
  events = output<RadioSelectEmitter>();
  control = input<AbstractControl>();
  informational = input<RadioSelectInformational>({active: false});
  isLoading = input(false);
  label = input<string>();
  name = input.required<string>();
  alignment = input<{flex: 'col' | 'row'} | {grid: number | 'auto'}>({flex: 'col'});
  private readonly OPTIONS_DEFAULT = [{value: true, label: "Yes"}, {value: false, label: "No"}];
  options = input<SelectOption[], SelectOption[] | undefined>(this.OPTIONS_DEFAULT, {transform: input => input ?? this.OPTIONS_DEFAULT});
  radioGroupClass = computed(() => {
    const alignment = this.alignment();
    if (alignment['grid']) {
      return alignment['grid'] == 'auto'
        ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7'
        : 'grid grid-cols-' + alignment['grid'];
    }
    return `flex ${alignment['flex'] == 'col' ? 'flex-col' : ''}`;
  });

  protected onChange(radioChange: MatRadioChange) {
    this.control()?.setValue(radioChange.value);
    this.events.emit({change: this.options().find(e => e.value == radioChange.value)});
  }

  protected onInformational() {
    this.events.emit({informationalClicked: true});
  }
}

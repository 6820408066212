import { Component, ElementRef, input, OnInit } from '@angular/core';
import { Onfido } from 'onfido-sdk-ui';
import { ApiOnfidoService } from "src/app/services/api-services/api-onfido.service";
import { Handle } from "onfido-sdk-ui/types/Onfido";
import { IOnfidoOptions } from 'src/app/services/api-services/types/api-onfido.types';


@Component({
  selector: 'app-onfido-modal',
  templateUrl: './onfido-modal.component.html',
  styleUrls: ['./onfido-modal.component.css']
})
export class OnfidoModalComponent implements OnInit {
  clientOptions = input.required<IOnfidoOptions>({alias: 'onfidoOptions'});
  id = input.required<string>();
  private clientInitialized: boolean = false;
  private loadedOptions?: IOnfidoOptions;
  private onfido?: Handle;

  constructor(private readonly apiOnfido: ApiOnfidoService, readonly eRef: ElementRef) {
    (eRef.nativeElement as HTMLElement).id = '';
  }

  initOnfido() {
    this.clientInitialized = true;
    this.initializeOnfidoApi();
  }

  ngOnInit() {
    this.getSdkToken();
    addEventListener('userAnalyticsEvent', (event: any) => console.debug('event', event.detail));
  }

  private getSdkToken() {
    this.apiOnfido.getSdkWorkflow().subscribe({
      next: r => {
        this.loadedOptions = {
          workflowRunId: r.data.workflow_run_id,
          token: r.data.sdk_token
        };
      },
      complete: () => this.initializeOnfidoApi()
    });
  }

  private initializeOnfidoApi() {
    if (this.clientInitialized && this.loadedOptions) {
      let options = {
        ...this.clientOptions(),
        ...this.loadedOptions,
        containerId: 'onfido-mount',
        cobrand: {text: "NotifyNOW"},
        logoCobrand: { // TODO
          darkLogoSrc: "https://notifynow.com/assets/landing/images/tdr/NotifyNOW_2C_Logo.png",
          lightLogoSrc: "https://notifynow.com/assets/landing/images/tdr/NotifyNOW_2C_Logo.png"
        }
      };
      console.debug("Options: ", options);
      const fnInit = () => this.onfido = Onfido.init(options);
      if (this.onfido)
        this.onfido.tearDown().then(() => fnInit());
      else
        fnInit();
    }
  }

}

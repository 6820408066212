import { SelectOption } from "src/app/services/select-option.service";
import { ConditionalGroup } from "src/app/services/api-services/conditional-evaluator.types";

export const NON_INPUT_TYPES = ["none", "paragraph"];
/**
 * Defines how to use the associated {@link FormElement}.
 * @usageNotes
 * "paragraph" — This element will simply insert an HTML string as a paragraph.<br>
 * "input" — This element will take an input from a user. <br>
 * "none" — This element will not have any impact on the form itself, but it may still have sub_elements that do. This
 * would allow grouped elements without an explicit parent element
 */
type AnyElement = ParagraphElement | InputElement | NoneElement;
type ElementType = "paragraph" | "input" | "none";
/**
 * "text" — A simple text input.<br>
 * "password" — A simple text input that will be hidden (ie "********").
 * "number" — A number input. The value returned will be a number. <br>
 * "date" — A date input, format of ISO-8601. <br>
 * "datetime-local" — A datetime input, format of ISO-8601. <br>
 * "email" — A text input in an email format. <br>
 * "radio" — A radio-button selection. Requires {@link InputElement.options} <br>
 * "checkbox" — A checkbox multi-selection. Requires {@link InputElement.options} <br>
 * "dropdown" — A dropdown selection. Requires {@link InputElement.options} <br>
 * "multiselect-dropdown" — A dropdown multi-selection. Requires {@link InputElement.options} <br>
 */
export type InputType =
  "text"
  | "number"
  | "password"
  | "date"
  | "datetime-local"
  | "email"
  | "phone-number"
  | "radio"
  | "checkbox"
  | "dropdown"
  | "multiselect-dropdown";


/** Defines what types of elements there may be. */
export interface BaseElement {
  type: ElementType;
}


/** 'none' element has no extra attributes. */
export interface NoneElement extends BaseElement {
  type: 'none';
}


/** 'Paragraph' element takes an HTML string. */
export interface ParagraphElement extends BaseElement {
  html: string;
  type: "paragraph";
}


/** 'Input' element creates an input in the form */
export interface InputElement extends BaseElement {
  /** Identifier used to relate this input with the returned value. */
  input_id: string;
  /** Default value of this element. */
  default_value: unknown;
  /** What time of input is being collected. */
  input_type: InputType;
  /** Label text for this element. If `null`, this will not be displayed */
  label?: string;
  /**
   * An array of {@link SelectOption SelectOptions} defining input options, should the {@link element} require this
   * attribute.
   */
  options: SelectOption[];
  /** Placeholder text for this element. If `null`, this will not be displayed */
  placeholder?: string;
  type: "input";
  validation: {
    /** Specifies the maximum value for an input element */
    max?: string;
    /** Specifies the maximum number of character for an input element */
    max_length?: number;
    /** Specifies the minimum value for an input element */
    min?: string;
    /** Specifies the minimum number of character for an input element */
    min_length?: number;
    /** Specifies a regular expression to check the input value against */
    pattern?: string;
    /** Specifies that an input element is required (must be filled out) */
    required: boolean;
    /** Specifies the legal number intervals for a number input element */
    step?: number;
    /** Specifies the default value for an input element */
    value?: unknown;
    /**
     * If `true`, then the user will be required to enter the input twice, and will fail validation if the two entries
     * do not match.
     */
    confirm: boolean;
    /**
     * Custom conditions that utilize {@link ConditionalGroup ConditionalGroups} to validate. This can be used in place
     * of most of the other validators, or alongside of the other validators. Using this attribute, comparisons against
     * other elements can be used.
     */
    custom_conditions: ConditionalGroup[];
  };
}


export interface FormElement {
  /** What this element should be represented as. */
  element_data: AnyElement;
  /**
   * An array of {@link ConditionalGroup ConditionalGroups} that govern whether this element is active. If not active,
   * this element will not be displayed & any inputs will not be required. If any are false, this element will not be
   * displayed. If the list is empty, it is considered `true`. <br>
   * If these conditions resolve to `false`, then this element will not be considered as required regardless of the
   * {@link validation} attribute.
   */
  show_element: ConditionalGroup[];
  /**
   * These are additional {@link ApiDynamicFormElement definitions} that will display as a subgroup of elements after
   * this element. <br> If this form is hidden (when any {@link show_element} fail) then the sub-elements will also be
   * hidden.
   */
  sub_elements: FormElement[];
}


export type DynamicForm = FormElement[];
export interface DynamicFormLang {
  form: DynamicForm,
  lang: Object
}

export interface AccountForm {
  account_type_id: string;
  account_name: string;
  form: DynamicForm;
}



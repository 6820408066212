import { Component, ElementRef, input } from '@angular/core';
import { ApiTypesService } from 'src/app/services/api-services/api-types.service';
import { SelectDropdownComponent } from "src/app/modules/shared/components/select-dropdown/select-dropdown.component";


@Component({
  selector: 'app-select-state',
  templateUrl: '../select-dropdown.component.html',
  styleUrls: ['../select-dropdown.component.css']
})
export class SelectStateComponent extends SelectDropdownComponent {
  countryCode = input<number | undefined, number | undefined>(10, {
    transform: code => {
      if (code != undefined) this.getStates(code);
      return code;
    }
  });
  override placeholder = input('Select Your State');
  override showSearchBox = input(true);

  constructor(
    elementRef: ElementRef,
    private readonly apiTypes: ApiTypesService) {
    super(elementRef);
  }

  /**
   * Get State based on country id
   *
   * @param {number} countryId
   */
  private getStates(countryId: number): void {
    this.apiTypes.getStatesOptions(countryId).subscribe({
      next: response => {        
        this.optionsDefault.set(response)
      } 
    });
  }

}

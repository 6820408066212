<div class="flex flex-col gap-2" (click)="test($event)">
  @if (inputElement().label) {
    <label class="text__label" [for]="inputElement().input_id" [innerText]="inputElement().label"></label>
  }
  <!-- START Input -->
  @if (inputElement().input_type == "dropdown" || inputElement().input_type == "multiselect-dropdown") {
    <app-select-dropdown
      [id]="inputElement().input_id"
      [options]="inputElement().options"
      [placeholder]="placeholder()"
      [control]="this.formControl()"
      [isMultiSelect]="inputElement().input_type == 'multiselect-dropdown'"
    />
  } @else if (inputElement().input_type == "radio") {
    <app-select-radio
      [control]="formControl()"
      [name]="inputElement().input_id"
      [options]="inputElement().options"
    />
  } @else if (inputElement().input_type == "checkbox") {
    <app-select-checkbox
      [control]="formControl()"
      [name]="inputElement().input_id"
      [options]="inputElement().options"/>
  } @else if (inputElement().input_type == "phone-number") {
    <app-phone-input
      [controlPhoneNumber]="formControl()"
      [id]="inputElement().input_id"
      [placeholder]="inputElement().placeholder"/>
  } @else {
    <input
      class="input__field"
      [type]="inputType()"
      [formControl]="this.formControl()"
      [id]="inputElement().input_id"
      [placeholder]="placeholder()"/>
  }
  <!-- END Input -->
  @for (error of getErrors(); track error) {
    <p class="input__error--label" [innerHTML]="error"></p>
  }

  @if (inputElement().validation.confirm && this.confirmFormControl()) {
    <!-- START Confirm Input -->
    <label
      class="text__label"
      [for]="confirmID()"
      [innerText]="confirmLabel()"></label>
    <input
      class="input__field"
      [type]="inputType()"
      [formControl]="this.confirmFormControl()!"
      [id]="confirmID()"
      [placeholder]="placeholder()"/>
    @for (error of getConfirmErrors(); track error) {
      <p class="input__error--label" [innerHTML]="error"></p>
    }
    <!-- END Confirm Input -->
  }
</div>

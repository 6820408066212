@for (imageItem of imageListItems(); track imageItem) {
  <div class="border border-secondary-dark rounded-md p-3 flex items-center justify-between gap-4 m-1">
    <div class="flex justify-between w-full">
      <div class="flex items-center gap-4 cursor-pointer"
           (click)="onImage(imageItem)">
        <img [src]="imageItem.src" class="w-8 h-8" alt="">
        <p class="text__content">{{ imageItem.filename }}</p>
      </div>
      <div class="flex items-center gap-4">
        @if (imageItem.status) {
          <p class="image__status image__status-{{imageItem.status.color ?? 'blue'}}">
            {{ imageItem.status.text }}
          </p>
        }
        <img ngSrc="assets/icons/cross.svg" class="w-6 h-6 cursor-pointer" alt=""
             (click)="onCross(imageItem)" height="25" width="24">
      </div>
    </div>
  </div>
}
@if (loading()) {
  <div class="w-full border border-secondary-dark rounded-md p-3 flex items-center m-1">
    <a class="w-full h-8 shimmer">text test</a>
  </div>
}
<app-common-modal [id]="MODAL_ID">
  <img [src]="modalItem()?.src" alt="">
</app-common-modal>

<!-- FOOTER START -->
<div class="footer__container">
  <div class="footer__content__container">
    <div class="footer__sections_container">
      <div>
        <h6> {{ 'LANDING.FOOTER.HEADING_1' | translate }} </h6>
        <a target="_blank" routerLink="/company-experience">{{ 'LANDING.FOOTER.SUB_MENU_1' | translate }}</a>
        <a target="_blank" routerLink="/contact-us">{{ 'LANDING.FOOTER.SUB_MENU_2' | translate }}</a>
      </div>
      <div>
        <h6> {{ 'LANDING.FOOTER.HEADING_2' | translate }} </h6>
        <a (click)="playIntroVideo()"> {{ 'LANDING.FOOTER.SUB_MENU_3' | translate }}</a>
        <a target="_blank" routerLink="/register"> {{ 'LANDING.FOOTER.SUB_MENU_4' | translate }}</a>
        <a target="_blank" routerLink="/contact-us">{{ 'LANDING.FOOTER.SUB_MENU_5' | translate }}</a>
      </div>
      <div>
        <h6> {{ 'LANDING.FOOTER.HEADING_3' | translate }} </h6>
        <a target="_blank" routerLink="/user-agreement">{{ 'LANDING.FOOTER.SUB_MENU_6' | translate }}</a>
        <a target="_blank" routerLink="/privacy-policy">{{ 'LANDING.FOOTER.SUB_MENU_7' | translate }}</a>
      </div>
    </div>

    <div class="flex flex-col gap-16 items-center">
      <p class="w-full italic" [innerHtml]="'LANDING.FOOTER.DESC_1' | translate"></p>
      <p>
        <span class="text-2xl md:text-4xl font-semibold">{{ 'LANDING.FOOTER.HEADING_5' | translate }}</span>
        <span class="italic">&nbsp;{{ 'LANDING.FOOTER.DESC_2' | translate }}</span>
      </p>
    </div>
  </div>
</div>
<!-- FOOTER END -->

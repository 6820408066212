import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { SelectOption } from "src/app/services/select-option.service";
import {
  Country,
  DeathType, DeathTypeBase, EmailType, EmailTypeBase, EnterpriseType, EnterpriseTypeBase,
  IdentificationType, IdentificationTypeBase, Industry, IndustryBase,
  MaritalStatus, MaritalStatusBase, PermissionKey, PermissionRequest,
  Relationship,
  RelationshipBase,
  Salutation,
  SalutationBase, Sex, SexBase, State, StateAndCountry, StateBase
} from "src/app/services/api-services/types/api-types.types";
import { ApiResponse, PagedRequest, PagedResponse } from "src/app/services/api-services/types/api-schema.types";


@Injectable({
  providedIn: 'root'
})
export class ApiTypesService extends ApiService {
  /**
   * Adds Email type
   * @param {EmailTypeBase} body
   * @returns
   */
  addEmailType(body: EmailTypeBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-email-type'), body);
  }

  /**
   * Adds enterprise type
   * @param {EnterpriseTypeBase} body
   * @returns
   */
  addEnterpriseType(body: EnterpriseTypeBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-enterprise-type'), body);
  }

  /**
   * Adds industry type
   * @param {IndustryBase} body
   * @returns
   */
  addIndustryType(body: IndustryBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-industry'), body);
  }

  /**
   * Adds Marital Status type
   * @param {MaritalStatusBase} body
   * @returns
   */
  addMaritalStatusType(body: MaritalStatusBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-marital-status'), body);
  }

  /**
   * Adds relationship type
   * @param {RelationshipBase} body
   * @returns
   */
  addRelationshipType(body: RelationshipBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-relationship'), body);
  }

  /**
   * Adds salutation type
   * @param {SalutationBase} body
   * @returns
   */
  addSalutationType(body: SalutationBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-salutation'), body);
  }

  /**
   * Adds sex type
   * @param {SexBase} body
   * @returns
   */
  addSexType(body: SexBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-sex-type'), body);
  }

  /**
   * Adds state type
   * @param {StateBase} body
   * @returns
   */
  addStateType(body: StateBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-state'), body);
  }

  /**
   * Adds death certificate type
   * @param body
   * @returns death certificate type
   */
  addDeathCertificateType(body: DeathTypeBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-death-certificate'), body);
  }

  /**
   * Adds identification type
   * @param body
   * @returns identification type
   */
  addIdentificationType(body: IdentificationTypeBase): Observable<ApiResponse<null>> {
    return this.post<any>(this.url('add-identification'), body);
  }

  /**
   * Deletes Email type
   * @param {string} id
   * @returns
   */
  deleteEmailType(id: string): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-email-type`), {params: {email_type_id: id}});
  }

  /**
   * Deletes enterprise type
   * @param {string} enterpriseId
   * @returns
   */
  deleteEnterpriseType(enterpriseId: string): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-enterprise-type`), {params: {enterprise_type_id: enterpriseId}});
  }

  /**
   * Deletes industry type
   * @param {string} industryId
   * @returns
   */
  deleteIndustryType(industryId: string): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-industry`), {params: {industry_id: industryId}});
  }

  /**
   * Deletes MaritalStatus type
   * @param {string} MaritalStatusId
   * @returns
   */
  deleteMaritalStatusType(MaritalStatusId: string): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-marital-status`), {params: {marital_status_id: MaritalStatusId}});
  }

  /**
   * Deletes relationship type
   * @param {string} relationshipId
   * @returns
   */
  deleteRelationshipType(relationshipId: string): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-relationship`), {params: {relationship_id: relationshipId}});
  }

  /**
   * Deletes salutation type
   * @param {string} salutationId
   * @returns
   */
  deleteSalutationType(salutationId: string): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-salutation`), {params: {salutation_id: salutationId}});
  }

  /**
   * Deletes sex type
   * @param {string} id
   * @returns
   */
  deleteSexType(id: string): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-sex-type`), {params: {sex_type_id: id}});
  }

  /**
   * Deletes state type
   * @param {number} id
   * @returns
   */
  deleteStateType(id: number): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-state`), {params: {state_id: id}});
  }

  /**
   * Deletes death type
   * @param id
   * @returns death type
   */
  deleteDeathType(id: string): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-death-certificate`), {params: {death_certificate_id: id}});
  }

  /**
   * Deletes identification type
   * @param id
   * @returns identification type
   */
  deleteIdentificationType(id: string): Observable<ApiResponse<null>> {
    return this.delete<any>(this.url(`delete-identification`), {params: {identification_id: id}});
  }

  /**
   * Edits Email type
   * @param {EmailTypeBase} body
   * @param {string} id
   * @returns
   */
  editEmailType(body: EmailTypeBase, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-email-type`), body, {params: {email_type_id: id}});
  }

  /**
   * Edits enterprise type
   * @param {EnterpriseTypeBase} body
   * @param {string} [id]
   * @returns
   */
  editEnterpriseType(body: EnterpriseTypeBase, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-enterprise-types`), body, {params: {enterprise_type_id: id}});
  }

  /**
   * Edits industry type
   * @param {IndustryBase} body
   * @param id
   * @returns
   */
  editIndustryType(body: IndustryBase, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-industry`), body, {params: {industry_id: id}});
  }

  /**
   * Edits MaritalStatus type
   * @param {MaritalStatusBase} body
   * @param {string} id
   * @returns
   */
  editMaritalStatusType(body: MaritalStatusBase, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-marital-status`), body, {params: {marital_status_id: id}});
  }

  /**
   * Edits Permission type
   * @param {PermissionRequest} body
   * @param {string} id
   * @returns
   */
  editPermissionType(body: PermissionRequest, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-permission-type`), body, {params: {permission_key_id: id}});
  }

  /**
   * Edits relationship type
   * @param {RelationshipBase} body
   * @param {string} [id]
   * @returns
   */
  editRelationshipType(body: RelationshipBase, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-relationship`), body, {params: {relationship_id: id}});
  }

  /**
   * Edits salutation type
   * @param {SalutationBase} body
   * @param {string} [id]
   * @returns
   */
  editSalutationType(body: SalutationBase, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-salutation`), body, {params: {salutation_id: id}});
  }

  /**
   * Edits sex type
   * @param {SexBase} body
   * @param {string} id
   * @returns
   */
  editSexType(body: SexBase, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-sex-type`), body, {params: {sex_type_id: id}});
  }

  /**
   * Edits state type
   * @param {StateBase} body
   * @param {number} id
   * @returns
   */
  editStateType(body: StateBase, id: number): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-state`), body, {params: {state_id: id}});
  }

  /**
   * Edits death type
   * @param body
   * @param id
   * @returns death type
   */
  editDeathType(body: DeathTypeBase, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-death-certificate`), body, {params: {death_certificate_id: id}});
  }

  /**
   * Edits identification type
   * @param body
   * @param id
   * @returns identification type
   */
  editIdentificationType(body: IdentificationTypeBase, id: string): Observable<ApiResponse<null>> {
    return this.patch<any>(this.url(`edit-identification`), body, {params: {identification_id: id}});
  }

  getCountries(): Observable<ApiResponse<Country[]>> {
    return this.getWithStorage(this.get<any>(this.url('countries')), 'countries');
  }

  /**
   * {@link getCountries} formatted for {@link SelectOption}
   */
  getCountriesOptions(): Observable<SelectOption<Country>[]> {
    return this.asOptions(this.getCountries(), 'id', 'country_name');
  }

  getEmailTypes(): Observable<ApiResponse<EmailType[]>> {
    return this.getWithStorage(this.get<any>(this.url('get-email-types')), 'email_types');
  }

  getEmailTypesOptions(): Observable<SelectOption<EmailType>[]> {
    return this.asOptions(this.getEmailTypes(), 'id', 'email_type_name');
  }

  getIndustries(): Observable<ApiResponse<Industry[]>> {
    return this.getWithStorage(this.get<any>(this.url('get-industries')), 'industries');
  }

  getIndustriesOptions(): Observable<SelectOption<Industry>[]> {
    return this.asOptions(this.getIndustries(), 'id', 'industry_name');
  }

  getMaritalStatusOptions(): Observable<SelectOption<MaritalStatus>[]> {
    return this.asOptions(this.getMaritalStatuses(), 'id', 'marital_status_name');
  }

  getMaritalStatuses(): Observable<ApiResponse<MaritalStatus[]>> {
    return this.getWithStorage(this.get<any>(this.url('get-marital-statuses')), 'marital_statuses');
  }

  /**
   * {@link getCountries} formatted for {@link SelectOption} where the nationality is used as the label.
   */
  getNationalitiesOptions(): Observable<SelectOption<Country>[]> {
    return this.getCountries().pipe(
      map(r => {
        const data = r.data;
        const options: SelectOption[] = [];
        data.forEach((e) => {
          options.push({
            value: e.id,
            label: `${e.nationality} (${e.country_name})`,
            data: e
          });
        });
        return options;
      })
    );
  }

  /**
   * Get a paged response for `EmailType` objects.
   */
  getPagedEmailTypes(request: PagedRequest): Observable<ApiResponse<PagedResponse<EmailType>>> {
    return this.get<any>(this.url(`get-paged-email-type`), this.getParams(request));
  }

  /**
   * Get a paged response for `EnterpriseType` objects.
   */
  getPagedEnterpriseTypes(request: PagedRequest): Observable<ApiResponse<PagedResponse<EnterpriseType>>> {
    return this.get<any>(this.url(`get-paged-enterprise-types`), this.getParams(request));
  }

  /**
   * Get a paged response for `Industry` objects.
   */
  getPagedIndustries(request: PagedRequest): Observable<ApiResponse<PagedResponse<Industry>>> {
    return this.get<any>(this.url(`get-paged-industries`), this.getParams(request));
  }

  /**
   * Get a paged response for `MaritalStatus` objects.
   */
  getPagedMaritalStatuses(request: PagedRequest): Observable<ApiResponse<PagedResponse<MaritalStatus>>> {
    return this.get<any>(this.url(`get-paged-marital-status`), this.getParams(request));
  }

  /**
   * Get a paged response for `PermissionKey` objects.
   */
  getPagedPermissionKeys(request: PagedRequest): Observable<ApiResponse<PagedResponse<PermissionKey>>> {
    return this.get<any>(this.url(`get-paged-permission-type`), this.getParams(request));
  }

  /**
   * Get a paged response for `Relationship` objects.
   */
  getPagedRelationships(request: PagedRequest): Observable<ApiResponse<PagedResponse<Relationship>>> {
    return this.get<any>(this.url(`get-paged-relationship`), this.getParams(request));
  }

  /**
   * Get a paged response for `Salutation` objects.
   */
  getPagedSalutations(request: PagedRequest): Observable<ApiResponse<PagedResponse<Salutation>>> {
    return this.get<any>(this.url(`get-paged-salutations`), this.getParams(request));
  }

  /**
   Get a paged response for `Sex` objects.
   */
  getPagedSexes(request: PagedRequest): Observable<ApiResponse<PagedResponse<Sex>>> {
    return this.get<any>(this.url(`get-paged-sex-types`), this.getParams(request));
  }

  /**
   * Get a paged response for `StateAndCountry` objects.
   */
  getPagedStates(request: PagedRequest): Observable<ApiResponse<PagedResponse<StateAndCountry>>> {
    return this.get<any>(this.url(`get-paged-states`), this.getParams(request));
  }

  /**
   * Gets paged identification types
   * @param request
   * @returns paged identification types
   */
  getPagedIdentificationTypes(request: PagedRequest): Observable<ApiResponse<PagedResponse<IdentificationType>>> {
    return this.get<any>(this.url(`get-paged-identifications-type`), this.getParams(request));
  }

  /**
   * Gets paged death certification types
   * @param request
   * @returns paged death certification types
   */
  getPagedDeathCertificationTypes(request: PagedRequest): Observable<ApiResponse<PagedResponse<DeathType>>> {
    return this.get<any>(this.url(`get-paged-death-certificate-type`), this.getParams(request));
  }

  /**
   * Get Relationship types
   * @return {Observable<any>}
   */
  getRelationships(): Observable<ApiResponse<Relationship[]>> {
    return this.getWithStorage(this.get<any>(this.url('get-relationships')), 'relationships');
  }

  /**
   * {@link getCountries} formatted for {@link SelectOption}
   */
  getRelationshipsOptions(): Observable<SelectOption<Relationship>[]> {
    return this.asOptions(this.getRelationships(), 'id', 'relationship_name');
  }

  getSexOptions(): Observable<SelectOption<Sex>[]> {
    return this.asOptions(this.getSexes(), 'id', 'sex_name');
  }

  getSexes(): Observable<ApiResponse<Sex[]>> {
    return this.getWithStorage(this.get<any>(this.url('get-sex-types')), 'sex_types');
  }

  /**
   * Get state
   *
   * @param {number} countryId
   * @return {Observable<any>}
   */
  getStates(countryId: number): Observable<ApiResponse<State[]>> {
    return this.getWithStorage(this.get<any>(this.url('states'), {params: {country_id: countryId}}), `states[${countryId}]`);
  }

  /**
   * {@link getCountries} formatted for {@link SelectOption}
   */
  getStatesOptions(countryId: number): Observable<SelectOption<State>[]> {
    return this.asOptions(this.getStates(countryId), 'id', 'state_name');
  }

  /**
   * Gets death types
   * @returns death types
   */
  getDeathTypes(): Observable<ApiResponse<DeathType[]>> {
    return this.getWithStorage(this.get<any>(this.url('get-death-certificates')), 'death_types');
  }

  /**
   * Gets death types options
   * @returns death types options
   */
  getDeathTypesOptions(): Observable<SelectOption<DeathType>[]> {
    return this.asOptions(this.getDeathTypes(), 'id', 'death_certificate_name');
  }

  /**
   * Gets identification types
   * @returns identification types
   */
  getIdentificationTypes(): Observable<ApiResponse<IdentificationType[]>> {
    return this.getWithStorage(this.get<any>(this.url('get-identifications')), 'identification_types');
  }

  /**
   * Gets identification types options
   * @returns identification types options
   */
  getIdentificationTypesOptions(): Observable<SelectOption<IdentificationType>[]> {
    return this.asOptions(this.getIdentificationTypes(), 'id', 'identification_name');
  }

  protected override url(slug: string): string {
    return super.url(['types', slug]);
  }
}

import { Component, computed, HostListener, OnInit, signal } from '@angular/core';
import { ApiSettingsService } from "src/app/services/api-services/api-settings.service";
import { ModalService } from "src/app/services/modal.service";
import { EventBusService, EventKey } from "src/app/services/event-bus.service";
import { ApiAuthService } from "src/app/services/api-services/api-auth.service";


/**
 * This should be placed in the {@link AppComponent} template to start the functionality. Once there, everything is
 * automatic.
 */
@Component({
  selector: 'app-inactivity-timeout-widget',
  templateUrl: './inactivity-timeout-widget.component.html',
  styleUrl: './inactivity-timeout-widget.component.css'
})
export class InactivityTimeoutWidgetComponent implements OnInit {
  protected readonly MODAL_ID = "site_timeout_widget_modal_id";
  private readonly WARNING_MS = 60 * 1000;
  private timeRemainingMS = signal<number>(0);
  protected timeRemainingText = computed<string>(() => this.getTimeString(this.timeRemainingMS()));
  private timeoutMS = signal<number>(0);
  protected timePassedText = computed<string>(() => this.getTimeString(this.timeoutMS()));
  private timerLogout?: NodeJS.Timeout;
  private timerWarning?: NodeJS.Timeout;

  constructor(
    private readonly apiSettings: ApiSettingsService,
    private readonly modalService: ModalService,
    private readonly auth: ApiAuthService,
    private readonly eventBus: EventBusService) {}

  clearTimeouts() {
    clearTimeout(this.timerWarning);
    clearTimeout(this.timerLogout);
    this.timerLogout = undefined;
    this.timerWarning = undefined;
    this.modalService.close(this.MODAL_ID);
  }

  ngOnInit() {
    this.apiSettings.getActiveSettings().subscribe({
      next: r => this.timeoutMS.set(+r.data.INACTIVITY_TIMOUT_MINUTES * 60 * 1000),
      complete: () => this.resetTimer()
    });
  }

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('document:touch')
  onMouseMove() {
    this.resetTimer();
    this.modalService.close(this.MODAL_ID);
  }

  /**
   * Format the given time as a minutes and seconds string.
   * @param timeMS Time in milliseconds
   * @private
   */
  private getTimeString(timeMS: number): string {
    const timestampMinutes = Math.floor(timeMS / 60000);
    const timestampSeconds = Math.floor(((timeMS / 60000) % 1) * 60);
    // Will show if minutes is a non-zero number. Handle Plurals.
    const textMinutes = timestampMinutes > 0 ?
      timestampMinutes + " minute" + (timestampMinutes > 1 ? 's' : '')
      : '';
    // Will show if there are no minutes, or if the seconds is a non-zero number. Handle plurals.
    const textSeconds = timestampMinutes == 0 || timestampSeconds > 0 ?
      timestampSeconds + " second" + (timestampSeconds > 1 ? 's' : '')
      : '';
    return `${textMinutes} ${textSeconds}`.trim();
  }

  /**
   * Stops the timers and emits a logout event.
   * @see EventBusService.emit
   * @private
   */
  private logout() {
    this.clearTimeouts();
    this.eventBus.emit({key: EventKey.USER_LOGOUT});
  }

  /**
   * Resets & starts the timer(s).
   * @private
   */
  private resetTimer() {
    this.clearTimeouts();
    if (this.auth.isAuthenticated && this.timeoutMS() > 0) {
      this.timerWarning = setTimeout(this.warning.bind(this), this.timeoutMS());
    }
  }

  /**
   * Executed once timeout has occurred & starts a {@link WARNING_MS} length warning with a countdown modal. Once this
   * countdown reaches zero, then {@link logout} is called.
   * @private
   */
  private warning() {
    this.modalService.open(this.MODAL_ID);
    this.timeRemainingMS.set(this.WARNING_MS);
    this.timerLogout = setInterval(() => {
      this.timeRemainingMS.update(t => t - 1000);
      if (this.timeRemainingMS() <= 0) this.logout();
    }, 1000);
  }

}

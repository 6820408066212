<div class="jw-modal flex items-center justify-center scroll" (click)="clickModal($event)">
  <div #main_body class="rounded w-full mx-4 relative {{customClass()}}"
       [ngClass]="{
         'md:w-2/5 md:m-auto': !forceFullScreen(),
         'jw-modal-body': showBody(),
         'jw-modal-body-padding': usePadding()
       }">
    @if (showClose()) {
      <div
        class="bg-white drop-shadow-md p-2 rounded-full absolute -right-4 -top-4 cursor-pointer z-50"
        (click)="closeModal()">
        <img ngSrc="assets/icons/cross.svg" class="filter-dark-gray" alt="X" height="25" width="24">
      </div>
    }
    <ng-content/>
  </div>
</div>
<div class="jw-modal-background"></div>

import { Component } from '@angular/core';
import { EventBusService, EventKey } from "src/app/services/event-bus.service";


@Component({
  selector: 'app-navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent {
  protected languageOptionValues: INavbarOption[] = [
    {text: 'English', action: () => console.debug('English')}
  ];
  protected logoImageLoading = true;
  protected profileImageLoading = true;
  protected profileOptions: INavbarOption[] = [
    {text: 'Settings', action: () => console.debug('Settings')},
    {text: 'Support', action: () => console.debug('Support')},
    {text: 'Sign Out', action: () => this.eventBus.emit({key: EventKey.USER_LOGOUT})}
  ];
  protected toggles: INavbarToggles = {profile: false, language: false, nav: false};

  constructor(private readonly eventBus: EventBusService) {
  }

  protected toggle(key: string) {
    this.toggles[key] = !this.toggles[key];
  }
}


interface INavbarOption {
  action: () => void;
  text: string;
}


interface INavbarToggles {
  language: boolean;
  nav: boolean;
  profile: boolean;
}

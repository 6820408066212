import { Pipe, PipeTransform } from '@angular/core';
import { SortingService } from "src/app/services/sorting.service";
import { SelectOption } from "src/app/services/select-option.service";


@Pipe({
  name: 'textFilterList'
})
/**
 * Filtering text based on string
 */
export class TextFilterListPipe implements PipeTransform {
  constructor(private readonly sortHelper: SortingService) {}

  transform<T>(
    values: Array<SelectOption<T>>,
    searchKey: string,
    optionKey: keyof SelectOption
  ): Array<SelectOption<T>> {
    // Search / Sort the array
    if (searchKey)
      values = this.sortHelper.searchSortSorensenDiceOptions(values, searchKey, optionKey);
    else
      values.sort((a, b) => a.label.localeCompare(b.label));

    // Keep 'Other' at the end
    const i = values.findIndex(e => e.label.toLowerCase() == 'others' || e.label.toLowerCase() == 'other');
    if (i >= 0) {
      const moveValue = values[i];
      values.splice(i, 1);
      values.push(moveValue);
    }
    return values;
  }
}

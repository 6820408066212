@if (!mediaStreamStarted()) {
  <div
    class="border border-secondary-dark border-dashed h-72 rounded w-full flex flex-col items-center justify-center mt-2 relative cursor-pointer"
    (click)="startWebcam()">
    <img ngSrc="assets/icons/camera.svg" alt="" height="63" width="70">
    <h5 class="text-secondary-dark font-sans font-medium text-base lg:text-lg text-center">
      Click here to open Camera
    </h5>
    @if (isWebcamBlocked()){
      <p class="input__error--label">
        Webcam access has been denied.
      </p>
    }
  </div>
}
<div [ngClass]="{'hidden':!mediaStreamStarted()}">
  <div class="flex justify-center cursor-pointer mt-2">
    <video #video autoplay class="h-72 rounded-md"></video>
    <canvas #canvas [width]="outputSize().width" [height]="outputSize().height" class="hidden"></canvas>
  </div>
  <div class="flex">
    <button class="btn__primary w-full mt-2" (click)="captureSnapshot()">
      Capture
    </button>
    <button class="btn__secondary w-full mt-2" (click)="stopWebcam()">
      Close
    </button>
  </div>
</div>

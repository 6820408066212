import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsumerRootComponent } from "./modules/user-consumer/consumer-root/consumer-root.component";
import { AuthGuardAdmin, AuthGuardConsumer } from "./guards/auth.guards";
import { AdminRootComponent } from "./modules/user-admin/admin-root/admin-root.component";
import { ProfessionalRootComponent } from "./modules/user-professional/professional-root/professional-root.component";
import { LandingRootComponent } from "./modules/landing-pages/landing-root/landing-root.component";
import { MaintenanceModeComponent } from "src/app/components/maintenance-mode/maintenance-mode.component";
import { NotFoundComponent } from "src/app/components/not-found/not-found.component";

// Placed in variable, so we can place these routes as independent routes and as children of the cobrand route.
const consumerRoutes = [
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
  }, {
    path: 'consumer', component: ConsumerRootComponent, canActivate: [AuthGuardConsumer],
    loadChildren: () => import('./modules/user-consumer/user-consumer.module').then(m => m.UserConsumerModule)
  }
];

const routes: Routes = [
  {
    path: '', component: LandingRootComponent,
    loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule)
  },
  {
    path: 'maintenance', component: MaintenanceModeComponent
  }, {
    path: 'admin', component: AdminRootComponent, canActivate: [AuthGuardAdmin],
    loadChildren: () => import('./modules/user-admin/user-admin.module').then(m => m.UserAdminModule)
  }, {
    path: 'professional', component: ProfessionalRootComponent,
    loadChildren: () => import('./modules/user-professional/user-professional.module').then(
      m => m.UserProfessionalModule)
  },
  ...consumerRoutes,
  // {path: ':cobrand', component: CobrandConsumerRoot, children: consumerRoutes},
  {path: 'page-not-found', component: NotFoundComponent},
  {path: '**', pathMatch: 'full', redirectTo: 'page-not-found'}
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        bindToComponentInputs: true
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';
import _ from 'lodash';
import { firstValueFrom, Observable } from "rxjs";
import { ApiTypesService } from "src/app/services/api-services/api-types.service";
import { AddressLocationDetails } from "src/app/services/types/address.types";
import Autocomplete = google.maps.places.Autocomplete;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;


@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private readonly DEFAULT_ID = 'address_helper_default_id';
  private autocomplete: Record<string, Autocomplete> = {};

  constructor(private readonly apiTypes: ApiTypesService) {
  }

  /**
   * Initialize Google Maps autocomplete for a particular {@link HTMLInputElement}.
   * @param searchElement {@link HTMLInputElement} take search value from.
   * @param countryId Country to narrow search by.
   * @param id Unique ID for this autocomplete map.
   */
  initAutocomplete(searchElement: HTMLInputElement, countryId: number, id: string = this.DEFAULT_ID) {
    if (this.autocomplete[id]) {
      this.autocomplete[id].unbindAll();
      google.maps.event.clearListeners(this.autocomplete[id], 'place_changed');
    }
    return new Observable<AddressLocationDetails>(observer => {
      // Check for required information
      if (!(countryId || searchElement)) {
        observer.error({
          error: 'Missing Value',
          values: {
            countryId: countryId,
            searchElement: searchElement
          }
        });
        return;
      }
      this.getCountryIso(countryId).then(countryIso => {
        // Create a Google Maps autocomplete field with or without country restrictions
        const opts: AutocompleteOptions | null = countryIso ? {componentRestrictions: {country: countryIso}} : null;
        this.autocomplete[id] = new Autocomplete(searchElement, opts);
        // Listen for place changes in the autocomplete field
        google.maps.event.addListener(this.autocomplete[id], 'place_changed', () => {
          // Get the selected place from the autocomplete field
          const locationDetails: Partial<AddressLocationDetails> = {country: countryId};
          const place = this.autocomplete[id].getPlace();
          const userAddress = this.commonAddressFilter(place.address_components ?? []);
          locationDetails.address = userAddress.address;
          locationDetails.zipcode = userAddress.zipcode;
          locationDetails.city = userAddress.city;

          firstValueFrom(this.apiTypes.getStates(countryId))
            .then(r =>
              locationDetails.state = r.data.filter(state =>
                state.state_name.toLowerCase() === userAddress.state?.toLowerCase())
                .at(0)?.id)
            .catch(e => observer.error(e))
            .finally(() => observer.next(locationDetails as AddressLocationDetails));
        });
      });
    });
  }

  /**
   * Get address strings from Geocoder Address
   * @param geoAddress
   * @private
   */
  private commonAddressFilter(geoAddress: GeocoderAddressComponent[]): { [key in keyof AddressLocationDetails]?: string } {
    const cityAllVariations: {long_name: any, id: number, type: string, field_name: string}[] = [];
    const addressInterface: { [key in keyof AddressLocationDetails]?: string } = {address: ''};
    geoAddress.forEach(element => {
      if (element.types.length) {
        const field_name = _.filter(ADDRESS_DATA, (data => data.type === element.types[0])).at(0);
        switch (field_name?.field_name) {
          case 'address':
            addressInterface.address += addressInterface.address == '' ? element.long_name : ' ' + element.short_name;
            break;
          case 'state':
            addressInterface.state = element.long_name;
            break;
          case 'city':
            cityAllVariations.push({...field_name, long_name: element.long_name});
            break;
          case 'postal_code':
            addressInterface.zipcode = element.long_name;
            break;
        }
      }
    });

    if (addressInterface.address == '' && geoAddress.length > 0) {
      addressInterface.address = geoAddress[0].long_name;
      if (geoAddress.length > 1)
        addressInterface.address += ' ' + geoAddress[1].short_name;
    }

    // Search city from multiple city variants
    if (cityAllVariations.length > 0) {
      const types_priority = ['locality', 'administrative_area_level_3', 'sublocality', 'neighborhood'];
      for (let type of types_priority) {
        const foundCity = cityAllVariations.find((e: {type: any;}) => e.type === type);
        if (foundCity) {
          addressInterface.city = foundCity.long_name;
          break;
        }
      }
      // If none of the priorities matched, take last item
      if (!addressInterface.city) {
        addressInterface.city = cityAllVariations[cityAllVariations.length - 1].long_name;
      }
    }
    return addressInterface;
  }

  /**
   * Get ISO code for the given country ID
   * @param countryId
   * @private
   */
  private getCountryIso(countryId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      firstValueFrom(this.apiTypes.getCountries())
        .then(r => {
          const countries = r.data;
          const iso2 = countries.filter(country => country.id === countryId).at(0)?.iso2;
          if (iso2) resolve(iso2);
          else reject();
        })
        .catch(e => reject(e));
    });
  }
}


const ADDRESS_DATA = [
  {id: 1, type: 'premise', field_name: 'address'},
  {id: 1, type: 'subpremise', field_name: 'address'},
  {id: 2, type: 'street_number', field_name: 'address'},
  {id: 2, type: 'route', field_name: 'address'},
  {id: 4, type: 'neighborhood', field_name: 'city'},
  {id: 4, type: 'postal_town', field_name: 'city'},
  {id: 4, type: 'sublocality_level_2', field_name: 'address'},
  {id: 4, type: 'sublocality_level_1', field_name: 'address'},
  {id: 3, type: 'sublocality', field_name: 'city'},
  {id: 5, type: 'locality', field_name: 'city'},
  {id: 6, type: 'administrative_area_level_3', field_name: 'city'},
  {id: 7, type: 'administrative_area_level_2', field_name: 'city'},
  {id: 8, type: 'administrative_area_level_1', field_name: 'state'},
  {id: 10, type: 'postal_code_prefix', field_name: 'postal_code'},
  {id: 10, type: 'postal_code', field_name: 'postal_code'}
];

import { Component, input } from '@angular/core';

import { SelectOption } from "src/app/services/select-option.service";


@Component({
  selector: 'app-list-options',
  templateUrl: './list-options.component.html',
  styleUrl: './list-options.component.css'
})
/**
 * This component accepts an array of {@link SelectOption} & displays it in a grid of labels & values.
 */
export class ListOptionsComponent {
  options = input.required<SelectOption[]>();
  isLoading = input(false);
}

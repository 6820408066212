import { effect, Injectable, OnDestroy, signal } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { SynchronousValidationService } from "src/app/services/validation-service/synchronous-validation.service";
import {
  Conditional,
  ConditionalGroup,
  ConditionalOperator
} from "src/app/services/api-services/conditional-evaluator.types";


@Injectable({
  providedIn: 'root'
})
export class ConditionalEvaluatorService implements OnDestroy {
  private readonly SYNC_VALIDATION_KEY = 'conditionalEvaluatorServiceMap';
  private registeredInputs = signal<Record<string, AbstractControl>>({});

  constructor(private syncValidation: SynchronousValidationService) {
    effect(() => {
      const controls = Object.values(this.registeredInputs());
      syncValidation.set(this.SYNC_VALIDATION_KEY, controls);
    });
  }

  /**
   * A validator function that evaluates a list of conditional groups and returns validation errors
   * if any condition is met.
   *
   * @param conditions - An array of {@link ConditionalGroup} objects to be evaluated.
   * @returns A {@link ValidatorFn} that returns a {@link ValidationErrors} object if any condition
   *          is `false`, otherwise `null`.
   */
  conditionalGroupsValidator(conditions: ConditionalGroup[]): ValidatorFn {
    return (_): ValidationErrors | null => !this.evaluateGroups(conditions) ? {conditional: conditions} : null;
  }

  /**
   * Evaluate the given {@link Conditional}.
   * @param condition See {@link Conditional}
   */
  evaluateCondition(condition: Conditional): boolean {
    const values = this.getValues(condition);
    const result = this.performOperation(values, condition.operator);
    return result !== condition.is_not;
  }

  /**
   * Evaluate the {@link ConditionalGroup}. If the {@link ConditionalGroup.strategy strategy} is "or" then the first
   * `true` condition will stop the evaluation.
   * @param group
   */
  evaluateGroup(group: ConditionalGroup): boolean {
    for (const condition of group.conditions) {
      let result = this.evaluateCondition(condition);
      if (group.strategy == "or" && result) return true;
      else if (group.strategy == "and" && !result) return false;
    }
    // If "and" didn't return `false`, then `true`. If "or" didn't return `true`, then `false`.
    return group.strategy == "and";
  }

  /**
   * Evaluate the provided {@link ConditionalGroup} array.
   * @see evaluateGroup
   * @param conditionGroups
   * @returns `false` if any group evaluates to `false`, else `true`.
   */
  evaluateGroups(conditionGroups: ConditionalGroup[]): boolean {
    return !conditionGroups.find(group => !this.evaluateGroup(group));
  }

  ngOnDestroy(): void {
    this.syncValidation.remove(this.SYNC_VALIDATION_KEY);
  }

  /**
   * Register an object with a value attribute for input-value references.
   * @example
   * ```typescript
   * myControl = new FormControl(...);
   * conditionalEvaluatorService.registerInput('myInputId', myControl);
   * ```
   * @param inputId
   * @param valueObj
   */
  registerInput(inputId: string, valueObj: AbstractControl) {
    if (inputId in this.registeredInputs())
      console.warn(`Input ID "${inputId}" already exists in Registered Inputs; Overwriting...`);
    this.registeredInputs.update(inputs => {
      inputs[inputId] = valueObj;
      return inputs;
    });
  }

  private getInput(inputId: string): AbstractControl | undefined {
    return this.registeredInputs()[inputId];
  }

  private getValues(condition: Conditional): ValuePair {
    return {
      value1: condition.value1.type == 'literal'
        ? condition.value1.value
        : this.getInput(condition.value1.value as string)?.value,
      value2: condition.value2.type == 'literal'
        ? condition.value2.value
        : this.getInput(condition.value2.value as string)?.value
    };
  }

  /**
   * Perform an operation on the {@link values} based on the {@link operator}.
   * @param values See {@link ValuePair}
   * @param operator See {@link ConditionalOperator}
   * @private
   */
  private performOperation(values: ValuePair, operator: ConditionalOperator): boolean {
    switch (operator) {
      case "is_eq":
        return values.value1 == values.value2;
      case "is_gt":
        try {
          // @ts-ignore
          return values.value1 > values.value2;
        } catch {
          return false;
        }
      case "is_gt_eq":
        try {
          // @ts-ignore
          return values.value1 >= values.value2;
        } catch {
          return false;
        }
      case "is_lt":
        try {
          // @ts-ignore
          return values.value1 < values.value2;
        } catch {
          return false;
        }
      case "is_lt_eq":
        try {
          // @ts-ignore
          return values.value1 < values.value2;
        } catch {
          return false;
        }
      case "pattern":
        try {
          // @ts-ignore
          return RegExp(values.value2).test(values.value1);
        } catch {
          return false;
        }
      default:
        throw Error(`Unhandled Operator: "${operator}"`);
    }
  }
}


type ValuePair = {value1: unknown, value2: unknown};

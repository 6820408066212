<!-- START Dynamic Form -->
<div class="flex flex-col gap-2">
  @for (formElement of formData(); track formElement) {
    <app-form-element [formElement]="formElement" [valueChanges]="formValueChanges()"/>
  }

  @if (submitButton().show) {
    <!-- START Submit Button -->
    <div class="flex justify-end">
      <app-button-loader
        [disabled]="formGroup().invalid"
        [isLoading]="isLoading()"
        [text]="submitButton().text??'Submit'"/>
    </div>
    <!-- END Submit Button -->
  }
</div>
<!-- END Dynamic Form -->

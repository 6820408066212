import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[disabledInput]',
  standalone: false,
})
export class DisabledInputDirective implements AfterViewInit, OnDestroy {
  private statusChangeSubscription!: Subscription;

  constructor(private el: ElementRef, private renderer: Renderer2, private control: NgControl) {}

  /**
   * Lifecycle hook that is called after a component's view has been fully initialized.
   */
  ngAfterViewInit(): void {
    this.updateCursor();

    this.statusChangeSubscription = this.control?.['statusChanges']!.subscribe(() => {
      this.updateCursor();
    });
  }

  /**
   * Updates the cursor style based on the form control's disabled status.
   * Adds 'cursor-not-allowed' class if the control is disabled, otherwise removes it.
   */
  private updateCursor() {
    if (this.control.disabled) {
      this.renderer.addClass(this.el.nativeElement, 'cursor-not-allowed');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'cursor-not-allowed');
    }
  }

  /**
   * Lifecycle hook that is called when a directive, pipe, or service is destroyed.
   */
  ngOnDestroy(): void {
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
  }
}

import { Component, computed, HostBinding, input, signal } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";
@Component({
  host: {
    '(@fade.start)': 'animationInProgress.set(true)',
    '(@fade.done)': 'animationInProgress.set(false)',
  },
  selector: 'app-page-spinner',
  templateUrl: 'page-spinner.component.html',
  styleUrl: './page-spinner.component.css',
  animations: [
    trigger('fade', [
      state('void', style({opacity: 0})),
      state('hidden', style({opacity: 0})),
      state('visible', style({opacity: 1})),
      transition('visible <=> hidden', [
        animate('0.5s ease-in-out')
      ]),
    ])
  ]
})
export class PageSpinnerComponent {
  isVisible = input.required<boolean>();
  animationInProgress = signal(false);
  hideContent = computed(()=>!this.animationInProgress() && !this.isVisible());

  @HostBinding('@fade') get fadeState() {
    return this.isVisible() ? 'visible' : 'hidden';
  }


}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiAuthService } from 'src/app/services/api-services/api-auth.service';
import { EventBusService, EventKey } from "src/app/services/event-bus.service";
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private readonly authService: ApiAuthService, private readonly eventBus: EventBusService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    req = req.clone();

    // check if the request is not for stripe api
    if (!req.url.includes(environment.STRIPE_API_URL)) {
    // Request
    if (this.authService.accessToken) req = this.addAuthHeader(req);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('login') &&
          error.status === HttpStatusCode.Unauthorized
        ) {
          return this.handleUnauthorized(req, next);
        }

        return throwError(() => error);
      })
    );

  }

  private handleUnauthorized(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing && this.authService.accessToken) {
      this.isRefreshing = true;
      // Attempt to refresh token. If this fails, it's because the user's authentication is expired.
      return this.authService.refreshToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          req = this.addAuthHeader(req);
          return next.handle(req);
        }),
        catchError((error) => {
          this.isRefreshing = false;
          if (error.status === HttpStatusCode.Unauthorized) {
            // Logout if still unauthorized.
            this.eventBus.emit({key: EventKey.USER_LOGOUT});
          }
          return throwError(() => error);
        }));
    }
    return next.handle(req);
  }

  private addAuthHeader(req: HttpRequest<any>) {
    return req.clone({
      headers: req.headers.set('Authorization', `${this.authService.accessTokenType} ${this.authService.accessToken}`)
    });
  }
}

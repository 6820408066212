import { Component, OnInit } from '@angular/core';
import { EventBusService, EventKey } from "src/app/services/event-bus.service";
import { VideoRef, VideoWidgetEvent } from "src/app/components/video-widget/video-widget.component";
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit{
  protected selectedLanguage!: string;

  constructor(
    private readonly eventBus: EventBusService,
    private readonly storage: StorageService,
    private readonly translate: TranslateService) {
  }

  ngOnInit(): void {
    this.selectedLanguage = this.storage.localization ?? '';
  }

  protected playIntroVideo() {
    this.eventBus.emit<VideoWidgetEvent>({
      key: EventKey.VIDEO_WIDGET,
      data: {
        setState: 'open',
        videoRef: VideoRef.NOTIFIER_INTRO
      }
    });
  }
}

@if (controlErrors() && control().invalid) {
  <div class="relative">
    <div
      [ngClass]="{
        'validation-panel--hover-panel': useHover(),
        'validation-panel--panel': !useHover(),
      }">
      @for (path of errorPaths(); track path) {
        <p>
          @if (!hasPath(controlErrors() ?? {}, path)) {
            <img ngSrc="assets/images/password/tickmark.svg" alt="O"
                 class="float-left"
                 height="16" width="16">
          } @else {
            <img ngSrc="assets/images/password/closemark.svg" alt="X"
                 class="float-left"
                 height="16" width="16">
          }
          {{ valueAt(errorText(), path) }}
        </p>
      }
    </div>
  </div>
}

<div class="space-y-4">
  <ngx-file-drop
    dropZoneClassName="border border-secondary-dark border-dashed h-72 rounded w-full relative mt-2"
    (onFileDrop)="onFileDrop($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      @if (files().length == 0) {
        <ng-container>
          <div class="flex flex-col h-full w-full items-center justify-center cursor-pointer"
               (click)="openFileSelector()">
            <img ngSrc="assets/icons/doc.svg" alt="" height="73" width="72"/>
            <div>
              <h6 class="text-secondary-dark font-sans font-medium text-base lg:text-lg text-center">
                Drop your file or
                <span class="text__link"> Browse files</span>
              </h6>
            </div>
          </div>
        </ng-container>
      } @else {
        <ng-container class="lg:w-11/12 ">
          <div class="flex flex-col h-full w-full items-center justify-center cursor-pointer">
            <ng-container *ngIf="imagePreviewUrl(); else fileName">
              <img [src]="imagePreviewUrl()" alt="IMG"/>
            </ng-container>

            <ng-template #fileName>
              <img ngSrc="assets/icons/document-list.svg" alt="" height="10" width="10"/>
              <div class="pl-2 overflow-hidden w-auto">
                <h5
                  class="text-darkblue text-regular font-semibold text-base truncate text-center md:text-left w-48 md:w-auto sm:w-auto mr-4">
                  {{ files()[0].name }}
                </h5>
              </div>
            </ng-template>
          </div>
        </ng-container>
      }
    </ng-template>
  </ngx-file-drop>
  @if (files().length == 0) {
    <p class="text__content">
      Click on the box above to select the files you want to upload. If you prefer, you can simply drag the files from
      your computer and drop them into the box above.
    </p>
  } @else {
    <div class="flex justify-center mt-4">
      <button mat-flat-button [color]="'warn'" class="justify-center text-center" (click)="removeUploadedFile()">
        <mat-icon class="large-icon" aria-hidden="false" aria-label="Example delete icon" fontIcon="delete"/>
        Delete
      </button>
    </div>
  }
</div>

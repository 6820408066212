import { Component, computed, input, output } from '@angular/core';
import { AbstractControl } from "@angular/forms";
import { SelectOption } from "src/app/services/select-option.service";


@Component({
  selector: 'app-select-checkbox',
  templateUrl: './select-checkbox.component.html',
  styleUrl: './select-checkbox.component.css'
})
export class SelectCheckboxComponent {
  valueChange = output<any>();
  control = input<AbstractControl>();
  disabled = input(false); // TODO
  isLoading = input(false); // TODO
  name = input.required<string>();
  options = input<SelectOption[], SelectOption[] | undefined>([], {transform: input => input ?? []});
  alignment = input<{flex: 'col' | 'row'} | {grid: number | 'auto'}>({flex: 'col'});
  checkboxGroupClass = computed(() => {
    const alignment = this.alignment();
    if (alignment['grid']) {
      return alignment['grid'] == 'auto'
        ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7'
        : 'grid grid-cols-' + alignment['grid'];
    }
    return `flex ${alignment['flex'] == 'col' ? 'flex-col' : ''}`;
  });

  onChange(option: SelectOption) {
    this.toggleValue(option.value);
    this.valueChange.emit(option.value);
  }

  /**
   * If the value exists in {@link control control.value} it is removed, else it is added.
   * @param value
   * @private
   */
  private toggleValue(value: any) {
    const control = this.control();
    if (!control) return;
    const values = Array.isArray(control.value) ? control.value : [];
    const valueExistsIndex = values.indexOf(value);
    if (valueExistsIndex >= 0)
      values.splice(valueExistsIndex, 1);
    else
      values.push(value);
    control.setValue(values);
  }

  isChecked(value: any): boolean {
    const control = this.control();
    if (!control || !Array.isArray(control.value)) return false;
    return control.value.includes(value);
  }
}

import { HttpStatusCode } from "@angular/common/http";

export const ERROR_MESSAGES: Partial<Record<AppError, string>> = {
  ACCOUNT_ID_NOT_COBRAND: 'The provided Account ID does not belong to a Cobrand Account.',
  ACCOUNT_ID_NOT_GENERIC: 'The provided Account ID does not belong to a Generic Account.',
  ADMIN_UNAUTHORIZED: 'You do not have permission to access this resource.',
  AUTH_DEACTIVATED_USER: 'Your account has been deactivated. Please contact support to reactivate your account.',
  AUTH_EMAIL_CONFLICT: 'The email provided is already in use.',
  AUTH_EMAIL_UNVERIFIED: 'Your email is not verified. Please verify your email to continue.',
  AUTH_INVALID_CREDENTIALS: 'Invalid email or password. Please try again.',
  AUTH_NON_ADMIN_ROUTE: 'This route is not accessible to admin users.',
  AUTH_PASSWORD_RESET_REQUIRED: 'You must reset your password before continuing. Please follow the instructions sent to your email.',
  COMPANY_DELETE_WITH_DR: 'The company cannot be deleted while Decedent Request Accounts are associated with it.',
  COMPANY_UNAUTHORIZED_FETCH: 'Unauthorized access: The company information cannot be accessed until it is confirmed.',
  COMPANY_UNCONFIRMED_COBRAND: 'An unconfirmed company cannot be linked to a cobrand.',
  CONSTRAINT_UNIQUENESS: 'The requested resource already exists and cannot have duplicates.',
  CSV_COLUMN_MISMATCH: 'The uploaded CSV file has incorrect columns.',
  DATABASE_INTEGRITY_ERROR: 'Duplicate entry',
  DEC_REQ_DELETE_IN_PROGRESS: 'A Decedent Request is already in progress and cannot be deleted.',
  DOCUMENT_DELETE_ASSOCIATION_MISMATCH: 'The document does not belong to the specified Decedent Request.',
  DOCUMENT_DELETE_FILE_NOT_FOUND: 'The document file could not be found.',
  INTEGRITY_ERROR: 'An unexpected error occurred. Please try again later.',
  INTERNAL_SERVER_ERROR: 'An internal server error occurred. Please try again later.',
  INVALID_FILE_TYPE: 'The uploaded file is not a valid type.',
  MISSING_COMPANY_INFORMATION: 'Company information is missing. Please provide the required details.',
  MISSING_SITE_SETTINGS: 'Site settings are missing. Please contact support.',
  NO_ASSOCIATED_COMPANY_WITH_DECEDENT_REQUESTS: 'There are no associated companies for this Decedent Request.',
  PAGED_REQUEST_FILTER_ERROR: 'Invalid filter parameters provided.',
  PASSWORD_CHANGE_CONFLICT: 'The new password cannot be the same as the old password. Please choose a different password.',
  RESOURCE_NOT_FOUND: 'The requested resource was not found.',
  USER_DELETE_ENTERPRISE_ASSOCIATION: 'Users associated with an enterprise cannot be deleted.',
  USER_FORBIDDEN_ACCESS: 'You do not have permission to perform this action.',
  VALIDATION_ERROR: 'Invalid data provided. Please check the details and try again.'
};

export type AppError =
  | 'ACCOUNT_ID_NOT_COBRAND'
  | 'ACCOUNT_ID_NOT_GENERIC'
  | 'ADMIN_UNAUTHORIZED'
  | 'AUTH_DEACTIVATED_USER'
  | 'AUTH_EMAIL_CONFLICT'
  | 'AUTH_EMAIL_UNVERIFIED'
  | 'AUTH_INVALID_CREDENTIALS'
  | 'AUTH_NON_ADMIN_ROUTE'
  | 'AUTH_PASSWORD_RESET_REQUIRED'
  | 'COMPANY_DELETE_WITH_DR'
  | 'COMPANY_UNAUTHORIZED_FETCH'
  | 'COMPANY_UNCONFIRMED_COBRAND'
  | 'CONSTRAINT_UNIQUENESS'
  | 'CSV_COLUMN_MISMATCH'
  | 'DATABASE_INTEGRITY_ERROR'
  | 'DEC_REQ_DELETE_IN_PROGRESS'
  | 'DOCUMENT_DELETE_ASSOCIATION_MISMATCH'
  | 'DOCUMENT_DELETE_FILE_NOT_FOUND'
  | 'INTEGRITY_ERROR'
  | 'INTERNAL_SERVER_ERROR'
  | 'INVALID_FILE_TYPE'
  | 'MISSING_COMPANY_INFORMATION'
  | 'MISSING_SITE_SETTINGS'
  | 'NO_ASSOCIATED_COMPANY_WITH_DECEDENT_REQUESTS'
  | 'PAGED_REQUEST_FILTER_ERROR'
  | 'PASSWORD_CHANGE_CONFLICT'
  | 'RESOURCE_NOT_FOUND'
  | 'SITE_MAINTENANCE'
  | 'USER_DELETE_ENTERPRISE_ASSOCIATION'
  | 'USER_FORBIDDEN_ACCESS'
  | 'VALIDATION_ERROR';


export interface ApiErrorResponse<T = any> {
  data: T;
  error_code: AppError;
}


export interface ApiResponse<T> {
  data: T;
  message: string;
  status_code: HttpStatusCode;
}


/** The ID column definition for tables. Usually a UUID String, but in some cases (Country, State) can be an integer. */
export interface IdCol<T = string> {
  id: T;
}


/** Standard timestamp definitions for created/updated datetimes. */
export interface Timestamp {
  created_at: string;
  updated_at: string;
}


/** Combined IdTimestamp object since any table definition will include both, making this a common definition. */
export type IdTimestamp<T = string> = IdCol<T> & Timestamp;


export interface FilterRequest {
  filter_on?: string[];
  filter_on_value?: string[];
}


/**
 * An object to describe a paged request. Also contains attributes for search & filter, if the endpoint supports these.
 */
export interface PagedRequest extends FilterRequest {

  /** while filtering from date and to date */
  from_date?: string;
  /** Page of the results */
  page?: number;
  /** Search Term */
  search?: string;
  /** Size of the pages */
  size?: number;
  to_date?: string;
}


export interface PagedResponse<T> extends PagedRequest {
  // The results on this given page
  results: Array<T>;
  // Total number of potential results
  total: number;
}

<nav class="bg-white">
  <div class="nav__menu--container">
    <a routerLink="/" class="flex items-center">
      <div [hidden]="!logoImageLoading" class="shimmer w-44 h-12">loading</div>
      <img class="nav__menu--logo" alt="NotifyNOW Logo"
           ngSrc="assets/images/logo.png" height="350" width="1861" priority
           (load)="logoImageLoading = false" [hidden]="logoImageLoading">
    </a>

    <div class="flex flex-row md:order-2 items-center gap-4">
      <div class="flex items-center relative" (clickOutside)="toggles.profile = false">
        <button type="button" class="nav__menu--profile--btn" (click)="toggle('profile')">
          <div [hidden]="!profileImageLoading" class="shimmer w-full rounded-full h-12">loading</div>
          <img class="nav__menu--profile--icon" alt="user-profile-img"
               ngSrc="assets/images/stock-profile.jpg" height="360" width="360"
               [hidden]="profileImageLoading" (load)="profileImageLoading = false">
        </button>
        <div [ngClass]="toggles.profile ? 'block' : 'hidden'" class="nav__menu--profile-dropdown"
             id="user-dropdown">
          @for (option of profileOptions; track option) {
            <button
              class="nav__menu--profile-options"
              [innerHTML]="option.text"
              (click)="option.action()">
            </button>
          }
        </div>
      </div>
      <button type="button" (click)="toggles.nav" class="nav__menu--toggle">
        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
      </button>
    </div>

    <div [ngClass]="toggles.nav ? 'block' : 'hidden'" class="nav__menu--dropdown ml-auto">
      <div class="nav__menu--dropdown-menu" (clickOutside)="toggles.language = false">
        <button class="nav__menu--lang-dropdown" type="button" (click)="toggle('language')">
          <span class="nav__menu--text">English</span>
          <svg class="w-2.5 h-2.5 text-primary font-sans text-base font-normal"
               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4"/>
          </svg>
        </button>
        <!-- Dropdown menu -->
        <div [ngClass]="toggles.language ? 'block' : 'hidden'"
             class="nav__menu--lang-dropdown-items">
          @for (option of languageOptionValues; track option) {
            <button
              class="nav__menu--language-options"
              [innerHTML]="option.text"
              (click)="option.action()">
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</nav>

import { Component, computed, effect, ElementRef, HostListener, input, OnInit, signal } from '@angular/core';
import { ApiTypesService } from "src/app/services/api-services/api-types.service";
import { AbstractControl } from "@angular/forms";
import { Country } from "src/app/services/api-services/types/api-types.types";
import { Subscription } from "rxjs";


@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css']
})
export class PhoneInputComponent implements OnInit {
  controlCountryCode = input<AbstractControl>();
  controlPhoneNumber = input<AbstractControl>();
  disabled = input(false);
  hasError = input(false);
  id = input('');
  isLoading = input(false);
  protected countries = signal<Country[]>([]);
  protected toggleList = signal(false);
  protected valueChangesCountryCode = signal<any>(undefined);
  protected selectedCountry = computed<Country | undefined>(() =>
    this.countries().find(e => e.id == this.valueChangesCountryCode()) ?? this.countries().at(0)
  );
  private readonly PLACEHOLDER_DEFAULT = "Enter your Phone Number";
  placeholder = input<string, string | undefined>(
    this.PLACEHOLDER_DEFAULT, {transform: (v) => v ?? this.PLACEHOLDER_DEFAULT});
  private valueChangeSubscriptionCountryCode?: Subscription;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly apiTypes: ApiTypesService
  ) {
    (elementRef.nativeElement as HTMLElement).id = '';
    // Maintain value changes signal
    effect(() => {
      const control = this.controlCountryCode();
      this.valueChangeSubscriptionCountryCode?.unsubscribe();
      this.valueChangeSubscriptionCountryCode = control?.valueChanges.subscribe(
        r => this.valueChangesCountryCode.set(r));
      // Bypass signal restriction with timeout to do initial value update.
      if (control) setTimeout(() => this.valueChangesCountryCode.set(control.value));
    });
  }

  ngOnInit(): void {
    this.apiTypes.getCountries().subscribe({
      next: r => {
        const countries = r.data;
        this.countries.set(countries);
        this.toggleList.set(false);
        this.selectCountry(0);
      }
    });
  }

  protected clickFlag($event: MouseEvent) {
    if (!this.disabled()) {
      this.toggleList.update(e => !e);
      $event.preventDefault();
    }
  }

  @HostListener('document:click', ['$event'])
  protected closeDropDown(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleList.set(false);
    }
  }

  protected getFlagSrc(country: Country): string {
    return `assets/images/flags/${country?.iso2.toLowerCase()}.png`;
  }

  /**
   * Selects a country based on its id in the 'countries' array.
   *
   * @param {number} id ID of the country to select.
   * @param {Event} $event If an Event is given, this will stop its propagation.
   */
  protected selectCountry(id: number, $event?: Event): void {
    $event?.stopPropagation();
    if (this.countries()) {
      this.toggleList.set(false);
      this.controlCountryCode()?.setValue(id);
    }
  }

}

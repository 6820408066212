import { Component, computed, effect, ElementRef, HostListener, input, OnInit, signal } from '@angular/core';
import { AbstractControl } from "@angular/forms";
import { SelectOption } from "src/app/services/select-option.service";
import { Subscription } from "rxjs";
import { TitleCasePipe } from "@angular/common";


/**
 * Dropdown Selection component with a built-in optional search function.
 */
@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.css']
})
export class SelectDropdownComponent implements OnInit {
  control = input<AbstractControl>();
  disabled = input(false);
  hasError = input(false);
  id = input('');
  isLoading = input(false);
  isMultiSelect = input(false);
  optionsInput = input<SelectOption[] | undefined>(undefined, {alias: 'options'});
  placeholder = input('Select');
  showSearchBox = input(false);
  /** Use Title Case formatting pipe if `true`. */
  useTitleCase = input(false);
  protected isOptionsOpen = signal(false);
  /**
   * Default options value. If {@link optionsInput} is undefined, this value will be used for {@link options}.
   * This allows Children to override a default options value.
   */
  protected optionsDefault = signal<SelectOption[]>([]);
  protected options = computed(() => this.optionsInput() ?? this.optionsDefault());
  protected searchKeyword = signal('');
  /** The selected options formatted to text. */
  protected selectionText = computed(() =>
    this.selectedOptions().length == 0
      ? this.placeholder()
      : this.selectedOptions().map(opt => opt.label).join(', ')
  );
  /** The text displayed inside the input box. Either {@link selectionText} or if no selection {@link placeholder}. */
  protected displayText = computed<string>(() => {
    const text = this.selectionText() || this.placeholder();
    return this.useTitleCase() ? (new TitleCasePipe()).transform(text) : text;
  });
  protected valueChanges = signal<any>(undefined);
  protected selectedOptions = computed<SelectOption[]>(() => {
    const control = this.control();
    if (this.valueChanges() == undefined || !control) return [];
    if (this.isMultiSelect() && !Array.isArray(control.value)) control.setValue([]);
    return this.options().filter(
      option => this.isMultiSelect()
        ? (control.value as Array<any>).includes(option.value)
        : option.value === control.value);
  });
  protected hasSelectedValue = computed(() => this.selectedOptions().length != 0);
  private labelElement: Element | null = null;
  private valueChangeSubscription?: Subscription;

  constructor(private readonly elementRef: ElementRef) {
    // Maintain value changes signal
    effect(() => {
      const control = this.control();
      this.valueChangeSubscription?.unsubscribe();
      this.valueChangeSubscription = control?.valueChanges.subscribe(r => this.valueChanges.set(r));
      // Bypass signal restriction with timeout to do initial value update.
      if (control) setTimeout(() => this.valueChanges.set(control.value));
    });
    // Maintain label element
    effect(() => {
      this.labelElement = document.querySelector(`label[for="${this.id()}"]`);
      if (this.labelElement) {
        this.labelElement.addEventListener('click', () => this.toggleDropdown());
      }
    });
  }

  ngOnInit(): void {
    // Keep this function for child overrides. In case this ever needs to be used, the super calls are already in
    // place.
  }

  /**
   * Closes drop down when the use clicks outside elsewhere on the document.
   * @param {Event} [$event]
   */
  @HostListener('document:click', ['$event'])
  protected closeDropDown($event: Event): void {
    const target = $event.target as Node | null;
    if ($event && !this.elementRef.nativeElement.contains(target) && !this.labelElement?.contains(target)) {
      this.isOptionsOpen.set(false);
    }
  }

  /**
   * If this is a multi-select dropdown, then this will tell if a particular {@link SelectOption} is selected in the
   * array of options.
   * @param option
   * @protected
   */
  protected isChecked(option: SelectOption) {
    const control = this.control();
    return this.isMultiSelect() && Array.isArray(control?.value) && control.value.includes(option.value);
  }

  protected onClickOption(option: SelectOption): void {
    const control = this.control();
    if (!control) return;
    if (this.isMultiSelect()) {
      const value = Array.isArray(control.value) ? control.value : [];
      const valueExistsIndex = value.indexOf(option.value);
      if (valueExistsIndex >= 0)
        value.splice(valueExistsIndex, 1);
      else
        value.push(option.value);
      control.setValue([...value]);
    } else {
      this.isOptionsOpen.set(false);
      control.setValue(option.value);
    }
  }

  protected toggleDropdown() {
    this.isOptionsOpen.update(e => !this.isLoading() && !this.disabled() && !e);
  }
}

import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { IGetSdkWorkflow } from "src/app/services/api-services/types/api-onfido.types";
import { ApiResponse } from "src/app/services/api-services/types/api-schema.types";
import { StorageService } from "src/app/services/storage.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { HttpHandler } from "@angular/common/http";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";


@Injectable({
  providedIn: 'root'
})
export class ApiOnfidoService extends ApiService {
  readonly webSocket: WebSocketSubject<ApiResponse<unknown>>;

  constructor(
    override readonly storageService: StorageService,
    override readonly optionService: SelectOptionService,
    handler: HttpHandler
  ) {
    super(storageService, optionService, handler);
    this.webSocket = webSocket(this.url('ws', 'ws'));
  }

  getSdkWorkflow(referrer_url?: string): Observable<ApiResponse<IGetSdkWorkflow>> {
    const params = {params: {}};
    if (referrer_url) params.params[referrer_url] = referrer_url;
    return this.get<ApiResponse<IGetSdkWorkflow>>(this.url('get-sdk-workflow'), params);
  }

  verifyOnfidoId(): Observable<ApiResponse<null>> {
    return this.get<ApiResponse<null>>(this.url('verify-id'));
  }

  protected override url(slug: string, type: "http" | "ws" = "http"): string {
    return super.url(['onfido', slug], type);
  }
}

import { Component } from '@angular/core';


@Component({
  selector: 'app-landing-root',
  templateUrl: './landing-root.component.html',
  styleUrl: './landing-root.component.css'
})
export class LandingRootComponent {

}

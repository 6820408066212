<div class="app-container">
  <main [class.overflow-hidden]="isLoading()" class="main-content">
    <router-outlet/>
    <app-footer [hidden]="maintenanceMode"/>
  </main>
</div>
@if (!maintenanceMode) {
  <app-video-widget/>
  <app-inactivity-timeout-widget/>
}

<app-page-spinner [isVisible]="isLoading()"/>

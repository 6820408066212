import { Component, ElementRef, input, output } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { MessageModalButton } from "src/app/modules/shared/components/message-modal/message-modal.types";

export const YES_NO_BUTTONS: MessageModalButton[] = [
  {text: 'Yes', value: 1, color: 'primary'},
  {text: 'No', value: 0, color: 'secondary'}
];


@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css']
})
export class MessageModalComponent {
  buttonClicked = output<any>();
  buttons = input<MessageModalButton[]>([]);
  message = input<string>();
  id = input.required<string>();
  title = input<string>();

  constructor(
    private readonly modalService: ModalService,
    readonly eRef: ElementRef) {
    (eRef.nativeElement as HTMLElement).id = '';
  }

  protected openConfirmDialog(buttonText: string) {
    this.buttonClicked.emit(buttonText);
    this.modalService.close(this.id());
  }
}

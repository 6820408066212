import { Component, input } from '@angular/core';
import { EventBusService, EventKey } from "src/app/services/event-bus.service";
import { VideoRef, VideoWidgetEvent } from "src/app/components/video-widget/video-widget.component";


@Component({
  selector: 'app-aida-widget',
  templateUrl: './aida-widget.component.html',
  styleUrl: './aida-widget.component.css'
})
export class AidaWidgetComponent {
  animateTooltip = input(true);
  tooltip = input('LANDING.AIDA');
  videoRef = input.required<VideoRef>();

  constructor(private readonly eventBus: EventBusService) {
  }

  protected onClick() {
    this.eventBus.emit<VideoWidgetEvent>({
      key: EventKey.VIDEO_WIDGET,
      data: {
        videoRef: this.videoRef(),
        setState: 'open'
      }
    });
  }
}


<div [ngClass]="checkboxGroupClass()">
  @for (option of options(); track option) {
    <mat-checkbox
      [name]="name()+option.value"
      (change)="onChange(option)"
      [checked]="isChecked(option.value)">
      {{ option.label }}
    </mat-checkbox>
  }
</div>

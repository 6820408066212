import { Component, OnInit } from '@angular/core';
import { ApiSettingsService } from "src/app/services/api-services/api-settings.service";
import { firstValueFrom } from "rxjs";
import { ApiSettings } from "src/app/services/api-services/types/api-settings.types";
import { Router } from "@angular/router";


@Component({
  selector: 'app-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  styleUrl: './maintenance-mode.component.css'
})
export class MaintenanceModeComponent implements OnInit {
  protected settings?: ApiSettings;

  constructor(private readonly apiSettings: ApiSettingsService, private readonly router: Router) { }

  ngOnInit() {
    firstValueFrom(this.apiSettings.getActiveSettings())
      .then(r => this.settings = r.data)
      .finally(() => {
        if (this.settings?.MAINTENANCE_MODE === false)
          void this.router.navigateByUrl("/");
      });
  }
}

import { Injectable } from '@angular/core';
import { concatMap, finalize, from, Observable, of, switchMap } from 'rxjs';
import { ForgotPassword, RegisterSchema, SignInSchema } from "../../modules/auth/auth.schema";
import { ApiService } from "./api.service";
import { HttpHeaders } from "@angular/common/http";
import { StorageKey, StorageMethod } from "../storage.service";
import { ApiResponse } from "src/app/services/api-services/types/api-schema.types";
import { UserData } from "src/app/services/api-services/types/api-user.types";
import { ApiAuth } from "src/app/services/api-services/types/api-auth.types";
import { AdminData } from "src/app/services/api-services/types/api-admin.types";


@Injectable({
  providedIn: 'root'
})
export class ApiAuthService extends ApiService {

  /**
   * Setter & getter for access token
   */
  get accessToken(): string | null {
    return this.storageService.get(StorageKey.ACCESS_TOKEN, StorageMethod.SESSION);
  }

  set accessToken(token: string | null) {
    if (token == null)
      this.storageService.remove(StorageKey.ACCESS_TOKEN, StorageMethod.SESSION);
    this.storageService.set(StorageKey.ACCESS_TOKEN, token, StorageMethod.SESSION);
  }

  /**
   * Setter & getter for access token type
   */
  get accessTokenType(): string | null {
    return this.storageService.get(StorageKey.ACCESS_TOKEN_TYPE, StorageMethod.SESSION);
  }

  set accessTokenType(type: string) {
    this.storageService.set(StorageKey.ACCESS_TOKEN_TYPE, type, StorageMethod.SESSION);
  }

  get isAuthenticated(): boolean {
    return !!this.accessToken;
  }

  get userType(): 'user' | 'admin' | undefined {
    if (this.storageService.userData) return 'user';
    else if (this.storageService.adminData) return 'admin';
    return undefined;
  }

  forgotPassword(body: ForgotPassword): Observable<ApiResponse<null>> {
    return this.post<ApiResponse<null>>(this.url('forgot-password'), body);
  }

  refreshToken(): Observable<ApiResponse<null> & ApiAuth> {
    return this.get<ApiResponse<null> & ApiAuth>(this.url('refresh-token'), {params: {token: this.accessToken ?? ""}})
      .pipe(switchMap(r => {
        this.storeAccessToken(r);
        return of(r);
      }));
  }

  register(body: RegisterSchema): Observable<ApiResponse<UserData>> {
    return this.post<ApiResponse<UserData>>(this.url('register'), body);
  }

  registerByAdmin(body: RegisterSchema): Observable<ApiResponse<UserData>> {
    return this.post<ApiResponse<UserData>>(this.url('register-by-admin'), body);
  }

  signInAdmin(body: SignInSchema): Observable<ApiResponse<AdminData> & ApiAuth> {
    return this.signIn<AdminData>(`admin-login`, body);
  }

  signInUser(body: SignInSchema): Observable<ApiResponse<UserData> & ApiAuth> {
    return this.signIn<UserData>(`user-login`, body);
  }

  /**
   * Signs out the current user by sending a logout request to the server.
   *
   * This function performs the following actions:
   * 1. Sends a POST request to the 'logout' endpoint.
   * 2. Once the request completes (regardless of success or failure), it performs cleanup actions to clear user
   * session data.
   *
   * The cleanup actions include:
   * - Setting the `accessToken` to `null`.
   * - Clearing the `userData` stored in `storageService`.
   * - Clearing the `adminData` stored in `storageService`.
   *
   * @returns {Observable<ApiResponse<null>>} An observable that emits the server's response to the logout request.
   */
  signOut(): Observable<ApiResponse<null>> {
    return this.post<ApiResponse<null>>(this.url('logout'), null).pipe(finalize(() => {
      this.accessToken = null;
      this.storageService.userData = null;
      this.storageService.adminData = null;
    }));
  }

  protected override url(url: string): string {
    return super.url(['auth', url]);
  }

  private signIn<T>(url: string, body: SignInSchema): Observable<ApiResponse<T> & ApiAuth> {
    let formBody = new URLSearchParams();
    for (let k in body)
      formBody.set(k, body[k]);
    let options = {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};
    return from(this.isAuthenticated ? this.signOut() : of(null)).pipe(
      concatMap(() => this.post<ApiResponse<T> & ApiAuth>(this.url(url), formBody.toString(), options)),
      switchMap(r => {
        this.storeAccessToken(r);
        return of(r);
      }));
  }

  private storeAccessToken(response: ApiAuth) {
    this.accessToken = response.access_token;
    this.accessTokenType = response.token_type;
    if (!this.accessToken) {
      throw Error('No Access Token Received');
    }
  }
}

import { Component } from '@angular/core';
import { ISidebarOption } from "src/app/modules/shared/components/sidebar/side-bar.types";


@Component({
  selector: 'app-professional-root',
  templateUrl: './professional-root.component.html',
  styleUrls:['./professional-root.component.css']
})
export class ProfessionalRootComponent {
  protected sidebarOptions: ISidebarOption[] = [
    {img: {src: "assets/icons/house.svg"}, routerLink: "overview", text: "Overview"},
    {img: {src: "assets/icons/house.svg"}, routerLink: "notifier-management", text: "Notifier Management"}
  ];
}

import { Component, input, output, signal } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-file-upload-handler',
  templateUrl: './file-upload-handler.component.html',
  styleUrls: ['./file-upload-handler.component.css']
})
export class FileUploadHandlerComponent {
  allowedExtensions = input<string[]>([]);
  filesSelected = output<File[]>();
  protected files = signal<File[]>([]);
  protected imagePreviewUrl = signal<string | null>(null);

  private readonly MAX_FILE_SIZE = 10 * 1024 * 1024; // (10MB)

  constructor(private toastr: ToastrService) {}

  /**
   * Handles file drop event and processes each dropped file.
   * @param droppedFiles - List of dropped files.
   */
  onFileDrop(droppedFiles: NgxFileDropEntry[]) {
    this.files.set([]);
    this.imagePreviewUrl.set(null);
    for (const droppedFile of droppedFiles) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => this.processFile(file));
      }
    }
  }

  /**
   * Processes a single file: validates type and size, then adds it to the list if valid.
   * @param file - The file to be processed.
   */
  private processFile(file: File) {
    if (this.isValidFile(file)) {
      if (this.isFileSizeValid(file)) {
        this.files().push(file);
        if (file.type.startsWith('image/')) {
          this.createImagePreview(file);
        }
        this.filesSelected.emit(this.files());
      } else {
        this.toastr.error('File size exceeds the 10MB limit');
      }
    } else {
      this.toastr.error('Invalid File Type');
    }
  }

  /**
   * Validates the file type based on the allowed extensions.
   * @param file - The file to be validated.
   * @returns `true` if the file type is valid, otherwise `false`.
   */
  private isValidFile(file: File): boolean {
    const extension = file.name.split('.').pop()?.toLowerCase();
    return extension ? this.allowedExtensions().includes(extension) : false;
  }

  /**
   * Checks if the file size is within the allowed limit.
   * @param file - The file to be checked.
   * @returns `true` if the file size is valid, otherwise `false`.
   */
  private isFileSizeValid(file: File): boolean {
    return file.size <= this.MAX_FILE_SIZE;
  }

  /**
   * Removes all uploaded files and emits an empty file list.
   */
  removeUploadedFile() {
    this.files.set([]);
    this.filesSelected.emit(this.files());
  }

  /**
   * Generates a preview URL for an image file.
   * @param {File} file - The image file to generate a preview for.
   * Uses FileReader to read the file and sets the imagePreviewUrl to
   * a data URL for displaying the image.
   */
  private createImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = () => this.imagePreviewUrl.set(reader.result as string);
    reader.readAsDataURL(file);
  }
}

import { Component, input, Input, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ValidationService } from "src/app/services/validation-service/validation.service";
import { NON_INPUT_TYPES } from "src/app/modules/shared/components/dynamic-form/dynamic-form.types";
import { SelectOption } from "src/app/services/select-option.service";
import { DynamicFormFieldOLD } from "src/app/modules/shared/components/old-dynamic-form/old-dynamic-form.interface";


/**
 * @obsolete See {@link DynamicFormComponent}
 */
@Component({
  selector: 'app-old-dynamic-form',
  templateUrl: './old-dynamic-form.component.html'
})
export class OldDynamicFormComponent {
  @ViewChildren('inputs_child') inputsChildren: QueryList<OldDynamicFormComponent> | undefined;
  parentForm = input<FormGroup>();
  protected dynamicForm!: FormGroup;
  protected fields: DynamicFormFieldOLD[] = [];

  /** Setter for this form */
  get form(): FormGroup {
    return this.dynamicForm;
  }

  /** Getter for this form */
  set form(form: FormGroup) {
    this.dynamicForm = form;
  }

  @Input({required: true, alias: 'fields'})
  set setFields(fields: DynamicFormFieldOLD[] | undefined) {
    this.fields = fields ?? [];
    this.initForm();
  }

  /**
   * If {@link dynamicForm} is {@link FormGroup.touched touched}, or if any dynamic inputs children are touched.
   */
  get touched(): boolean {
    // Check Self
    if (this.form.touched)
      return true;

    // Check Children
    for (let i of this.inputsChildren!.toArray())
      if (i.touched)
        return true;

    // None touched
    return false;
  }

  /**
   * If {@link dynamicForm} is {@link FormGroup.valid valid}, and all dynamic inputs children are valid.
   */
  get valid(): boolean {
    // Check Self
    if (!this.form.valid)
      return false;

    // Check Children
    if (this.inputsChildren)
      for (let i of this.inputsChildren.toArray())
        if (!i.valid)
          return false;

    // All Valid
    return true;
  }

  constructor(private readonly validation: ValidationService) {
    // Must be before ngOnInit
    this.initForm();
  }

  /**
   * Get all the values in an object in the form of `{field_id: value}`
   * @param fields
   */
  static getValuesFromFields(fields: DynamicFormFieldOLD[]): Record<string, any> {    
    const values: Record<string, any> = {};
    const fnGetValue = (field: DynamicFormFieldOLD) => {
      values[field.field_id] = field.value;
      field.sub_fields?.forEach(sub_field => fnGetValue(sub_field));
    };
    fields.forEach(e => fnGetValue(e));
    return values;
  }

  /** Get a name for the given input for a 'confirmation field'. */
  protected getConfirmName(input: DynamicFormFieldOLD) {
    return `${input.field_id}_confirm`;
  }

  /** Get a control by name from the {@link dynamicForm form}. */
  protected getControl(controlName: string): AbstractControl {
    return this.form.controls[controlName];
  }

  /**
   * See {@link ValidationService.getErrors}
   * @param controlKey
   */
  protected getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(this.form.controls[controlKey]);
  }

  /**
   * Checks to determine if the given localValues for the given field ids all satisfy the validation conditions for that
   * field.
   * @param conditions
   * @protected
   */
  protected isConditionsMet(conditions: {field_id: string; is_value: any}[]): boolean {
    const is_met = conditions.map(e => this.getField(e.field_id)?.value == e.is_value);
    return is_met.indexOf(false) < 0;
  }

  /** Handle checkbox change value events */
  protected onChangeCheckbox(controlName: string, option: SelectOption, $event: Event) {
    const control = this.getControl(controlName);
    if (!control) return;
    let value = control.value ?? {};
    value[option.value] = $event.target?.['value'] == "on";
    control.setValue(value);
  }

  /** Set the value for the control with the given name. */
  protected setValue(controlName: string, value: any) {
    this.getControl(controlName)?.setValue(value);
  }

  /** Get a field from an ID. */
  private getField(field_id: string): DynamicFormFieldOLD | undefined {
    return this.fields.find(e => e.field_id == field_id);
  }

  /**
   * Initialize the {@link dynamicForm form}.
   * @private
   */
  private initForm() {
    this.form = this.parentForm() || new FormGroup([]);
    const formControls: Record<string, FormControl> = {};

    this.fields.forEach((field) => {
      // Don't create a control for non-field field types.
      if (NON_INPUT_TYPES.indexOf(field.field_type) >= 0) return [];

      // Create validators
      const validatorMap: Record<string, (e: DynamicFormFieldOLD) => ValidatorFn> = {
        min: input => Validators.minLength(Number(input.validation?.min)),
        max: input => Validators.maxLength(Number(input.validation?.max)),
        is_required: () => Validators.required,
        pattern: input => Validators.pattern(input.validation?.pattern ?? '')
      };
      const validators: any[] = [];
      if (field.validation) Object.keys(field.validation).forEach(key => {
        if (validatorMap[key]) validators.push(validatorMap[key](field));
      });

      // Create FormControl; init value and if disabled.
      const value = {value: field.value, disabled: !!field.validation?.is_disabled};
      formControls[field.field_id] = new FormControl(value, validators);
      field.form_control = formControls[field.field_id];

      // If requested, create Confirm Field with validator
      if (field.validation?.use_confirm) {
        formControls[this.getConfirmName(field)] = new FormControl(value,
          [this.validation.validators.isMatched(formControls[field.field_id], field.label ?? '')]);
        this.validation.syncValidation.set(field.field_id, [
          formControls[this.getConfirmName(field)],
          formControls[this.getConfirmName(field)]
        ]);
      }
      return formControls;
    });

    // Create Form
    for (let controlName in formControls) {
      this.form.addControl(controlName, formControls[controlName]);
    }

    // Subscribe to Value Changes
    this.form.valueChanges.subscribe(() => this.onValueChange());
  }

  /**
   * Handler for `onValueChange` event for {@link dynamicForm}.
   * @private
   */
  private onValueChange() {
    // update values
    for (let field_id in this.form.controls) {
      const field = this.getField(field_id);
      if (field) {
        const control = this.form.controls[field_id];
        field.value = control.value;
      }
    }
  }
}

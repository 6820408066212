import { Injectable } from "@angular/core";

import { SelectOption } from "src/app/services/select-option.service";


/**
 * Collection of functions for comparing & sorting strings.
 */
@Injectable({
  providedIn: 'root'
})
export class SortingService {

  /**
   * Calculate the Sørensen–Dice coefficient between two strings.
   * @return A number `n` where `0 <= n <= 1.0`.
   */
  calcSorensenDiceCoefficient(str1: string, str2: string): number {
    // Sanitize Strings
    const sanitizeString = (str: string) => str.replace('\W+', '').toLowerCase();
    str1 = sanitizeString(str1);
    str2 = sanitizeString(str2);

    // Get Bigrams
    const bigrams1: Set<string> = this.tokenizeString(str1, 2);
    const bigrams2: Set<string> = this.tokenizeString(str2, 2);

    // Get bigram intersection
    const intersection = new Set([...bigrams1].filter(element => bigrams2.has(element)));

    // Calculate the Sørensen–Dice coefficient && Avoid division by zero
    const totalBigrams = bigrams1.size + bigrams2.size;
    return totalBigrams == 0 ? 0 : 2 * intersection.size / totalBigrams;
  }

  /**
   * Sort the given array using Sorensen Dice based on the given search term.
   * @see Array.sort
   * @param options {@link SelectOption} Array to sort. Will sort using the `label` attribute.
   * @param searchTerm Search term to sort by.
   * @param key Key of {@link SelectOption} to sort by. Defaults to {@link SelectOption.label}
   */
  searchSortSorensenDiceOptions<T>(
    options: SelectOption<T>[],
    searchTerm: string,
    key: keyof SelectOption = 'label',
  ): SelectOption<T>[] {
    return options.sort((a, b) =>
      this.calcSorensenDiceCoefficient(searchTerm, b[key]) - this.calcSorensenDiceCoefficient(searchTerm, a[key]));
  }

  /**
   * Create a set of tokens of length {@link tokenLength} from {@link str}.
   * @param str String to tokenize
   * @param tokenLength Length of tokens in the set. This should be `<= str.length`>
   */
  tokenizeString(str: string, tokenLength: number): Set<string> {
    return new Set(
      Array.from(
        {length: str.length - (tokenLength - 1)},
        (_, i) => str.slice(i, i + tokenLength)
      )
    );
  }

}

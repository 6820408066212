import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class ObjectUtilityService {

  /**
   * Get a deep-copy of the given object. This will return an exact copy of the same object with memory references
   * to the source object. This will work on {@link Array Arrays} of {@link Object Objects} and {@link Date Dates} as well.
   * @param source
   */
  static clone<T>(source: T): T {
    let clonedSrc: any;
    // Handle arrays
    if (Array.isArray(source))
      clonedSrc = source.map(item => this.clone(item));

    // Handle Dates
    else if (source instanceof Date)
      clonedSrc = new Date(source.getTime());

    // Handle other objects.
    else if (source && typeof source === 'object')
      clonedSrc = Object.getOwnPropertyNames(source).reduce((o, prop) => {
        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
        o[prop] = this.clone((source as Record<string, any>)[prop]);
        return o;
      }, Object.create(Object.getPrototypeOf(source)));

    else
      clonedSrc = source as T;
    return clonedSrc;
  }
}

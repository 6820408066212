import { Component, computed, ElementRef, input, output, signal } from '@angular/core';


@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.css']
})
export class ButtonLoaderComponent {
  color = input<'none' | 'base' | 'primary' | 'secondary'>('primary');
  disabled = input(false);
  isLoading = input(false);
  onClick = output<MouseEvent>();
  spinnerColor = input<string>();
  text = input<string>();
  type = input('button');
  /** Additional CSS styling */
  protected css = signal('');
  protected buttonClass = computed(() => {
    const css = [this.css()];
    if (this.color() != 'none') css.push(`btn__${this.color()}`);
    if (this.disabled()) css.push(`opacity-50`);
    return css.join(' ');
  });

  protected get getSpinnerColor(): string {
    return this.spinnerColor() ?? (this.color() == 'primary' ? 'accent' : 'primary');
  }

  constructor(eRef: ElementRef) {
    const el = (eRef.nativeElement as HTMLElement);
    this.css.set(el.className);
    el.className = '';
  }

  protected handleOnclick($event: MouseEvent): void {
    if (!this.isLoading() && !this.disabled()) this.onClick.emit($event);
  }
}

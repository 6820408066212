import { Component, ElementRef, input, OnDestroy, OnInit, output, viewChild } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { CommonModalEmitter } from "src/app/modules/shared/components/common-modal/common-modal.types";


@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.css']
})
export class CommonModalComponent implements OnInit, OnDestroy {
  closeOnExternalClick = input(false);
  customClass = input<string>();
  events = output<CommonModalEmitter>();
  forceFullScreen = input(false);
  id = input.required<string>();
  showBody = input(true);
  showClose = input(true);
  usePadding = input(true);
  private readonly NATIVE_ELEMENT: HTMLElement;
  private mainBody = viewChild<ElementRef>('main_body');
  private openStatus = false;

  get isOpen(): boolean {return this.openStatus;}

  constructor(private readonly modalService: ModalService, el: ElementRef) {
    this.NATIVE_ELEMENT = el.nativeElement;
    this.NATIVE_ELEMENT.classList.add('app-common-modal');
  }

  /**
   * Initializes the component.
   * - Sets up a click event listener to close the modal on background click.
   * - Adds this modal instance to the modal service for controller access.
   */
  ngOnInit(): void {
    if (!this.id()) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.NATIVE_ELEMENT);
    this.NATIVE_ELEMENT.addEventListener(
      'click',
      el => (el.target as HTMLElement).className === 'jw-modal' && this.close());
    this.modalService.add(this);
  }

  /**
   * Destroys the component.
   * - Removes this modal instance from the modal service.
   * - Removes the modal NATIVE_ELEMENT from the DOM.
   * - Removes the 'jw-modal-open' class from the body.
   */
  ngOnDestroy(): void {
    this.modalService.remove(this.id());
    this.NATIVE_ELEMENT.remove();
    document.body.classList.remove('jw-modal-open');
  }

  /**
   * Closes the modal.
   * - Sets the modal's display style to 'none'.
   * - Sends a signal via modalDataService indicating the modal is closed.
   * - Removes the 'jw-modal-open' class from the body.
   */
  close(): void {
    this.NATIVE_ELEMENT.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.events.emit({toggled: 'close'});
    this.openStatus = false;
  }

  /**
   * Opens the modal.
   * - Sets the modal's display style to 'block'.
   * - Sends a signal via modalDataService indicating the modal is open.
   * - Adds the 'jw-modal-open' class to the body for styling purposes.
   */
  open(): void {
    this.NATIVE_ELEMENT.style.display = 'block';
    document.body.classList.add('jw-modal-open');
    this.events.emit({toggled: 'open'});
    this.openStatus = true;
  }

  protected clickModal(e: MouseEvent) {
    const clickedOutside = !(this.mainBody()?.nativeElement as Node | undefined)?.contains(<Node>e.target);
    if (clickedOutside && this.closeOnExternalClick())
      this.modalService.close(this.id());
    this.events.emit({click: {outsideBody: clickedOutside}});
  }

  /**
   * Closes a modal by its identifier.
   *
   * This method emits an event to notify that the video modal is closed
   * and then closes the modal using the 'modalService'.
   */
  protected closeModal(): void {
    this.modalService.close(this.id());
  }
}

<div class="relative cursor-pointer">
  <div class="input__field"
       (click)="toggleDropdown()"
       [appShimmer]="isLoading()"
       [matTooltip]="isMultiSelect() ? selectionText() : ''"
       [ngClass]="{
          '!border-red': hasError(),
          'bg-secondary-dark': disabled()
       }">
    <div class="flex justify-between items-center h-full">
      <a class="select__dropdown--selected-option"
         [ngClass]="{'select__dropdown--placeholder': !hasSelectedValue()}">
        {{ displayText() }}
      </a>
      <img class="h-4 w-4 filter-dark-gray my-auto" alt="\/" height="16" ngSrc="assets/icons/angle-down.svg"
           width="16"/>
    </div>
  </div>
  @if (isOptionsOpen()) {
    <div class="select__dropdown--container">
      <div class="select__dropdown">
        @if (showSearchBox()) {
          <input type="text" class="input__field m-2"
            [placeholder]="placeholder() | titleCaseExcept" [(ngModel)]="searchKeyword"/>
        }
        <div>
          @for (option of options() | textFilterList : searchKeyword() : 'label';
            track option; let i = $index) {
            <div class="select__dropdown--option" (click)="onClickOption(option)">
              @if (isMultiSelect()) {
                <input
                  type="checkbox"
                  [checked]="isChecked(option)"
                  class="w-5 h-5 my-auto text-primary accent-[#124683] border-secondary-light outline-none">
              }
              <p class="text__content text-base">{{ useTitleCase() ? (option.label | titlecase) : option.label }}</p>
            </div>
          }
        </div>
      </div>
    </div>
  }
</div>

import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { ApiAuthService } from '../services/api-services/api-auth.service';
import { environment } from "src/environments/environment";

export const AuthGuardConsumer: CanActivateFn | CanActivateChildFn = (_, _state) => {
  const router: Router = inject(Router);
  const auth = inject(ApiAuthService);
  return (auth.isAuthenticated && auth.userType == 'user') || router.parseUrl(`/login`);
};

export const AuthGuardAdmin: CanActivateFn | CanActivateChildFn = (_, _state) => {
  const router: Router = inject(Router);
  const auth = inject(ApiAuthService);
  // TODO For temporary development ease-of-access, should be removed.
  if (!environment.production)
    return true;
  return (auth.isAuthenticated && auth.userType == 'admin') || router.parseUrl(`/admin/login`);
};

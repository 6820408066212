<div class="bg-primary">
  <app-navbar/>
  <div class="p-4 md:p-5 lg:p-8 lg:py-12">
    <div class="text-white text-2xl md:text-3xl lg:text-4xl font-sans mb-4 md:mb-5 lg:mb-8">Welcome Home!</div>
    <div [ngClass]="{
    'layout': sidebarOptions.length > 0,
    'layout-no-gap': sidebarOptions.length == 0,
    }">
      <app-sidebar [sidebarOptions]="sidebarOptions"/>
      <div class="layout-main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<form class="flex flex-col gap-2" [formGroup]="dynamicForm">
  @for (field of fields; track field; let index = $index) {
    @if (isConditionsMet(field.conditionals ?? [])) {
      <div class="flex flex-col">

        @if (field.field_type == 'paragraph') {
          <p class="text__content" [innerHTML]="field.label"></p>
        }

        @if (field.field_type == 'text') {
          <div class="flex flex-col gap-2">
            <div>
              <label class="text__label" [innerHTML]="field.label"></label>
              <input
                class="input__field"
                type="text"
                [formControlName]="field.field_id"
                [placeholder]="field.placeholder ?? field.label"
              />
            </div>
            <ng-container *ngTemplateOutlet="template_errors"/>
            @if (field.validation?.use_confirm) {
              <div>
                <label class="text__label" [innerHTML]="'Confirm ' + field.label"></label>
                <input
                  class="input__field"
                  type="text"
                  [formControlName]="getConfirmName(field)"
                  [placeholder]="field.placeholder ?? ('Confirm ' + field.label)"
                />
                @for (error of getErrors(getConfirmName(field)); track error) {
                  <p class="input__error--label" [innerHTML]="error"></p>
                }
              </div>
            }
          </div>
        }

        @if (field.field_type == 'date') {
          <label class="text__label" [innerHTML]="field.label"></label>
          <input class="input__field !pl-12" placeholder="DD-MM-YYYY"
                 [formControlName]="field.field_id"
                 [placeholder]="field.placeholder ?? field.label ?? ''"
                 [matDatepicker]="input_date"
                 matInput>
          <mat-datepicker [disabled]="field.validation?.is_disabled" #input_date/>
          <div class="relative">
            <mat-datepicker-toggle class="absolute left-0 -top-12" matIconSuffix [for]="input_date"/>
          </div>
          <ng-container *ngTemplateOutlet="template_errors"/>
        }

        @if (field.field_type == 'radio') {
          <div class="flex justify-between my-auto gap-2">
            @if (field.label) {
              <label class="text__label" [innerHTML]="field.label"></label>
            }
            <app-select-radio
              [disabled]="!!field.validation?.is_disabled"
              [options]="field.options"
              (events)="setValue(field.field_id, $event.change?.value)"
              [name]='field.field_id'/>
          </div>
          <ng-container *ngTemplateOutlet="template_errors"/>
        }

        @if (field.field_type == 'checkbox') {
          <div class="flex justify-between my-auto gap-2">
            <label class="text__label" [innerHTML]="field.label"></label>
            <div class="flex gap-2">
              @for (option of field.options ?? []; track option) {
                <input [id]="field.field_id+option.value" type="checkbox"
                       [disabled]="field.validation?.is_disabled"
                       (change)="onChangeCheckbox(field.field_id,option, $event)">
                @if (field.label) {
                  <label
                    class="text__label"
                    [for]="field.field_id+option.value"
                    [innerHTML]="option.label">
                  </label>
                }
              }
            </div>
          </div>
          <ng-container *ngTemplateOutlet="template_errors"/>
        }

        @if (field.field_type == 'dropdown') {
          <label class="text__label" [innerHTML]="field.label"></label>
          <app-select-dropdown
            [disabled]="!!field.validation!.is_disabled"
            [control]="getControl(field.field_id)"
            [options]="field.options"
            [hasError]="false"
            [placeholder]="field.placeholder ?? ''"/>
          <ng-container *ngTemplateOutlet="template_errors"/>
        }

        @if (field.sub_fields) {
          <div class="border__shape-standard border-secondary-dark mt-2">
            <app-old-dynamic-form
              #inputs_child
              [parentForm]="form"
              [fields]="field.sub_fields"/>
          </div>
        }
      </div>

      <ng-template #template_errors>
        @for (error of getErrors(field.field_id); track error) {
          <p class="input__error--label" [innerHTML]="error"></p>
        }
      </ng-template>
    }
  }
</form>

import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, of, tap } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/services/api-services/types/api-schema.types";
import { ApiSettings, ApiSettingsResponse } from "src/app/services/api-services/types/api-settings.types";


@Injectable({
  providedIn: 'root'
})
export class ApiSettingsService extends ApiService {
  private siteSettings?: ApiResponse<ApiSettings>;

  getActiveSettings(): Observable<ApiResponse<ApiSettings>> {
    if (this.siteSettings)
      return of(this.siteSettings);
    return this.get<ApiResponse<ApiSettingsResponse>>(this.url('get-active'))
      .pipe(
        map(r => {
          return {
            ...r,
            data: {
              ...r.data,
              INACTIVITY_TIMOUT_MINUTES: +r.data.INACTIVITY_TIMOUT_MINUTES,
              MAINTENANCE_MODE: !!+r.data.MAINTENANCE_MODE || r.data.MAINTENANCE_MODE == undefined
            }
          };
        }),
        tap(r => this.siteSettings = r)
      );
  }

  protected override url(slug: string): string {
    return super.url(['settings', slug]);
  }
}

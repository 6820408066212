<div class="h-screen">
  <img
    class="p-4 hover:cursor-pointer max-h-28 w-auto"
    alt="NotifyNOW Logo"
    height="350"
    ngSrc="assets/images/logo.png"
    priority
    routerLink="/"
    width="1861">
  <div class="flex h-2/3 w-full justify-center items-center">
    <div class="flex flex-col gap-2 p-6 rounded-lg shadow-md max-w-md h-fit">
      <div class="flex gap-4 justify-center">
        <img
          class="h-8 w-8 my-auto filter-dark-blue"
          alt=""
          height="800"
          ngSrc="assets/icons/exclamation-circle.svg"
          width="800">
        <h2 class="font-bold">Site Maintenance</h2>
      </div>

      <p class="text__content text-center">We're currently performing scheduled maintenance. We'll be back online
        shortly.</p>
      @if (settings && (settings.SUPPORT_EMAIL || settings.SUPPORT_PHONE)) {
        <div class="flex flex-col justify-center">
          <p class="text__content text-primary font-bold">
            Contact Support
          </p>
          @if (settings.SUPPORT_EMAIL) {
            <p class="text__content">
              Email:
              <a class="text__link" href="mailto:{{settings.SUPPORT_EMAIL}}" [innerText]="settings.SUPPORT_EMAIL"></a>
            </p>
          }
          @if (settings.SUPPORT_PHONE) {
            <p class="text__content">
              Phone:
              <a class="text__link" href="mailto:{{settings.SUPPORT_PHONE}}" [innerText]="settings.SUPPORT_PHONE"></a>
            </p>
          }
        </div>
      }
    </div>
  </div>
</div>

@if (sidebarOptions().length > 0) {
  <nav class="md:w-24">
    <ul class="list-none p-0 flex flex-row lg:flex-col gap-4 md:gap-6 lg:gap-8">
      @for (option of sidebarOptions(); track option) {
        <li>
          <a class="sidebar__item" [routerLink]="option.routerLink" (click)="option.action?.()">
            <img
              [ngSrc]="option.img.src" alt="" width="24" height="24"
              [ngClass]="option.img.css ?? 'filter-white'"
              class="m-auto">
            @if (option.text) {
              <div class="sidebar__title" [innerHTML]="option.text"></div>
            }
          </a>
        </li>
      }
    </ul>
  </nav>
}

import {Injectable} from '@angular/core';
import {environment} from "src/environments/environment";
import CryptoJS from "crypto-js";


@Injectable({
  providedIn: 'root',
})
/**
 * Autofill Address Helper
 */
export class EncryptionService {
  private readonly F_ENCRYPT = environment.ENCRYPT_STORAGE;
  private readonly STORAGE_SECRET = environment.LOCAL_STORAGE_SECRET;


  /**
   * If encryption is set to be used in {@link environment}, then this encrypts the given value using
   * {@link environment.LOCAL_STORAGE_SECRET} as a key. It then returns the encrypted value as a string.<br>
   * If encryption is not being used, then {@link data} is returned as a string.<br>
   * If data is nullish, `null` is returned.
   * @see JSON.stringify
   * @param data
   */
  encrypt(data: any) {
    if (data == null) return null;
    data = JSON.stringify(data);
    if (this.F_ENCRYPT) {
      data = CryptoJS.AES.encrypt(data, this.STORAGE_SECRET).toString();
    }
    return data;
  }


  /**
   * If encryption is set to be used in {@link environment}, then this decrypts the given string using
   * {@link environment.LOCAL_STORAGE_SECRET} as a key. It then returns the decrypted value.<br>
   * If encryption is not being used, then {@link data} is returned.<br>
   * If data is nullish, `null` is returned.
   * @see JSON.parse
   * @param data
   */
  decrypt(data: string | null): any | null {
    if (data == null) return null;
    if (this.F_ENCRYPT) {
      data = CryptoJS.AES.decrypt(data, environment.LOCAL_STORAGE_SECRET).toString(CryptoJS.enc.Utf8)
    }
    return JSON.parse(data);
  }
}

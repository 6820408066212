import { ObjectUtilityService } from "src/app/services/object-utility.service";
import { DynamicFormFieldOLD } from "src/app/modules/shared/components/old-dynamic-form/old-dynamic-form.interface";

export const ACCOUNT_NUMBER_INPUTS: DynamicFormFieldOLD[] = [
  {
    field_id: 'account_number_text',
    field_type: 'paragraph',
    label: 'Please enter and confirm your Account Number below.',
    sub_fields: [
      {
        field_id: 'account_number',
        field_type: 'text',
        label: 'Account Number',
        validation: {
          use_confirm: true,
          placeholder: "Enter your Account Number2",
          is_required: true,
          pattern: "[A-Za-z0-9-]*"
        }
      }
    ]
  }
];
export const GENERIC_COMPANY_FIELDS: DynamicFormFieldOLD[] = [
  {
    field_id: 'p1',
    field_type: 'paragraph',
    label: `
              <b>Notice:</b>
              <p>
                The company entered is not currently in our system.
                We will use the information you provide to complete the notification process. If you continue to receive
                invoices/communications from them please notify them directly.
              </p>
            `
  }, {
    field_id: 'industry_type',
    field_type: 'dropdown',
    label: "Industry Type",
    options: [],
    validation: {is_required: true}
  }, {
    field_id: 'phone_number',
    field_type: "text",
    label: "Company Phone Number",
    validation: {
      is_required: true,
      min: 6, max: 16,
      pattern: `[0-9]*$`
    }
  }, {
    field_id: 'is_gt_10k',
    field_type: 'radio',
    label: 'Do you believe this company has more than 10,000 customers?',
    options: [
      {value: true, label: "Yes"},
      {value: false, label: "No"},
      {value: null, label: 'Unknown'}
    ]
  }, {
    field_id: 'p2',
    field_type: 'paragraph',
    label:
      `In order for the notification process to the company to be effective, it is important we receive an
      email address that gets to the appropriate department or individual. The preferred email address in priority
      order would be, <br>
      <ul class="list-disc ml-8">
        <li>Closed Accounts</li>
        <li>Customer Service</li>
        <li>info@ or some other</li>
      </ul>
      * Please input the best email address you can supply: (One email address is mandatory)
      `
  }, {
    field_id: 'email_type_id',
    field_type: 'dropdown',
    label: 'Email Type',
    validation: {
      is_required: true
    },
    options: []
  }, {
    field_id: 'email',
    field_type: 'text',
    label: 'Email Address',
    validation: {
      is_required: true,
      pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
    }
  }
];
export const GENERIC_COMPANY_FIELDS_CONFIRMED: DynamicFormFieldOLD[] = ObjectUtilityService.clone(
  GENERIC_COMPANY_FIELDS);
GENERIC_COMPANY_FIELDS_CONFIRMED.forEach(e => e.validation = {is_disabled: true});

export const TEST_INPUTS: Record<string, DynamicFormFieldOLD[]> = {
  'Dual Fuel': [
    {
      field_id: 'account_number',
      field_type: 'text',
      label: 'Account Number',
      validation: {
        use_confirm: true,
        placeholder: "Enter your Account Number",
        is_required: true,
        pattern: "[A-Za-z0-9-]*"
      }
    },
    {
      field_id: 'p1',
      field_type: 'paragraph',
      label: 'If you are unable to enter a final meter reading, an estimated bill will be generated by Bank of California'
    },
    {
      field_id: 'current_meter_reading',
      field_type: 'text',
      label: 'Current Meter Reading',
      validation: {
        is_required: true,
        pattern: "[0-9]*"
      }
    },
    {
      field_id: 'current_meter_date',
      field_type: 'date',
      label: 'Date of Current Meter Reading',
      validation: {
        is_required: true
      }
    },
    {
      field_id: 'transfer_or_close',
      field_type: 'radio',
      label: '',
      options: [
        {value: 0, label: 'Transfer Account'},
        {value: 1, label: 'Close Account'}
      ],
      validation: {
        is_required: true
      }
    },
    {
      field_id: 'transfer_or_close_conditional',
      field_type: 'none',
      conditionals: [{field_id: 'transfer_or_close', is_value: 0}],
      sub_fields: [
        {
          field_id: 'transfer_p',
          field_type: "paragraph",
          label: "Please enter the contact information for the person you wish to transfer to below."
        },
        {
          field_id: 'transfer_to_name',
          field_type: 'text',
          label: 'Enter Full Name',
          validation: {is_required: true}
        },
        {
          field_id: 'transfer_to_phone',
          field_type: 'text',
          label: 'Enter Phone Number',
          validation: {is_required: true}
        }
      ]
    }
  ]
};

import { Directive, effect, ElementRef, HostListener, input, Renderer2 } from '@angular/core';


/**
 * Directive to add or remove a shimmer effect dynamically based on a condition.
 *
 * @usageNotes
 *
 * ```html
 * <div [appShimmer]="isShimmering"></div>
 * ```
 *
 * Where `isShimmering` is a boolean variable in your component determining whether to apply the shimmer effect.
 */
@Directive({
  selector: '[appShimmer]'
})
export class ShimmerDirective {
  private readonly HTML_ELEMENT!: HTMLElement;
  private readonly SHIMMER_CSS = 'shimmer';
  isActive = input.required<boolean>({alias: 'appShimmer'});

  constructor(private readonly renderer: Renderer2, el: ElementRef) {
    this.HTML_ELEMENT = el.nativeElement;
    // Update CSS
    effect(() => this.isActive() ?
      this.renderer.addClass(this.HTML_ELEMENT, this.SHIMMER_CSS) :
      this.renderer.removeClass(this.HTML_ELEMENT, this.SHIMMER_CSS));
  }

  /**
   * Interrupt keyboard events if shimmer is active.
   */
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    this.isActive() && event.preventDefault();
  }

}

<div class="flex flex-wrap items-center justify-between gap-4 w-full">
  @if (label() || informational().active) {
    <div class="flex">
      @if (label()) {
        <label class="text__label" [innerText]="label()"></label>
      }
      @if (informational().active) {
        <button
          class="my-auto ml-1 flex items-center"
          [matTooltip]="informational().text ?? 'Click to learn more'"
          matTooltipPosition="above"
          (click)="onInformational()">
          <img
            ngSrc="assets/icons/circle-question.svg" alt="I"
            class="inline-block filter-red w-6" width="800" height="800">
        </button>
      }
    </div>
  }
  @if (isLoading()) {
    <div class="w-32 h-8 shimmer"></div>
  } @else {
    <mat-radio-group
      [ngClass]="radioGroupClass()"
      [required]="required()"
      [name]="name()"
      [value]="control()?.value"
      (change)="onChange($event)">
      @for (option of options(); track option) {
        <mat-radio-button
          class="col-span-1"
          color="primary"
          [value]="option.value"
          [disabled]="disabled()">
          <p class="text__content" [innerHTML]="option.label"></p>
        </mat-radio-button>
      }
    </mat-radio-group>
  }
</div>


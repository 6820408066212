import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { AuthInterceptor } from "src/app/interceptors/auth.interceptor";
import { HttpErrorInterceptor } from "src/app/interceptors/http-error.interceptor";
import {
  InactivityTimeoutWidgetComponent
} from "src/app/components/inactivity-timeout-widget/inactivity-timeout-widget.component";
import { VideoWidgetComponent } from "src/app/components/video-widget/video-widget.component";
import { MaintenanceModeComponent } from "src/app/components/maintenance-mode/maintenance-mode.component";
import { provideNgxMask } from 'ngx-mask';
import { NotFoundComponent } from "src/app/components/not-found/not-found.component";
import { PageSpinnerComponent } from "src/app/components/page-spinner/page-spinner.component";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    InactivityTimeoutWidgetComponent,
    VideoWidgetComponent,
    MaintenanceModeComponent,
    NotFoundComponent,
    PageSpinnerComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      timeOut: 5000,
      preventDuplicates: true,
      maxOpened: 5
    }),
    NgOptimizedImage,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      extend: true,
      useDefaultLang: true,
      defaultLanguage: 'en'
    })
  ],
  providers: [
    CurrencyPipe,
    provideNgxMask(),
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}

import { Component, input, output, signal } from '@angular/core';
import { ImageListEvent, ImageListItem } from "src/app/modules/shared/components/image-list/image-list.types";
import { ModalService } from "src/app/services/modal.service";


@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.css']
})
export class ImageListComponent {
  events = output<ImageListEvent>();
  imageListItems = input<ImageListItem[]>([]);
  loading = input(false);
  protected readonly MODAL_ID: string = 'image-list-view-modal';
  protected modalItem = signal<ImageListItem | undefined>(undefined);

  constructor(private readonly modalService: ModalService) {
  }

  onCross(item: ImageListItem) {
    this.events.emit({
      item: item,
      type: "onCross"
    });
  }

  onImage(imageItem: ImageListItem) {
    this.modalItem.set(imageItem);
    this.modalService.open(this.MODAL_ID);
  }
}

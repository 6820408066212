import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private subject = new Subject<EventData>();

  /**
   * Emit an event that can be listened to with {@link on}.
   * @param event {@link EventData} object
   */
  emit<T>(event: EventData<T>) {
    this.subject.next(event);
  }

  /**
   * Listen for events with the given {@link EventKey}. On the valid events, perform an {@link action}.
   * @param key EventKey to trigger on.
   * @param action Action to trigger.
   */
  on<T>(key: EventKey, action: (e: T) => void): Subscription {
    return this.subject.pipe(filter((e: EventData) => e.key === key), map((e: EventData) => e.data)).subscribe(action);
  }
}


/**
 * All events should use an EventKey enum value.
 */
export enum EventKey {
  USER_LOGOUT,
  VIDEO_WIDGET
}


export interface EventData<T = any> {
  data?: T;
  key: EventKey;
}

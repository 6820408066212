import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import jszip from "jszip";


/**
 * This {@link Injectable} helper service contains functions for Blob, File, Zip, and DataURL manipulation & access.
 */
@Injectable({
  providedIn: 'root'
})
export class FileService {

  /**
   * @param dataURL A string to convert to a {@link Blob}.
   * @returns A {@link Blob} representation of the given dataURL
   */
  dataUrlToBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], {type: 'image/png'});
  }

  /**
   * @param blob A {@link Blob} to convert to a base 64 data URL.
   * @returns An Observable which resolves as a string representation of a base 64 data URL.
   */
  blobToDataUrl(blob: Blob): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.onload = (event: any) => observer.next(event.target.result);
      reader.onerror = (event: any) => observer.error(event.target.error);
      reader.onloadend = () => observer.complete();
      reader.readAsDataURL(blob);
    });
  }

  /**
   * @param zip_blob A blob representing a zip file
   * @returns An Observable which will resolve into {@link File} objects representing the files in the zip blob.
   */
  extractFilesFromZip(zip_blob: Blob): Observable<File> {
    return new Observable<File>(observe => {
      jszip.loadAsync(zip_blob).then(zip => {
        Object.keys(zip.files).forEach((filename, i) =>
          zip.files[filename].async('blob').then(blob => {
            observe.next(new File([blob], filename));
            if (i - 1 == Object.keys(zip.files).length)
              observe.complete();
          }));
      });
    });
  }
}

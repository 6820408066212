import { Component, OnInit, signal } from '@angular/core';
import { ISidebarOption } from "src/app/modules/shared/components/sidebar/side-bar.types";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";


@Component({
  selector: 'app-consumer',
  templateUrl: './consumer-root.component.html',
  styleUrls: ['./consumer-root.component.css']
})
export class ConsumerRootComponent implements OnInit {
  private atURL = signal<string | undefined>(undefined);
  private sidebarOptions: ISidebarOption[] = [
    {
      text: "Estate Accounts",
      img: {src: "assets/icons/house.svg"},
      action: this.routeToEstateAccounts.bind(this)
    }
  ];
  protected getOptions = signal(this.atURL() != 'estate-accounts' ? this.sidebarOptions : []);

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.initAtURL();
  }

  private initAtURL() {
    this.atURL.set(this.router.url.split('/').pop());
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) this.atURL.set(e.url.split('/').pop());
    });
  }

  private routeToEstateAccounts() {
    void this.router.navigate([...this.route.snapshot.url, 'estate-accounts'], {relativeTo: this.route});
  }
}

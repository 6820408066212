import { AbstractControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { Injectable } from "@angular/core";


/**
 * Service for synchronizing updates between form controls.
 *
 * This service allows setting up synchronized updates between two {@link AbstractControl AbstractControls} such that
 * when the value of one control changes, the value of the other control is checked and its validity
 * is updated if the values are different. This is useful for keeping the validation state of related controls in sync
 * without an infinite recursion error.
 *
 * @usageNotes
 * Direct Usage:
 * ```
 * import { SynchronousUpdateService } from './synchronous-update.service';
 *
 * constructor(private syncUpdateService: SynchronousUpdateService) { }
 *
 * ngOnInit() {
 *   this.syncUpdateService.set('exampleKey', [this.controlA, this.controlB]);
 * }
 *
 * ngOnDestroy() {
 *   this.syncUpdateService.remove('exampleKey');
 * }
 * ```
 * Usage with {@link ValidationService.syncUpdate ValidationService}:
 * ```
 * import { SynchronousUpdateService } from './synchronous-update.service';
 *
 * constructor(private validation: ValidationService) { }
 *
 * ngOnInit() {
 *   this.validation.syncUpdate.set('exampleKey', [this.controlA, this.controlB]);
 * }
 *
 * ngOnDestroy() {
 *   this.syncUpdateService.remove('exampleKey');
 * }
 * ```
 *
 * @providedIn `root`
 */
@Injectable({
  providedIn: 'root'
})
export class SynchronousValidationService {
  /**
   * A mapping of controls that need to be updated in synchronously. Each entry in the record is keyed by a string and
   * contains two controls (A and B), each with their control instance, previous value, and subscription.
   */
  private syncMaps: Record<string, SyncMap[]> = {};

  /**
   * Remove the synchronized updates for the given key.
   * @param mapKey - The key used store the synchronous update mapping.
   */
  remove(mapKey: string) {
    if (this.syncMaps[mapKey]) {
      this.syncMaps[mapKey].forEach(map => map.subscription.unsubscribe());
      delete this.syncMaps[mapKey];
    }
  }

  /**
   * Sets up synchronous updates between two {@link AbstractControl AbstractControls}. When the value of one control
   * changes, the value of the other control is checked and its validity is updated if the values are different.
   *
   * @param mapKey - The key to store the mapping of the two controls.
   * @param controls - The controls to be updated synchronously.
   */
  set(mapKey: string, controls: AbstractControl[]) {
    this.remove(mapKey);
    this.syncMaps[mapKey] = controls.map(control => {
      const map: SyncMap = {
        control: control,
        prevValue: control.value,
        subscription: control.valueChanges.subscribe(value => {
          if (value === map.prevValue) return;
          map.prevValue = value;
          controls
            .filter(e => e != control)
            .forEach(updateControl => updateControl.updateValueAndValidity());
        })
      };
      return map;
    });
  }
}


export interface SyncMap {
  control: AbstractControl,
  prevValue: unknown,
  subscription: Subscription
}

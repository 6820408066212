import {
  AfterViewInit,
  computed,
  Directive,
  effect,
  ElementRef,
  HostListener,
  input,
  Optional,
  Renderer2
} from '@angular/core';
import { AbstractControl, NgControl } from "@angular/forms";
import { Subscription } from "rxjs";


/**
 * Directive to add or remove error CSS based on the validity of a control.
 * The error style is applied when the control is `touched` and `invalid`.
 *
 * @usageNotes
 *
 * Will apply automatically when using a formControl:
 * ```html
 * <form formGroup="myFormGroup">
 *   <input formControlName="myControl">
 *   <input formControl="myControl">
 * </form>
 * ```
 *
 * You can also inject your control directly. This will override any `formControl` or `formControlName`:
 * ```html
 * <input esControl="myControl">
 * ```
 *
 * The CSS applied when the control is invalid can be overridden:
 * ```html
 * <input esControl="myControl" esCSS="my-custom-error-style">
 * ```
 *
 * The directive can be disabled manually:
 * ```html
 * <form formGroup="myFormGroup">
 *   <input formControlName="myControl" [esDisabled]="true">
 * </form>
 * ```
 */
@Directive({
  selector: '[esControl], [formControlName], [formControl]'
})
export class ErrorStyleDirective implements AfterViewInit {
  /** Override the injected control */
  customControl = input<AbstractControl | undefined>(undefined, {alias: 'esControl'});
  disabled = input(false, {alias: 'esDisabled'});
  errorCSS = input('!border-red', {alias: 'esCSS'});
  forceApply = input(false, {alias: 'esForce'});
  /**
   * Get the appropriate AbstractControl.
   * This will either be {@link customControl} if it is provided, or else {@link ngControl}.
   */
  private formControl = computed<AbstractControl | null>(() => this.customControl() || this.ngControl.control);
  private valueChanges?: Subscription;

  constructor(
    @Optional() private readonly ngControl: NgControl,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {
    /** Effect to trigger {@link updateErrorStyle} if the {@link customControl}  changes. */
    effect(() => {
      if (this.formControl()) {
        this.valueChanges?.unsubscribe();
        this.valueChanges = this.formControl()!.valueChanges.subscribe(_ => this.updateErrorStyle());
      }
      if (this.formControl() || this.forceApply()) this.updateErrorStyle();
    });
  }

  ngAfterViewInit() {
    // Perform an initial check.
    this.updateErrorStyle();
  }

  @HostListener('focusout')
  onFocusOut() {
    this.updateErrorStyle();
  }

  /**
   * Checks if a form control is erroneous.
   * @returns {boolean} True if the control is invalid and touched; otherwise, false.
   */
  private hasError(): boolean {
    const control = this.formControl();
    return this.forceApply() || (!!control && control.invalid && control.touched);
  }

  private updateErrorStyle() {
    if (!this.disabled() && this.hasError())
      this.renderer.addClass(this.el.nativeElement, this.errorCSS());
    else
      this.renderer.removeClass(this.el.nativeElement, this.errorCSS());
  }

}

<nav aria-label="Breadcrumb">
  <mat-card-actions>
    @for (crumb of items(); track crumb; let i = $index) {
      <a mat-button
         (click)="navigateTo(crumb.path)"
         [disabled]="i === items().length - 1">
        {{ crumb.label }}
        @if (i < items().length - 1) {
          >
        }
      </a>
    }
  </mat-card-actions>
</nav>

import { Component, input } from '@angular/core';
import { ISidebarOption } from 'src/app/modules/shared/components/sidebar/side-bar.types';


@Component({
  selector: 'app-sidebar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent {
  sidebarOptions = input.required<ISidebarOption[]>();
}

import { AfterViewInit, Component, OnInit, signal } from '@angular/core';
import { ApiAuthService } from "./services/api-services/api-auth.service";
import { EventBusService, EventKey } from "./services/event-bus.service";
import { NavigationEnd, Router } from "@angular/router";
import { firstValueFrom, forkJoin } from "rxjs";
import {
  GENERIC_COMPANY_FIELDS,
  GENERIC_COMPANY_FIELDS_CONFIRMED
} from "./modules/estate-account-management/estate-account-steps/notify-all/notify-all.const";
import { ApiTypesService } from "./services/api-services/api-types.service";
import { Meta, Title } from "@angular/platform-browser";
import { ApiSettingsService } from "src/app/services/api-services/api-settings.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  protected isLoading = signal(true);
  protected maintenanceMode = false;

  constructor(
    private readonly router: Router,
    private readonly meta: Meta,
    private readonly title: Title,
    private readonly apiTypes: ApiTypesService,
    private readonly apiSettings: ApiSettingsService,
    private readonly authService: ApiAuthService,
    private readonly eventBus: EventBusService,
    private readonly translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    if (!environment.production) console.debug('environment', environment);
    this.initSettings();
    this.initGenericCompanyFields();
    this.listenNavigationScrollToTop();
    this.listenLogout();
    this.translate.setDefaultLang('en');
  }

  ngAfterViewInit() {
    this.isLoading.set(false);
  }

  /**
   * Initialize the generic company fields definitions' dropdown options.
   * TODO depreciate and remove with new dynamic forms implementation.
   * @private
   */
  private initGenericCompanyFields() {
    forkJoin([this.apiTypes.getIndustriesOptions(), this.apiTypes.getEmailTypesOptions()]).subscribe({
      next: options => {
        [['industry_type', 0], ['email_type_id', 1]].forEach(option => {
          [GENERIC_COMPANY_FIELDS, GENERIC_COMPANY_FIELDS_CONFIRMED].forEach(e => {
            const typeList = e.find(e => e.field_id == option[0]);
            if (typeList)
              typeList.options = options[option[1]];
            else
              console.error("typeList was undefined; this shouldn't have happened.");
          });
        });
      }
    });
  }

  private initSettings() {
    this.apiSettings.getActiveSettings().subscribe({
      next: r => {
        const settings = r.data;
        this.title.setTitle(settings.SITE_TITLE);
        this.meta.updateTag(({name: 'keywords', content: settings.META_KEYWORDS}));
        this.maintenanceMode = r.data.MAINTENANCE_MODE;
      },
      complete: () => this.listenNavigationMaintenanceMode()
    });
  }

  /** Listen for USER_LOGOUT events; call API logout and reload the page. */
  private listenLogout() {
    this.eventBus.on(EventKey.USER_LOGOUT,
      () => firstValueFrom(this.authService.signOut()).finally(() => window.location.reload()));
  }

  /**
   * Listen to navigation changes & navigate to maintenance mode page if in maintenance mode AND not an excepted route.
   * @private
   */
  private listenNavigationMaintenanceMode() {
    const EXCEPTED_ROUTES = [
      '/maintenance',
      '/admin/'
    ];
    void this.router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        const IS_EXCEPTED = EXCEPTED_ROUTES.find(e => event.url.startsWith(e)) != undefined;
        if (!IS_EXCEPTED && this.maintenanceMode)
          void this.router.navigateByUrl('/maintenance');
      }
    });
  }

  /**
   * On navigation end, scroll to the top of the page.
   * @private
   */
  private listenNavigationScrollToTop() {
    void this.router.events.forEach(
      e => {
        if (e instanceof NavigationEnd)
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      });
  }
}

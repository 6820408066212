import { Component, computed, effect, OnInit, signal } from '@angular/core';
import { EventBusService, EventKey } from "src/app/services/event-bus.service";
import { VideoRef, VideoWidgetEvent } from "src/app/components/video-widget/video-widget.component";
import { StorageService } from "src/app/services/storage.service";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-landing-navbar',
  templateUrl: './landing-navbar.component.html',
  styleUrl: './landing-navbar.component.css'
})
export class LandingNavbarComponent implements OnInit {
  protected toggles: INavbarToggles = {profile: false, language: false, nav: false};
  private languageOptions = signal<INavbarOptions>({
    en: {text: 'English', action: () => this.setLanguage('en')},
    es: {text: 'Español', action: () => this.setLanguage('es')}
  });
  protected languageOptionValues = computed<INavbarOptionValue[]>(() => Object.values(this.languageOptions()));
  private selectedLanguage = signal<string>('');
  protected selectedLanguageOption = computed(() => this.languageOptions()[this.selectedLanguage()]);

  constructor(
    private readonly eventBus: EventBusService,
    private readonly storage: StorageService,
    private readonly translate: TranslateService
  ) {
    effect(() => this.translate.use(this.selectedLanguage()));
  }

  ngOnInit(): void {
    this.updateLang();
  }

  protected openIntroVideo() {
    this.eventBus.emit<VideoWidgetEvent>({
      key: EventKey.VIDEO_WIDGET,
      data: {
        videoRef: VideoRef.US_LANDING_INTRO,
        setState: 'open'
      }
    });
  }

  /**
   * Trigger a toggle on a key value in {@link toggles}.
   * @param key
   */
  protected toggle(key: keyof INavbarToggles) {
    this.toggles[key] = !this.toggles[key];
  }

  /**
   * Update localstorage localization setting.
   * @param language - Language to set.
   * @private
   */
  private setLanguage(language: string) {
    this.storage.localization = language;
    this.updateLang();
  }

  /**
   * Update {@link TranslateService} language based on localstorage setting.
   * @private
   */
  private updateLang() {
    this.selectedLanguage.set(this.storage.localization ?? this.translate.defaultLang);
  }
}


interface INavbarOptionValue {
  action: () => void;
  text: string;
}


interface INavbarOptions {
  [id: string]: INavbarOptionValue;
}


interface INavbarToggles {
  language: boolean;
  nav: boolean;
  profile: boolean;
}

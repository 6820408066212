import { computed, Directive, effect, ElementRef, Renderer2, signal } from '@angular/core';


/**
 * @usageNotes
 * Place this directive on an input to add an 'eye' icon toggle that will switch the input `type`
 * between `text` and `password`.
 * The input should be inside a container to achieve proper eye position.
 * ```html
 * <div>
 *   <input eyeToggle>
 * </div>
 * ```
 */
@Directive({
  selector: '[eyeToggle]'
})
export class PasswordToggleDirective {
  private isPasswordShown = signal(false);
  private toggleElement!: HTMLInputElement;
  protected srcEye = computed(() => `assets/icons/eye${this.isPasswordShown() ? '-off' : ''}.svg`);

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.setupToggleElement();
    effect(()=>{
      this.el.nativeElement.type = this.isPasswordShown() ? 'text' : 'password';
      this.toggleElement.src = this.srcEye();
    })
  }

  private setupToggleElement() {
    this.toggleElement = this.renderer.createElement('img');
    this.renderer.addClass(this.toggleElement, 'toggle-visibility');
    this.toggleElement.classList.add(...['cursor-pointer', 'absolute']);
    this.renderer.setProperty(this.toggleElement, 'src', this.srcEye());
    this.renderer.setProperty(this.toggleElement, 'width', 18);
    this.renderer.setProperty(this.toggleElement, 'height', 18);
    this.renderer.setStyle(this.toggleElement, 'right', '1rem');
    this.renderer.setStyle(this.toggleElement, 'top', '50%');
    this.renderer.setStyle(this.toggleElement, 'transform', 'translateY(-50%)');

    const parent = this.renderer.parentNode(this.el.nativeElement);
    this.renderer.setStyle(parent, 'position', 'relative');
    this.renderer.appendChild(parent, this.toggleElement);

    this.renderer.listen(this.toggleElement, 'click', () => this.isPasswordShown.update(e => !e));
  }
}

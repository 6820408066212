import { Component, computed, effect, input, signal } from '@angular/core';
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Subscription } from "rxjs";


@Component({
  selector: 'app-validation-panel',
  templateUrl: './validation-panel.component.html',
  styleUrl: './validation-panel.component.css'
})
export class ValidationPanelComponent {
  /** The control whose errors to check. */
  control = input.required<AbstractControl>();
  /** An object defining the text to display for each error type. */
  errorText = input.required<ValidationPanelData>();
  /**
   * If `true`, this will have an absolute 'hover' position. If `false`, it will be inline.
   * @default true
   */
  useHover = input(true);
  /** Signalized */
  protected controlErrors = signal<ValidationErrors | null>(null);
  protected errorPaths = computed<string[][]>(() => this.getPaths(this.errorText()));
  private controlSubscription?: Subscription;

  constructor() {
    // Effect to signalize the control errors.
    effect(() => {
      const control = this.control();
      this.controlSubscription?.unsubscribe();
      this.controlSubscription = undefined;
      if (control) {
        this.controlSubscription = control.valueChanges.subscribe(_ => this.controlErrors.set(control.errors));
        // Need to update immediately too but setting signals is 'disallowed' in other signals. Workaround.
        setTimeout(() => this.controlErrors.set(this.control().errors));
      }
    });
  }

  /**
   * Process an object {@link obj} into an array of paths.
   * @example
   * ```
   * obj = { k1: { k2: 2, k3: 3}, k4:4 }
   * console.log(getPaths(obj)) // Expect [ ['k1', 'k2'], ['k1', 'k3'], ['k4'] ]
   * ```
   * @param obj Object to process
   * @protected
   */
  protected getPaths(obj: { [key: string]: any }): string[][] {
    function traverse(current: { [key: string]: any }, path: string[] = []): string[][] {
      if (typeof current !== 'object' || current === null) return [path];
      return Object.entries(current).flatMap(([key, value]) => traverse(value, [...path, key]));
    }

    return traverse(obj);
  }


  /**
   * Check if an object {@link obj} contains nested attributes matching a given path of `string[]`.
   * @param obj Object to process.
   * @param path Path to find.
   * @protected
   */
  protected hasPath(obj: any, path: string[]): boolean {
    return path.reduce(
      (current, key) =>
        current && typeof current === 'object' && key in current
          ? current[key]
          : undefined,
      obj
    ) !== undefined;
  }

  /**
   * Get the value from an object {@link obj} at the path {@link path} if it exists.
   * @param obj Object to process.
   * @param path Path to search.
   * @protected
   */
  protected valueAt(obj: {[key: string]: any}, path: string[]): any | undefined {
    return path.reduce((current, key) => current?.[key], obj);
  }

}


export interface ValidationPanelData {
  [key: string]: string | ValidationPanelData;
}

import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { SynchronousValidationService } from "src/app/services/validation-service/synchronous-validation.service";
import { CustomValidators } from "src/app/services/validation-service/custom-validators";


/**
 * This component helper makes the population of validation messages in templates dynamic, cleaning up the HTML. It also
 * makes it easy to edit the validation texts from one place.
 */
@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  /** Access to {@link SynchronousValidationService} */
  readonly syncValidation: SynchronousValidationService;
  /** Access to {@link CustomValidators} */
  readonly validators = CustomValidators;
  /**
   * Defines text which can be used globally across the platform. If the error key is provided to
   * {@link setErrors}, this will be ignored.
   */
  private readonly GLOBAL_ERROR_TEXTS: Readonly<ErrorsModel> = {
    bsDate: error => (error.invalid ? 'Invalid Date' : `Unhandled bsDate Error: ${Object.keys(error)}`),
    dateInvalid: _ => `Invalid Date`,
    decimal: _ => "Please enter a valid decimal number (ie 123.123)",
    email: _ => 'Invalid Email',
    future_date: _ => `Death date cannot be in the future.`,
    integer: _ => "Please enter a valid integer number (ie 123)",
    invalidCurrency: _ => `Please enter a valid currency amount`,
    isMatched: (e: {value: boolean, name: string}) => `Must match "${e.name}"`.trim(),
    max: (e: {max: number, actual: number}) => `Value may not exceed ${e.max}`,
    maxlength: error => `Maximum ${error['requiredLength']} characters allowed`,
    min: (e: {min: number, actual: number}) => `Value may not be less than ${e.min}`,
    minlength: error => `Minimum ${error['requiredLength']} characters required`,
    /**
     * This will find offending characters in a pattern validation. Good for patterns which want to exclude certain
     * characters. Will not work well for specific format validation.
     */
    pattern: error => {
      // Remove end-of-string anchor
      const reqPattern: string = error['requiredPattern'].endsWith('$') ? error['requiredPattern'].slice(0,
        -1) : error['requiredPattern'];
      const regex = RegExp(reqPattern, 'g');
      let value: string = error['actualValue'];
      const invalidChars: string[] = [];

      // Find Offending Characters
      while (value.length > 0) {
        const matches = (value.match(regex) ?? []).filter((e) => e != '');
        if (matches.length > 0) {
          let uniqueMatches = [...new Set(matches)].join('');
          uniqueMatches = this.escapeRegExp(uniqueMatches); // Escape any special characters.
          value = value.replace(RegExp(uniqueMatches, 'g'), '');
        } else {
          if (!invalidChars.includes(value[0])) {
            invalidChars.push(value[0]);
          }
          value = value.slice(1);
        }
      }
      invalidChars.sort();

      // If plural
      const s = invalidChars.length > 1 ? 's' : '';
      return `Invalid Character${s}: ${invalidChars.join(', ')}`;
    },
    required: _ => `Field is required`,
    step: (e: {stepAmount: number, value: number}) => {
      if (isNaN(e.value)) return "Value must be a number.";
      return `Value must be divisible by ${e.stepAmount}`;
    }
  };
  // Defines text which can be used globally across the platform. If the error key is provided to {@link
  private errorTexts: Record<string, Record<string, string>> = {};

  constructor(synchronousValidationService: SynchronousValidationService) {
    this.syncValidation = synchronousValidationService;
  }

  /**
   * Get the key of the given control in its parent {@link FormGroup}. If this control doesn't have a parent, this will
   * return `null`.
   * @param control
   */
  getControlKey(control: AbstractControl): string | null {
    const formGroup = control.parent?.controls ?? {};
    return Object.keys(formGroup).find(name => control === formGroup[name]) || null;
  }

  /**
   * Returns an {@link Array} of validation error messages as defined in {@link errorTexts} for the given control.
   * If {@link checkTouched} is `true`, then the errors will only be returned if the control has been marked as touched.
   * @param control The control to get error messages for.
   * @param controlKey The 'controlKey' to match error messages on. If this is not provided, Validation Helper will
   * attempt to find this value automatically from the parent {@link FormGroup}, if it exists.
   * If the key can not be found then only {@link GLOBAL_ERROR_TEXTS Global Error Texts} will be used.
   * @param checkTouched
   * @return An array of strings matching failed validation errors as defined by {@link setErrors}.
   * If no validation errors have failed, an empty array will be returned<br>
   * If {@link checkTouched} is true, then the failed validation errors will only be populated if the control has been
   * touched.
   */
  getErrors(control: AbstractControl, controlKey: string | undefined = undefined, checkTouched = true): Array<string> {
    const arrErrors: Array<string> = [];
    const controlName = this.getControlKey(control) ?? controlKey ?? '';
    if (controlName === '')
    if (control?.errors) {
      const isTouched = !checkTouched || control.touched;
      const errors: ValidationErrors = isTouched ? control.errors : {};
      for (const errorKey in errors) {
        if (Object.keys(errors).includes('required') && errorKey != 'required') continue;
        if (this.errorTexts[controlName]?.[errorKey] != '' && this.errorTexts[controlName]?.[errorKey] !== null) {
          const errorText =
            this.errorTexts[controlName]?.[errorKey] ??
            this.getGlobalErrorText(errorKey)(errors[errorKey]) ??
            `!Missing Error Text [${controlName}.${errorKey}]!`;
          arrErrors.push(errorText);
        }
      }
    }
    return arrErrors;
  }

  /**
   * Set the validation errors in the form of<br>
   * ```Typescript
   * { controlKey: {errorKey: "This is an error"} }
   * ````
   * This can contain any number of keys. If a key provided already exists, it will be overwritten. Existing keys which
   * are not overwritten will not be removed.<br>
   * Refer to {@link GLOBAL_ERROR_TEXTS} to see which texts have a default error handler.
   * @param errors
   */
  setErrors(errors: Record<string, Record<string, string>>): void {
    for (const errorsKey in errors)
      this.errorTexts[errorsKey] = errors[errorsKey];
  }

  /**
   * Escape special characters in regex in the given {@link text}.
   * @param{string} text
   * @return{string}
   */
  private escapeRegExp(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&');
  }

  /**
   * @return The {@link GLOBAL_ERROR_TEXTS} function for the given {@link errorKey}. If it doesn't exist, returns a
   *   function which returns undefined.
   * @param errorKey
   * @private
   */
  private getGlobalErrorText(errorKey: string): ((a?: any) => any) {
    return this.GLOBAL_ERROR_TEXTS[errorKey] ?? (() => undefined);
  }
}


type ErrorsModel = Record<string, (e?: any) => string>;




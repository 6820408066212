import { Component, computed, input, OnDestroy, OnInit, signal } from '@angular/core';
import { InputElement, InputType } from "src/app/modules/shared/components/dynamic-form/dynamic-form.types";
import { FormControl, ValidatorFn } from "@angular/forms";
import { ConditionalEvaluatorService } from "src/app/services/conditional-evaluator.service";
import { ValidationService } from "src/app/services/validation-service/validation.service";


@Component({
  selector: 'app-input-element',
  templateUrl: './input-element.component.html',
  styleUrl: './input-element.component.css'
})
export class InputElementComponent implements OnInit, OnDestroy {
  inputElement = input.required<InputElement>();
  protected confirmFormControl = signal<FormControl | undefined>(undefined);
  protected confirmID = computed<string>(() => `${this.inputElement().input_id}_confirm`);
  protected confirmLabel = computed<string>(() => `Confirm ${this.inputElement().label}`);
  protected formControl = signal<FormControl>(new FormControl());
  /** See {@link InputControlMapping} */
  inputControlMapping = computed<InputControlMapping>(() => {
    return {input_id: this.inputElement().input_id, control: this.formControl()};
  });
  protected inputType = computed<InputType>(
    () => this.inputElement().input_type == "number" ? "text" : this.inputElement().input_type);
  protected placeholder = computed<string>(() => this.inputElement().placeholder ?? this.inputElement().label ?? '');

  constructor(
    private readonly validation: ValidationService,
    private readonly conditionalEvaluator: ConditionalEvaluatorService
  ) {
  }

  ngOnDestroy(): void {
    this.validation.syncValidation.remove(this.inputElement().input_id);
  }

  ngOnInit(): void {
    this.initFormControl();
    this.conditionalEvaluator.registerInput(this.inputElement().input_id, this.formControl());
  }

  /**
   * See {@link ValidationService.getErrors}
   */
  protected getConfirmErrors(): Array<string> {
    return this.validation.getErrors(this.confirmFormControl()!);
  }

  /**
   * See {@link ValidationService.getErrors}
   */
  protected getErrors(): Array<string> {
    return this.validation.getErrors(this.formControl());
  }

  protected test(event: MouseEvent) {
    if (event.ctrlKey) {
      console.log(this.inputElement().input_id);
      console.log(this.formControl().value);
      console.log(this.formControl().errors);
    }
  }

  private initFormControl(): void {
    const validation = this.inputElement().validation;
    const validators: ValidatorFn[] = [];

    // Confirm Input
    if (validation.confirm) {
      const confirmControl = new FormControl();
      this.validation.syncValidation.set(this.inputElement().input_id, [this.formControl(), confirmControl]);
      confirmControl.setValidators(
        this.validation.validators.isMatched(
          this.formControl(),
          this.inputElement().label ?? '')
      );
      validators.push(this.validation.validators.isMatched(confirmControl, this.confirmLabel()));
      this.validation.setErrors({[this.inputElement().input_id]: {isMatched: ''}});
      this.confirmFormControl.set(confirmControl);
    }

    // Build Validation
    if (validation.required)
      validators.push(this.validation.validators.required);
    if (validation.max !== undefined)
      validators.push(this.validation.validators.max(+validation.max));
    if (validation.max_length !== undefined)
      validators.push(this.validation.validators.maxLength(+validation.max_length));
    if (validation.min !== undefined)
      validators.push(this.validation.validators.min(+validation.min));
    if (validation.min_length !== undefined)
      validators.push(this.validation.validators.minLength(+validation.min_length));
    if (validation.pattern !== undefined)
      validators.push(this.validation.validators.pattern(validation.pattern));
    if (validation.step !== undefined)
      validators.push(this.validation.validators.step(validation.step));
    if (this.inputElement().input_type == 'email')
      validators.push(this.validation.validators.email);
    if (this.inputElement().input_type == 'number')
      validators.push(this.validation.validators.integer);

    // todo custom conditions
    if (validation.custom_conditions !== undefined)
      validators.push(
        this.conditionalEvaluator.conditionalGroupsValidator(this.inputElement().validation.custom_conditions));

    this.formControl().addValidators(validators);

    // Default Value.
    this.formControl().setValue(this.inputElement().default_value);
  }
}


/**
 * An object representing an {@link InputElement} by the {@link InputElement.input_id input_id} and {@link FormControl}.
 */
export interface InputControlMapping {
  control: FormControl
  input_id: string,
}

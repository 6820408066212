import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from "@angular/forms";


/**
 * Simply casts an {@link AbstractControl} to a {@link FormControl}.
 */
@Pipe({
  name: 'castFormControl'
})
export class CastFormControlPipe implements PipeTransform {
  transform(value: AbstractControl): FormControl<typeof value['value']> {
    return value as FormControl<typeof value['value']>;
  }
}

import { Component, computed, effect, input, signal, viewChildren } from '@angular/core';
import { DynamicForm } from "src/app/modules/shared/components/dynamic-form/dynamic-form.types";
import {
  FormElementComponent
} from "src/app/modules/shared/components/dynamic-form/form-element/form-element.component";
import { AbstractControl, FormGroup } from "@angular/forms";
import {
  InputControlMapping
} from "src/app/modules/shared/components/dynamic-form/form-element/input-element/input-element.component";
import { Subscription } from "rxjs";


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrl: './dynamic-form.component.css'
})
export class DynamicFormComponent {
  formData = input<DynamicForm, DynamicForm | undefined>([], {transform: i => i ?? []});
  isLoading = input(false);
  submitButton = input<DynamicFormSubmitButton>({show: true});
  protected formElementChildren = viewChildren(FormElementComponent);
  protected InputControlMappings = computed<InputControlMapping[]>(
    () => this.formElementChildren().flatMap(e => e.inputControlMappings()));
  protected formGroup = computed(() =>
    new FormGroup(
      this.InputControlMappings().reduce<Record<string, AbstractControl>>(
        (acc, item) => {
          acc[item.input_id] = item.control;
          return acc;
        }, {})
    )
  );
  protected formValueChanges = signal<Record<string, unknown>>({});
  private formValueChangesSubscription?: Subscription;

  constructor() {
    /**
     * Converting formGroup valueChanges directly with `toSignal` doesn't work for some reason;
     * convert with subscription.
     */
    effect(() => {
      this.formValueChangesSubscription?.unsubscribe();
      this.formValueChangesSubscription = this.formGroup().valueChanges.subscribe(
        c => this.formValueChanges.set(c));
    });
  }

}


export interface DynamicFormSubmitButton {
  show: boolean;
  text?: string;
}

import { Component } from '@angular/core';
import { AdminSidebarOption } from 'src/app/modules/user-admin/admin-sidebar/admin-sidebar.types';

@Component({
  selector: 'app-admin-root',
  templateUrl: './admin-root.component.html',
  styleUrls: ['./admin-root.component.css'],
})
export class AdminRootComponent {
  protected sidebarOptions: AdminSidebarOption[] = [
    { text: 'Dashboard', imgSrc: 'assets/icons/house.svg', url: 'dashboard' },
    {
      text: 'FAQ Management',
      imgSrc: 'assets/icons/house.svg',
      children: [
        { text: 'Generic FAQ', imgSrc: 'assets/icons/circle-empty.svg', url: 'faq-management/generic' },
        { text: 'Cobrand FAQ', imgSrc: 'assets/icons/circle-empty.svg', url: 'faq-management/cobrand' },
      ],
    },
    {
      text: 'Master Types',
      imgSrc: 'assets/icons/house.svg',
      children: [
        { text: 'Relationship Master', imgSrc: 'assets/icons/circle-empty.svg', url: 'master/relationships' },
        { text: 'Industry Type Master', imgSrc: 'assets/icons/circle-empty.svg', url: 'master/industry-types' },
        { text: 'Enterprise Type Master', imgSrc: 'assets/icons/circle-empty.svg', url: 'master/enterprise-types' },
        { text: 'States', imgSrc: 'assets/icons/circle-empty.svg', url: 'master/states' },
        { text: 'Email Master Types', imgSrc: 'assets/icons/circle-empty.svg', url: 'master/email-types' },
        { text: 'Permission Types', imgSrc: 'assets/icons/circle-empty.svg', url: 'master/permission-type' },
        { text: 'Death Certificate Types', imgSrc: 'assets/icons/circle-empty.svg', url: 'master/death-certificate-type' },
        { text: 'Identification Types', imgSrc: 'assets/icons/circle-empty.svg', url: 'master/identification-type' },
      ],
    },
    {
      text: 'Payment Management',
      imgSrc: 'assets/icons/house.svg',
      children: [
        { text: 'Payment Settings', imgSrc:'assets/icons/circle-empty.svg', url: 'payment-settings' },
        { text: 'Payment Gateway', imgSrc:'assets/icons/circle-empty.svg', url: 'payment-gateway/list' },
        { text: 'Payment History', imgSrc:'assets/icons/circle-empty.svg',
          children: [
        { text: 'Transaction History', imgSrc:'assets/icons/circle-empty.svg', url: 'transaction-history' },
        { text: 'Refund History', imgSrc:'assets/icons/circle-empty.svg', url: 'refund-history' }
          ]
        }
      ]
    },
    { text: 'Co-Branding Management', imgSrc: 'assets/icons/circle-empty.svg', url: 'co-branding-management' },
    { text: 'User Management', imgSrc: 'assets/icons/circle-empty.svg', url: 'user-management' },
    { text: 'Estate Account', imgSrc: 'assets/icons/circle-empty.svg', url: 'decedent-request' },
    { text: 'Company Management', imgSrc: 'assets/icons/document-list.svg', url: 'company-management' },
  ];
}

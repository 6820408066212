<div class="flex gap-2 items-center input__field" [esControl]="controlPhoneNumber()" [esForce]="hasError()">
  <button
    class="flex gap-2 outline-none min-w-fit items-center"
    type="button"
    (click)="clickFlag($event)">
    @if (selectedCountry() && !isLoading()) {
      <img [ngSrc]="getFlagSrc(selectedCountry()!)" alt="" width="20" height="20" class="h-3.5 w-auto">
      <p class="text-secondary-dark" [innerText]="selectedCountry()!.phone_code"></p>
    }
  </button>
  <input type="text" [id]="id()" class="input__field_hidden" [placeholder]="placeholder()"
         [formControl]="controlPhoneNumber()! | castFormControl" [attr.disabled]="disabled() || undefined"
         [appShimmer]="isLoading()"
         [esDisabled]="true">
</div>


@if (toggleList()) {
  <div class="absolute mt-4">
    <ul class="input relative border up-arrow bg-white rounded-xs z-20 h-40 overflow-y-auto">
      @for (country of countries(); track country) {
        <li
          class="cursor-pointer px-3 text-grey text-sm py-2 select-option flex flex-row list-act hover:bg-secondary-dark"
          (click)="selectCountry(country.id, $event)"
          [ngClass]="{ 'bg-secondary-dark': selectedCountry()?.id === country.id }">
          <div class="flex justify-between px-2 w-full">
            <div class="flex gap-2">
              <img class="h-3.5 w-auto" [ngSrc]="getFlagSrc(country)" alt="" width="20" height="20">
              {{ country.country_name }}
            </div>
            <span class="text-secondary-dark" [innerText]="country.phone_code"></span>
          </div>
        </li>
      }
    </ul>
  </div>
}

import { Component, input } from '@angular/core';


@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css']
})
export class FileDownloadComponent {
  buttonName = input('');
  disabled = input(false);
  fileContent = input.required<Blob | undefined>();
  fileName = input.required<string>();
  mimeType = input('');

  /**
   * Downloads file
   */
  downloadFile(): void {
    const fileContent = this.fileContent();
    if (this.fileName() && fileContent) {
      const blob = new Blob([fileContent], {type: this.mimeType()});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.fileName());
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up memory usage
    } else {
      console.error('Missing required input properties: fileName, fileContent');
    }
  }
}

import { Component, input } from '@angular/core';
import { BreadcrumbItem } from 'src/app/modules/shared/components/breadcrumb/breadcrumb.types';
import { Router } from '@angular/router';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent {
  items = input.required<BreadcrumbItem[]>();

  constructor(private router: Router) {
  }

  /**
   * Navigates to
   * @param {string} url
   */
  protected navigateTo(url: string) {
    void this.router.navigateByUrl(url);
  }
}

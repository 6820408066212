import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { DECIMAL_PATTERN, EMAIL_PATTERN, INTEGER_PATTERN } from "src/constants/validation.const";


/**
 * A set of custom validators extending Angular's default Validators.
 *
 * This class provides a variety of additional validation functions for use in Angular forms.
 * It includes validators for date ranges, decimals, integers, emails with custom domain patterns,
 * future dates, password strength, and more.
 *
 * @usageNotes
 * From {@link ValidationService.validators ValidationService}:
 * ``` *
 * constructor(private fb: FormBuilder, private validation:ValidationService) { }
 *
 * ngOnInit() {
 *   this.form = this.fb.group({
 *     date: ['', this.validation.validators.dateRange(new Date('2020-01-01'), new Date('2020-12-31'))],
 *     email: ['', this.validation.validators.email],
 *     password: ['', this.validation.validators.passwordValidator()],
 *     ...
 *   });
 * }
 * ```
 * Direct usage:
 * ```
 * import { FormBuilder, FormGroup } from '@angular/forms';
 * import { CustomValidators } from './custom-validators';
 *
 * constructor(private fb: FormBuilder) { }
 *
 * ngOnInit() {
 *   this.form = this.fb.group({
 *     date: ['', CustomValidators.dateRange(new Date('2020-01-01'), new Date('2020-12-31'))],
 *     email: ['', CustomValidators.email],
 *     password: ['', CustomValidators.passwordValidator()],
 *     ...
 *   });
 * }
 * ```
 *
 * The validators can be used directly on form controls to enforce various rules,
 * such as ensuring a value is within a specific date range, matching a certain pattern,
 * or having specific characteristics (e.g., strong passwords).
 *
 */
export class CustomValidators extends Validators {

  /**
   * Interprets `control.value` as a Date, then compares to `minDate` and `maxDate`.
   * If either `minDate` or `maxDate` are undefined, then it will be treated as no limit.
   * @returns `invalidYear: true` if the value is not within the range of `minDate` and `maxDate`.<br>
   * `yearLow: true` if the value is lower than `minDate`. <br>
   * `yearHigh: true` if the value is higher than `maxDate`.
   * @param minDate
   * @param maxDate
   */
  static dateRange(minDate?: Date, maxDate?: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;

      if (minDate && maxDate && minDate.getTime() > maxDate.getTime()) {
        throw new Error('minDate is greater than maxDate!');
      }
      const controlValue = new Date(control.value);

      // Set the time components (hours, minutes, seconds, and milliseconds) to zero
      minDate?.setHours(0, 0, 0, 0);
      maxDate?.setHours(0, 0, 0, 0);
      controlValue?.setHours(0, 0, 0, 0);

      const year = controlValue.getTime();
      const low = minDate && year < minDate.getTime() && year !== 0;
      const high = maxDate && year > maxDate.getTime();
      return low || high ? {dateInvalid: {...(low && {low: true}), ...(high && {high: true})}} : null;
    };
  }

  static decimal(control: AbstractControl): ValidationErrors | null {
    const pTest = CustomValidators.pattern(DECIMAL_PATTERN)(control);
    return pTest ? {decimal: pTest['pattern']} : null;
  }

  /**
   * Checks {@link Validators.email} and a custom pattern for the domain.
   * @param control
   * @returns ValidationErrors | null
   */
  static override email(control: AbstractControl): ValidationErrors | null {
    return super.email(control) ?? ((RegExp(EMAIL_PATTERN)).test(control.value) ? null : {email: true});
  }

  static integer(control: AbstractControl): ValidationErrors | null {
    const pTest = CustomValidators.pattern(INTEGER_PATTERN)(control);
    return pTest ? {integer: pTest['pattern']} : null;
  }

  /**
   * Returns a validator function to check if the provided date is a future date.
   *
   * Parses the date from the control's value and compares it to the current date.
   * If the date is in the future, a {@link ValidationErrors} object with the key 'future_date'
   * and a value of `true` is returned; otherwise, returns `null`.
   *
   * @returns A validator function for ensuring the date is in the future.
   */
  static isFutureDate(): ValidatorFn {
    return (control): ValidationErrors | null => {
      const date = Date.parse(control.value);
      const todayDate = (new Date()).valueOf();
      return date > todayDate ? {future_date: {value: true}} : null;
    };
  }

  /**
   * Validates that this control matches another.
   * Recommend use with {@link SynchronousUpdateService} on {@link controlMatch}
   * to make this control update validation with the other control's validation.
   * @param controlMatch Control whose value to match this control's value with
   * @param controlName Name of this control for identification in error.
   */
  static isMatched(controlMatch: AbstractControl, controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      control.value != controlMatch.value ?
        {isMatched: {value: true, name: controlName}} :
        null;
  }

  /**
   * Returns a validator function to check if the provided date is not a future date.
   *
   * Parses the date from the control's value and compares it to the current date.
   * If the date is in the future, a {@link ValidationErrors} object with the key 'future_date'
   * and a value of `true` is returned; otherwise, returns `null`.
   *
   * @returns A validator function for ensuring the date is not in the future.
   */
  static isNotFutureDate(): ValidatorFn {
    return (control): ValidationErrors | null => {
      const date = Date.parse(control.value);
      const todayDate = (new Date()).valueOf();
      return date > todayDate ? {future_date: {value: true}} : null;
    };
  }

  /**
   * Validates for standard password requirements.
   */
  static passwordValidator(): ValidatorFn {
    const TESTS: Readonly<Record<string, (e: string) => boolean>> = {
      hasUpperCase: e => /[A-Z]+/.test(e),
      hasLowerCase: e => /[a-z]+/.test(e),
      hasNumeric: e => /[0-9]+/.test(e),
      hasSpecialCharacters: e => /[!@#$%^&*]+/.test(e),
      hasMinLength: e => e.length >= 8
    };
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      let passwordStrength: {[key: string]: any} | null = null;
      Object.keys(TESTS).forEach(key => {
        const testResult = TESTS[key](control.value);
        if (!testResult) {
          passwordStrength ??= {};
          passwordStrength[key] = testResult;
        }
      });
      return passwordStrength ? {passwordStrength: passwordStrength} : null;
    };
  }

  /**
   * Returns the results of {@link Validators.required},
   * except when `exceptFn` is `true`, in which case `null` is returned.
   *
   * @param exceptFn Function that returns a boolean indicating whether the validation should be skipped.
   * @returns A validator function.
   */
  static requiredExcept(exceptFn: () => boolean): ValidatorFn {
    return (control): ValidationErrors | null => exceptFn() ? null : Validators.required(control);
  }

  static step = (stepAmount: number): ValidatorFn => {
    if (stepAmount == 0)
      throw Error('Step amount cannot be zero.');
    return (control: AbstractControl): ValidationErrors | null => {
      // Don't evaluate no-input.
      if (control.value == "" || control.value == null) return null;

      // Evaluate number.
      const value = parseInt(control.value);
      if (value % stepAmount !== 0) return {'step': {stepAmount: stepAmount, value: value}};
      return null;
    };
  };
}

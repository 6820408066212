<div class="bg-primary">
  <app-navbar/>
  <div class="p-2 gap-2">
    <h3 class="admin__title pb-3 pt-1">Admin Dashboard</h3>
    <div class="min-h-screen flex">
      <mat-drawer-container class="w-full">
        <mat-drawer mode="side" [opened]="true">
          <app-admin-sidebar [sidebarOptions]="sidebarOptions"/>
        </mat-drawer>
        <div class="admin__layout_main">
          <router-outlet></router-outlet>
        </div>
      </mat-drawer-container>
    </div>
  </div>
</div>

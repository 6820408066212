import { NgModule } from '@angular/core';
import { NavBarComponent } from './components/navbar/nav-bar.component';
import { SideBarComponent } from './components/sidebar/side-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { SelectDropdownComponent } from './components/select-dropdown/select-dropdown.component';
import { SelectStateComponent } from './components/select-dropdown/select-state/select-state.component';
import { SelectCountryComponent } from './components/select-dropdown/select-country/select-country.component';
import { TitleCaseExceptPipe } from 'src/app/pipes/title-case-except-pipe.pipe';
import { TextFilterListPipe } from 'src/app/pipes/text-filter-list.pipe';
import { NgxFileDropModule } from 'ngx-file-drop';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { SelectRadioComponent } from './components/select-radio/select-radio.component';
import {
  SelectRelationshipTypeComponent
} from './components/select-dropdown/select-relationship-type/select-relationship-type.component';
import { ButtonLoaderComponent } from './components/button-loader/button-loader.component';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AidaWidgetComponent } from 'src/app/modules/shared/components/ada-widget/aida-widget.component';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OldDynamicFormComponent } from 'src/app/modules/shared/components/old-dynamic-form/old-dynamic-form.component';
import { ImageListComponent } from './components/image-list/image-list.component';
import { WebcamSnapshotComponent } from './components/webcam-snapshot/webcam-snapshot.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { OnfidoModalComponent } from './components/onido-modal/onfido-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { ShimmerDirective } from "./directives/shimmer.directive";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { ListOptionsComponent } from "./components/list-options/list-options.component";
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FileDownloadComponent } from './components/file-download/file-download.component';
import { FileUploadHandlerComponent } from './components/file-upload-handler/file-upload-handler.component';
import { CastFormControlPipe } from "src/app/pipes/cast-form-control";
import { MatIcon } from '@angular/material/icon';
import { ErrorStyleDirective } from "src/app/modules/shared/directives/error-style.directive";
import { DisabledInputDirective } from './directives/disabled-input.directive';
import {
  ValidationPanelComponent
} from "src/app/modules/shared/components/validation-panel/validation-panel.component";
import { PasswordToggleDirective } from "src/app/modules/shared/directives/eye-toggle.directive";
import { AddressAutocompleteDirective } from "src/app/modules/shared/directives/address-autocomplete.directive";
import { FormElementComponent } from './components/dynamic-form/form-element/form-element.component';
import { InputElementComponent } from './components/dynamic-form/form-element/input-element/input-element.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { SelectCheckboxComponent } from "src/app/modules/shared/components/select-checkbox/select-checkbox.component";
import { MatCheckbox } from "@angular/material/checkbox";
import {
  ParagraphElementComponent
} from "src/app/modules/shared/components/dynamic-form/form-element/paragraph-element/paragraph-element.component";
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    NavBarComponent,
    ListOptionsComponent,
    SideBarComponent,
    FooterComponent,
    ClickOutsideDirective,
    PasswordToggleDirective,
    SelectDropdownComponent,
    SelectStateComponent,
    SelectCountryComponent,
    TitleCaseExceptPipe,
    CastFormControlPipe,
    TextFilterListPipe,
    PhoneInputComponent,
    SelectRadioComponent,
    SelectRelationshipTypeComponent,
    ButtonLoaderComponent,
    CommonModalComponent,
    AidaWidgetComponent,
    OldDynamicFormComponent,
    ImageListComponent,
    WebcamSnapshotComponent,
    OnfidoModalComponent,
    MessageModalComponent,
    ShimmerDirective,
    ErrorStyleDirective,
    BreadcrumbComponent,
    FileDownloadComponent,
    FileUploadHandlerComponent,
    DisabledInputDirective,
    AddressAutocompleteDirective,
    ValidationPanelComponent,
    FormElementComponent,
    InputElementComponent,
    ParagraphElementComponent,
    DynamicFormComponent,
    SelectCheckboxComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    GoogleMapsModule,
    NgxFileDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    NgOptimizedImage,
    MatTooltipModule,
    MatExpansionModule,
    MatSidenavModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule,
    MatButtonModule,
    DatePipe,
    MatSelectModule,
    MatProgressSpinner,
    MatRadioGroup,
    MatRadioButton,
    MatIcon,
    MatCheckbox,
    TranslateModule
  ],
  exports: [
    NavBarComponent,
    SideBarComponent,
    FooterComponent,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    ClickOutsideDirective,
    DisabledInputDirective,
    SelectDropdownComponent,
    SelectStateComponent,
    SelectCountryComponent,
    PhoneInputComponent,
    SelectRadioComponent,
    SelectRelationshipTypeComponent,
    MessageModalComponent,
    GoogleMapsModule,
    ButtonLoaderComponent,
    TitleCaseExceptPipe,
    CastFormControlPipe,
    TextFilterListPipe,
    NgxFileDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModalComponent,
    AidaWidgetComponent,
    OldDynamicFormComponent,
    ImageListComponent,
    WebcamSnapshotComponent,
    MatExpansionModule,
    MatSidenavModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    DatePipe,
    OnfidoModalComponent,
    ListOptionsComponent,
    ShimmerDirective,
    ErrorStyleDirective,
    BreadcrumbComponent,
    FileDownloadComponent,
    FileUploadHandlerComponent,
    ValidationPanelComponent,
    PasswordToggleDirective,
    AddressAutocompleteDirective,
    FormElementComponent,
    InputElementComponent,
    DynamicFormComponent,
    SelectCheckboxComponent
  ]
})
export class SharedModule {
}

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHandler } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { StorageMethod, StorageService } from "src/app/services/storage.service";
import { map } from "rxjs/operators";
import { SelectOption, SelectOptionService } from "src/app/services/select-option.service";
import {
  AccountForm,
  DynamicForm,
  DynamicFormLang
} from "src/app/modules/shared/components/dynamic-form/dynamic-form.types";
import { ApiResponse } from "src/app/services/api-services/types/api-schema.types";


@Injectable({
  providedIn: 'root'
})
export class ApiService extends HttpClient {
  private readonly API_HTTP_URL: string;
  private readonly API_WS_URL: string;

  constructor(
    protected readonly storageService: StorageService,
    protected readonly optionService: SelectOptionService,
    handler: HttpHandler
  ) {
    super(handler);
    this.API_HTTP_URL = environment.API_BASE_URL;
    const url = new URL(this.API_HTTP_URL);
    this.API_WS_URL = `ws://${url.host}${url.pathname}`;
  }

  /**
   * Convert the {@link ApiResponse.data data} result of an observable to a {@link SelectOption} array.
   * @param observable {@link Observable} that returns an {@link ApiResponse}.
   * @param valueKey Key from {@link ApiResponse.data} to use for {@link SelectOption.value}
   * @param labelKey Key from {@link ApiResponse.data} to use for {@link SelectOption.label}
   */
  asOptions<T>(
    observable: Observable<ApiResponse<T[]>>,
    valueKey: keyof T,
    labelKey: keyof T
  ): Observable<SelectOption<T>[]> {
    return observable.pipe(map(r => this.optionService.toOptions<T>(r.data, valueKey, labelKey)));
  }

  getGenericForm(): Observable<ApiResponse<DynamicFormLang>> {
    return this.get<any>(this.url('get-generic-company-form'));
  }

  getTestForm(): Observable<ApiResponse<AccountForm>> {
    return this.get<any>(this.url('get-test-company-form'));
  }

  /**
   * Get a parameters options object from the given object. Any values that are nullish will not be added to the params
   * object.
   * @protected
   */
  protected getParams(obj: {}): {params: {[key: string]: any}} {
    const params = {params: {}};
    Object.keys(obj).forEach(e => obj[e] != null && (params.params[e] = obj[e]));
    return params;
  }

  protected getWithStorage<T = any>(
    observable: Observable<T>, key: string, storageMethod: StorageMethod = StorageMethod.SESSION): Observable<T> {
    const storageKey = `${key}_storage`;
    const storage_data = this.storageService.dynamicGet(storageKey, storageMethod);
    return storage_data ? of(storage_data) :
      observable.pipe(map(
        response => {
          this.storageService.dynamicSet(storageKey, response, storageMethod);
          return response;
        }
      ));
  }

  /**
   * Get the proper API URL for the given endpoint slug.
   * @param slug String or String Array to use to build the full URL
   * @param type Whether to get HTTP ('http') or Websocket ('ws') URL.
   * @protected
   */
  protected url(slug: string | string[], type: 'http' | 'ws' = 'http') {
    // Sanitize base
    let base = type == 'http' ? this.API_HTTP_URL : this.API_WS_URL;
    if (base.endsWith('/')) {
      base = base.slice(0, -1);
    }

    // Sanitize slug
    const endpointSlug = Array.isArray(slug) ? slug.join('/') : (slug || '');
    return `${base}/${endpointSlug}`;
  }
}

<app-common-modal [id]="MODAL_ID">
  <div class="flex flex-col gap-1">
    <h4 class="font-semibold mb-1">
      Inactivity Warning!
    </h4>
    <p class="text__content">
      You have not been active on this site for
      <span class="text-primary font-semibold">{{ timePassedText() }}.</span>

    </p>
    <p class="text__content">
      You will be logged out in
      <span class="text-primary font-semibold">{{ timeRemainingText() }}.</span>
    </p>
  </div>
</app-common-modal>

import { Component, input } from '@angular/core';
import { AdminSidebarOption } from "src/app/modules/user-admin/admin-sidebar/admin-sidebar.types";


@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})
export class AdminSidebarComponent {
  sidebarOptions = input.required<AdminSidebarOption[]>();

  /**
   * Build and return TemplateOutput Context. Used to clean up template code.
   * @protected
   */
  protected getContext(depth: number, options: AdminSidebarOption[]) {
    return {$implicit: {depth: depth + 1, options: options}};
  }

  /**
   * Get appropriate padding based on the depth of the option.
   * @protected
   */
  protected getPadding(depth: number): string {
    const value = depth / 2;
    return `padding-left: ${value}rem`;
  }

  /**
   * This is to preserve typing in template, to cast {@link options} as an {@link AdminSidebarOption} array.
   */
  protected toOptions(options: AdminSidebarOption[]): AdminSidebarOption[] {
    return options;
  }
}

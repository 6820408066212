import { Component, input, ViewEncapsulation } from '@angular/core';
import { ParagraphElement } from "src/app/modules/shared/components/dynamic-form/dynamic-form.types";

@Component({
  selector: 'app-paragraph-element',
  template: `<p class="text__content" [innerHtml]="paragraphElement().html"></p>`,
  styleUrl: './paragraph-element.component.css',
  encapsulation: ViewEncapsulation.None
})
export class ParagraphElementComponent {
  paragraphElement = input.required<ParagraphElement>();
}

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from "src/environments/environment";
import { ApiErrorResponse, ERROR_MESSAGES } from "src/app/services/api-services/types/api-schema.types";
import { Router } from "@angular/router";


/**
 * Interceptor to handle common responses to HTTP errors.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly toastr: ToastrService, private readonly router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            // Connection Error
            case 0:
              this.handleUnknownError(error);
              break;
            case HttpStatusCode.InternalServerError:
              this.handleInternalError(error);
              break;
            default:
              this.handleAppError(error);
              break;
          }
        }
        return throwError(() => error);
      })
    );
  }

  /**
   * Handle API Application Errors.
   * @param error
   * @private
   */
  private handleAppError(error: HttpErrorResponse) {
    const errorCode = (error.error as ApiErrorResponse).error_code;
    const errorMessage = ERROR_MESSAGES[errorCode];

    // Global Error Handling
    switch (errorCode) {
      case "SITE_MAINTENANCE":
        void this.router.navigateByUrl('/maintenance');
    }
    if (errorMessage) this.toastr.error(errorMessage);
  }

  /**
   * Handle responses to {@link HttpStatusCode.InternalServerError}
   * @param error
   * @private
   */
  private handleInternalError(error: HttpErrorResponse) {
    this.toastr.error('Something went wrong.', `Connection Error (${error.status})`);
  }

  /**
   * Handle responses to Unknown (0) Errors
   * @param error
   * @private
   */
  private handleUnknownError(error: HttpErrorResponse) {
    // Failure to connect to backend
    if ((error.url?.indexOf(environment.API_BASE_URL) ?? -1) >= 0) {
      // TODO Replace with handler in http-error interceptor to handle general failure to connect to backend
      this.toastr.error('Error Connecting to Server', `Unknown Connection Error`);
    } else {
      this.toastr.error('Internal Error.', `Connection Error (${error.status})`);
    }
  }
}

<button
  (click)="handleOnclick($event)"
  [disabled]="disabled() || isLoading()"
  [ngClass]="buttonClass()"
  [type]="type()">
  <div class="flex gap-2 items-center justify-center">
    {{ text() }}
    <ng-content/>
    @if (isLoading()) {
      <mat-spinner diameter="20" [color]="getSpinnerColor"/>
    }
  </div>
</button>

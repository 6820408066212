@if (sidebarOptions().length > 0) {
  <nav
    class="flex flex-row lg:flex-col">
    <ng-container
      [ngTemplateOutlet]="listOptions"
      [ngTemplateOutletContext]="getContext(0, sidebarOptions())">
    </ng-container>
  </nav>
}

<ng-template #listOptions let-data>
  <mat-accordion displayMode="flat">
    @for (option of toOptions(data['options']); track option) {
      <mat-expansion-panel
        class="mat-expansion-panel__remove-padding w-52"
        (click)="option.action?.()"
        [routerLink]="option.url"
        [hideToggle]="!option.children">
        <mat-expansion-panel-header [style]="getPadding(data['depth'])">
          <mat-panel-title class="sidebar__item">
            <img [ngSrc]="option.imgSrc" alt="" width="24" height="24"
                 class="filter-dark-gray">
            <p class="sidebar__title" [innerHTML]="option.text"></p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        @if (option.children) {
          <ng-container
            [ngTemplateOutlet]="listOptions"
            [ngTemplateOutletContext]="getContext(data['depth'], option.children)">
          </ng-container>
        }
      </mat-expansion-panel>
    }
  </mat-accordion>
</ng-template>

<app-common-modal
  [id]="modalId"
  [usePadding]="false"
  [showClose]="false"
  [showBody]="false"
  [closeOnExternalClick]="true"
  (events)="onModalEvent($event)">
  @if (videoRef && videoRef.includes('http')) {
    <iframe #videoIFrame [src]="safe(videoRef)" width="100%" height="100%" class="min-h-96"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen></iframe>
  } @else {
    <video #videoPlayer controls playsinline webkit-playsinline="true" width="100%" height="100%">
      <source [src]="videoRef" type="video/mp4">
    </video>
  }
</app-common-modal>

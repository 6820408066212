import { Directive, effect, ElementRef, input, output } from '@angular/core';
import { AddressService } from "src/app/services/address.service";
import { Subscription } from "rxjs";
import { AddressLocationDetails } from "src/app/services/types/address.types";
import { AbstractControl, NgControl } from "@angular/forms";


/**
 * Implements Google's autocomplete on the applied HTML element.
 * This is intended to be used on an {@link HTMLInputElement} that also has an NgControl binding.
 */
@Directive({
  selector: '[addressAutocomplete]'
})
export class AddressAutocompleteDirective {
  addressChanged = output<AddressLocationDetails>();
  cityControl = input<AbstractControl | undefined>(undefined);
  countryControl = input<AbstractControl | undefined>(undefined);
  forCountry = input<number | undefined>(undefined);
  stateControl = input<AbstractControl | undefined>(undefined);
  zipControl = input<AbstractControl | undefined>(undefined);
  private addressSubscription?: Subscription;

  constructor(
    private readonly eRef: ElementRef,
    private readonly addressService: AddressService,
    private readonly ngControl: NgControl
  ) {
    effect(() => {
      this.addressSubscription?.unsubscribe();
      const countryId = this.forCountry();
      if (countryId)
        this.initAutocomplete(countryId);
    });
  }

  /**
   * Initialize autocomplete & listen for updates.
   * @param countryId
   * @private
   */
  private initAutocomplete(countryId: number) {
    this.addressSubscription = this.addressService.initAutocomplete(this.eRef.nativeElement, countryId).subscribe({
      next: r => {
        console.log('VALUE CHANGED', r);
        this.addressChanged.emit(r);
        this.patchValues(r);
      },
      error: e => console.error(e)
    });
  }

  /**
   * Patch the control values from an {@link AddressLocationDetails} object.
   * @param loc An {@link AddressLocationDetails} object to patch the controls on.
   * @private
   */
  private patchValues(loc: AddressLocationDetails) {
    this.ngControl.control?.setValue(loc.address);
    this.countryControl()?.setValue(loc.country);
    this.cityControl()?.setValue(loc.city);
    this.stateControl()?.setValue(loc.state);
    this.zipControl()?.setValue(loc.zipcode);
  }
}

import { Component, ElementRef, input, OnInit } from '@angular/core';
import { ApiTypesService } from 'src/app/services/api-services/api-types.service';
import { SelectDropdownComponent } from "src/app/modules/shared/components/select-dropdown/select-dropdown.component";


@Component({
  selector: 'app-select-relationship-type',
  templateUrl: '../select-dropdown.component.html',
  styleUrls: ['../select-dropdown.component.css']
})
export class SelectRelationshipTypeComponent extends SelectDropdownComponent implements OnInit {
  override placeholder = input('Select Relationship Type');
  override showSearchBox = input(true);

  constructor(
    elementRef: ElementRef,
    private readonly apiService: ApiTypesService) {
    super(elementRef);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.getRelationTypeList();
  }

  private getRelationTypeList(): void {
    this.apiService.getRelationshipsOptions().subscribe({next: r => this.optionsDefault.set(r)});
  }
}

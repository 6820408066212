<app-common-modal [id]="id()">
  <div class="mt-3 text-center sm:mt-5">
    @if (title()) {
      <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title" [innerHTML]="title()"></h3>
    }
    <div class="mt-2">
      <p class="text-sm text-gray-500" [innerHTML]="message()"></p>
    </div>
  </div>
  <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-{{buttons().length}} sm:gap-3">
    @for (button of buttons(); track button) {
      <button
        mat-raised-button
        [color]="button.color"
        (click)="openConfirmDialog(button.text)"
        [innerText]="button.text">
      </button>
    }
  </div>
</app-common-modal>

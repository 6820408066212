<div class="not-found-container">
  <img
    alt="NotifyNOW Logo"
    height="350"
    ngSrc="assets/images/logo.png"
    priority
    routerLink="/"
    width="1861">
  <div>
    <h1>404</h1>
    <p class="text__content">Sorry, the page you are looking for does not exist.</p>
    <a class="text__link" routerLink="/">Go to Home</a>
  </div>
</div>

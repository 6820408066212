import { Component, ElementRef, Input, input, OnInit } from '@angular/core';
import { ApiTypesService } from 'src/app/services/api-services/api-types.service';
import { SelectDropdownComponent } from "src/app/modules/shared/components/select-dropdown/select-dropdown.component";
import { AbstractControl } from '@angular/forms';


@Component({
  selector: 'app-select-country',
  templateUrl: '../select-dropdown.component.html',
  styleUrls: ['../select-dropdown.component.css']
})
export class SelectCountryComponent extends SelectDropdownComponent implements OnInit {
  override placeholder = input('Select  Your Country');
  override showSearchBox = input(true);
  @Input() countryControl: AbstractControl | undefined;

  /**
   * Creates an instance of select country component.
   */
  constructor(elementRef: ElementRef, private readonly apiService: ApiTypesService) {
    super(elementRef);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.apiService.getCountriesOptions().subscribe({
      next: r => {        
        this.optionsDefault.set(r);
        this.countryControl?.setValue(r[0].value);
      } 
    });
  }
}

<nav class="bg-white">
  <div class="landing-nav__menu--container">
    <div class="flex items-center h-full w-auto">
      <img
        class="landing-nav__menu--logo" alt="NotifyNOW Logo" height="350"
        ngSrc="assets/images/logo.png" priority routerLink="/" width="1861">
    </div>

    <div class="landing-nav__right-content">
      <div class="hidden xl:flex items-center gap-4">
        <ng-container *ngTemplateOutlet="nav_buttons"></ng-container>
      </div>
      <div class="flex flex-row xl:hidden order-2">
        <button
          class="landing-nav__menu--toggle" (click)="toggle('nav')"
          (clickOutside)="toggles.nav=false" type="button">
          <img class="w-5 h-5" alt="M" height="800" ngSrc="assets/icons/hamburger-menu.svg" width="800">
        </button>
      </div>

      <div class="flex xl:flex-row-reverse gap-4">
        <!-- START Language Dropdown -->
        <div class="landing-nav__menu--dropdown ml-auto">
          <div class="landing-nav__menu--lang-dropdown-menu">
            <button
              class="landing-nav__menu--btn-language"
              (click)="toggle('language')"
              (clickOutside)="toggles.language = false"
              type="button">
              <span
                class="landing-nav__menu--text sm:hidden"
                [innerText]="selectedLanguageOption().text | slice: 0:2"></span>
              <span class="landing-nav__menu--text hidden sm:block" [innerText]="selectedLanguageOption().text"></span>
              <img class="w-2.5 h-2.5 mr-2 sm:mr-0" alt="" height="16" ngSrc="assets/icons/angle-down.svg" width="16">
            </button>
            <!-- Dropdown menu -->
            <div class="landing-nav__menu--lang-dropdown-items" [hidden]="!toggles.language">
              @for (option of languageOptionValues(); track option) {
                <button
                  class="landing-nav__menu--language-options"
                  [innerHTML]="option.text"
                  (click)="option.action()">
                </button>
              }
            </div>
          </div>
        </div>
        <!-- END Language Dropdown -->
        <!-- START flag image -->
        <div class="my-auto w-10">
          <img
            class="w-10 h-8 object-cover rounded-sm"
            alt="flag"
            height="60"
            ngSrc="assets/images/flags/us-md.png"
            width="92">
        </div>
        <!-- END flag image -->

      </div>
    </div>
  </div>
  <div class="flex flex-col gap-4 items-start px-4 pb-4" [hidden]="!toggles.nav">
    <button class="landing-nav__btn-minor" routerLink="/">Home</button>
    <ng-container *ngTemplateOutlet="nav_buttons"></ng-container>
  </div>
</nav>
<div class="landing-nav__divider"></div>
<ng-template #nav_buttons>
  <button class="landing-nav__btn-minor" (click)="openIntroVideo()" translate="LANDING.NAVBAR.OPTION_1"></button>
  <button class="landing-nav__btn-minor" routerLink="/faq" translate="LANDING.NAVBAR.OPTION_2"></button>
  <button class="landing-nav__btn-minor" routerLink="/contact-us" translate="LANDING.NAVBAR.OPTION_3"></button>
  <button class="landing-nav__btn-major" routerLink="/login" translate="LANDING.NAVBAR.OPTION_4"></button>
  <button class="landing-nav__btn-major" routerLink="/register" translate="LANDING.NAVBAR.OPTION_5"></button>
</ng-template>

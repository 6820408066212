import { Injectable } from '@angular/core';
import { CommonModalComponent } from "../modules/shared/components/common-modal/common-modal.component";


@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: CommonModalComponent[] = [];
  private openModals: string[] = [];

  /**
   * Add modal. Modals must be unique by ID. If a duplicate modal is added, the previous modal with a matching ID will
   * be removed.
   * @param {any} modal
   */
  add(modal: CommonModalComponent): void {
    // Handle duplicates
    if (this.contains(modal)) {
      this.remove(modal.id());
      console.warn('Added a duplicate modal; removing old modal.');
    }
    // Add modal to array of active modals
    this.modals.push(modal);
  }

  /**
   * close model based on id
   * @param {string} id
   */
  close(id: string): void {
    const deleteID = this.openModals.findIndex(e => e == id);
    if (deleteID >= 0) {
      this.openModals.splice(deleteID, 1);
      this.updateOpenModals();
    }
  }

  /**
   * Check if the specified modal exists in the service. Can be used on a modal object containing an id field,
   * or a string containing the ID to look for.
   * @param modal Modal object containing id field or modal ID string.
   */
  contains(modal: CommonModalComponent | string): boolean {
    return this.modals.filter((x) => (x.id === (typeof modal == 'string' ? modal : modal.id))).length > 0;
  }

  /**
   * Check if the modal with the given ID is open.
   * @param id
   * @returns A boolean value reflecting {@link CommonModalComponent.isOpen} for the given ID, or `undefined` if the
   * modal doesn't exist.
   */
  isOpen(id: string): boolean | undefined {
    return this.getModal(id)?.isOpen;
  }

  /**
   * open model based on id
   * @param {string} id
   */
  open(id: string): void {
    const openIndex = this.openModals.findIndex(e => e == id);
    // Existing ID floats to top of list.
    if (openIndex >= 0)
      this.openModals.splice(openIndex, 1);
    this.openModals.push(id);
    this.updateOpenModals();
  }

  /**
   * Remove the given modal from the service.
   * @param {string} id
   */
  remove(id: string): void {
    this.modals = this.modals.filter((x) => x.id() !== id);
  }

  /**
   * @param id
   * @return the modal which matches the given id from {@link modals}.
   * @private
   */
  private getModal(id: string) {
    return this.modals.find((x) => x.id() === id);
  }

  /**
   * open model based on id
   * @param {string} id
   */
  private openModal(id: string): boolean {
    const modal = this.getModal(id);
    if (modal)
      modal.open();
    else
      console.error(`open: Modal ${id} does not exist!`);
    return !!modal;
  }

  /**
   * All modals are closed, then the last modal defined in {@link openModals} is opened.
   * @private
   */
  private updateOpenModals() {
    this.modals.forEach(e => this.isOpen(e.id()) && e.close());
    if (this.openModals.length > 0) {
      // Open modal at the top of the stack.
      const openID = this.openModals[this.openModals.length - 1];
      this.openModal(openID);
    }
  }
}

<div class="flex flex-col gap-2">
  @if (paragraphElement()) {
    <app-paragraph-element [paragraphElement]="paragraphElement()!"/>
  } @else if (inputElement()) {
    <app-input-element [inputElement]="inputElement()!"/>
  }

  @if (formElement().sub_elements.length) {
    <div class="flex flex-col gap-2 p-2 border border-secondary-dark rounded-lg">
      @for (childElement of formElement().sub_elements; track childElement) {
        <app-form-element class="col-span-1" [formElement]="childElement" [valueChanges]="valueChanges()"/>
      }
    </div>
  }
</div>

import { Injectable } from '@angular/core';
import { EncryptionService } from "src/app/services/encryption.service";
import { environment } from "src/environments/environment";
import { UserData } from "src/app/services/api-services/types/api-user.types";
import { AdminData } from "src/app/services/api-services/types/api-admin.types";


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  get adminData(): AdminData | null {
    return this.get(StorageKey.ADMIN_DATA, StorageMethod.SESSION);
  }

  set adminData(data: AdminData | null) {
    if (data == null) {
      this.remove(StorageKey.ADMIN_DATA, StorageMethod.SESSION);
    } else {
      this.set(StorageKey.ADMIN_DATA, data, StorageMethod.SESSION);
    }
  }

  get localization(): string | null {
    return this.get(StorageKey.ISO2_LOCALIZATION);
  }

  /**
   * ISO2 Localization setting.
   */
  set localization(data: string) {
    if (!data)
      this.remove(StorageKey.ISO2_LOCALIZATION);
    else
      this.set(StorageKey.ISO2_LOCALIZATION, data);
  }

  /** userData Getter */
  get userData(): UserData | null {
    return this.get(StorageKey.USER_DATA, StorageMethod.SESSION);
  }

  /** userData Setter */
  set userData(data: UserData | null) {
    if (!data)
      this.remove(StorageKey.USER_DATA, StorageMethod.SESSION);
    else
      this.set(StorageKey.USER_DATA, data, StorageMethod.SESSION);
  }

  constructor(private readonly encryptHelper: EncryptionService) {
    // Init localization settings.
    this.localization ??= environment.LOCAL_ISO_2;
  }

  /**
   * Clear storage.
   * @param method If provided, only this storage method will be cleared. If this is undefined, then both storage
   * methods will be cleared.
   */
  clear(method?: StorageMethod) {
    if (method !== undefined) {
      this.getStorageMethod(method).clear();
      return;
    }
    localStorage.clear();
    sessionStorage.clear();
  }

  /**
   * Get stored decrypted data in localStorage. This get-function is to be used for cases where the key is dynamically
   * computed. If the key is a constant, use {@link get} with {@link StorageKey} for key-checking.
   * @param key - Key to identify the data.
   * @param method - Storage method to use — Local Storage or Session Storage. Local Storage is the default.
   */
  dynamicGet<T = any>(key: string, method: StorageMethod = StorageMethod.LOCAL): T | null {
    return this._get(key, method);
  }

  /**
   * Store encrypted data in localStorage. This set-function is to be used for cases where the key is dynamically
   * computed. If the key is a constant, use {@link set} for key-checking.
   * @param key - Key to identify the data.
   * @param value - Data to store.
   * @param method - Storage method to use — Local Storage or Session Storage. Local Storage is the default.
   */
  dynamicSet(key: string, value: any, method: StorageMethod = StorageMethod.LOCAL): void {
    this._set(key, value, method);
  }

  /**
   * Retrieve and decrypt stored data from localStorage by DataKeys.
   * @param key - Key to identify the data.
   * @param method - Storage method to use — Local Storage or Session Storage. Local Storage is the default.
   * @return Stored data.
   */
  get<T = any>(key: StorageKey, method: StorageMethod = StorageMethod.LOCAL): T | null {return this._get(key, method);}

  /**
   * Remove data from local storage by DataKeys.
   *
   * @param key - Key of the data to be removed.
   * @param method - Storage method to use — Local Storage or Session Storage. Local Storage is the default.
   */
  remove(key: string, method: StorageMethod = StorageMethod.LOCAL) {
    this.getStorageMethod(method).removeItem(key);
  }

  /**
   * Store encrypted data in localStorage.
   *
   * @param key - A {@link StorageKey} to identify the data.
   * @param value - Data to store.
   * @param method - Storage method to use — Local Storage or Session Storage. Local Storage is the default.
   */
  set(key: StorageKey, value: any, method: StorageMethod = StorageMethod.LOCAL): void {this._set(key, value, method);}

  private _get<T = any>(key: string, method: StorageMethod = StorageMethod.LOCAL): T | null {
    return this.encryptHelper.decrypt(this.getStorageMethod(method).getItem(key));
  }

  private _set(key: string, value: any, method: StorageMethod = StorageMethod.LOCAL): void {
    this.getStorageMethod(method).setItem(key, this.encryptHelper.encrypt(value));
  }

  private getStorageMethod(method: StorageMethod): Storage {
    return method == StorageMethod.LOCAL ? localStorage : sessionStorage;
  }
}


export enum StorageKey {
  ADMIN_DATA = 'ADMIN_DATA',
  USER_DATA = 'USER_DATA',
  UNVERIFIED_USER_EMAIL = 'UNVERIFIED_USER_EMAIL',
  ISO2_LOCALIZATION = 'ISO2_LOCALIZATION',
  ACCESS_TOKEN_TYPE = 'ACCESS_TOKEN_TYPE',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  TEMP_DR_PAYMENT_STATUS = 'DR_PAYMENT_STATUS'// TODO set for handling the payment status in ui. will remove in future
                                              // use
}


export enum StorageMethod {
  LOCAL,
  SESSION
}
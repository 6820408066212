<div id="consumer-root-el" class="bg-primary h-full">
  <app-navbar/>
  <div class="p-4 md:p-5 lg:p-8 lg:py-12">
    <div [ngClass]="{
      'layout': getOptions().length > 0,
      'layout-no-gap': getOptions().length == 0,
    }">
      <app-sidebar [sidebarOptions]="getOptions()"/>
      <div class="layout-main">
        <router-outlet/>
      </div>
    </div>
  </div>
</div>

export const environment = {
  production: false,
  API_BASE_URL: "http://159.203.81.92:8002/api/v1", //'https://fastapi-app.dev.k8s.estate-registry.com/api/v1',
  LOCAL_STORAGE_SECRET: 'D96B2B84E3433063E8355912A845488A',
  ENCRYPT_STORAGE: false,
  LOCAL_ISO_2: 'en',
  GOOGLE_API_KEY: 'AIzaSyC8DuXEQ8kS9ZteOicKDqYBzmfapH4qRNc',
  STRIPE_PUBLIC_KEY: 'pk_test_51OUIT7HkIHsq7znWTXr5PSd8P4RxuDhFIOrWnkJmrPK3Gvy0RFWXipYVcWCbiynP7ZuH6GhVqv9Or1zpqPEty9aU00hPvtNOD3',
  STRIPE_API_URL: 'https://api.stripe.com/v1/tokens',
};

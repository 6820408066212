import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from "src/app/services/modal.service";
import { CommonModalEmitter } from "src/app/modules/shared/components/common-modal/common-modal.types";
import { EventBusService, EventKey } from "src/app/services/event-bus.service";
import { DomSanitizer } from "@angular/platform-browser";


/**
 * This should be placed in the {@link AppComponent} template to start the functionality. Once there, it can be
 * interacted with using the {@link EventBusService} component.
 */
@Component({
  selector: 'app-video-widget',
  templateUrl: './video-widget.component.html',
  styleUrl: './video-widget.component.css'
})
export class VideoWidgetComponent {
  @ViewChild('videoIFrame') videoIFrame?: ElementRef;
  @ViewChild('videoPlayer') videoPlayer?: ElementRef;
  protected modalId = 'video-widget-modal-id';
  protected videoRef?: VideoRef;

  constructor(
    private readonly modalService: ModalService,
    private readonly sanitizer: DomSanitizer,
    eventBus: EventBusService) {
    eventBus.on<VideoWidgetEvent>(EventKey.VIDEO_WIDGET, e => {
      this.videoRef = e.videoRef ?? this.videoRef;
      if (e.setState == 'open')
        this.modalService.open(this.modalId);
      else if (e.setState == 'closed')
        this.modalService.close(this.modalId);
    });
  }

  protected onModalEvent($event: CommonModalEmitter) {
    if ($event.toggled == 'open') {
      // If the modal has been opened, load the video source (if it exists).
      if (this.videoPlayer && this.videoRef)
        (<HTMLVideoElement>this.videoPlayer.nativeElement).load();
      else if (this.videoIFrame) {
        console.log('this.videoIFrame', this.videoIFrame);
        console.log('vidoeref', this.videoRef);
      }

    } else if ($event.toggled == 'close') {
      // Pause the video and reset its playback position to the beginning
      const videoElement: HTMLVideoElement = this.videoPlayer?.nativeElement;
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  }

  protected safe(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


export enum VideoRef {
  US_LANDING_INTRO = "assets/videos/us_assistant_intro-video.mp4",
  NOTIFIER_INTRO = "https://player.vimeo.com/video/798942731",
}


export interface VideoWidgetEvent {
  setState?: 'open' | 'closed';
  videoRef?: VideoRef;
}

import { Component, ElementRef, input, OnDestroy, output, signal, viewChild } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { ISnapshotSize } from "src/app/modules/shared/components/webcam-snapshot/webcam-snapshot.types";
import { FileService } from "src/app/services/file.service";


@Component({
  selector: 'app-webcam-snapshot',
  templateUrl: './webcam-snapshot.component.html',
  styleUrls: ['./webcam-snapshot.component.css']
})
export class WebcamSnapshotComponent implements OnDestroy {
  canvas = viewChild<ElementRef>('canvas');
  events = output<File>();
  stopOnSnapshot = input(true);
  video = viewChild<ElementRef>('video');
  protected isWebcamBlocked = signal(false);
  protected mediaStreamStarted = signal(false);
  protected outputSize = signal<ISnapshotSize>({width: 0, height: 0});
  private mediaStream?: MediaStream;

  constructor(
    private readonly toastr: ToastrService,
    private readonly fileHelper: FileService) {
  }

  ngOnDestroy() {
    this.stopWebcam();
  }

  stopWebcam() {
    this.mediaStream?.getVideoTracks().forEach(e => e.stop());
    this.mediaStreamStarted.set(false);
  }

  /**
   * Captures a snapshot from the webcam video stream
   */
  protected captureSnapshot() {
    this.drawImageToCanvas(this.video()?.nativeElement);
    const dataUrl = (this.canvas()?.nativeElement as HTMLCanvasElement).toDataURL('image/png');
    const blob = this.fileHelper.dataUrlToBlob(dataUrl);
    const file = new File([blob], 'Webcam Image.png', {type: 'image/png'});
    this.events.emit(file);
    if (this.stopOnSnapshot()) this.stopWebcam();
  }

  /**
   * Starts the webcam and displays the video stream.
   */
  protected async startWebcam() {
    const videoElement = this.video()?.nativeElement;
    if (this.mediaStreamStarted() || !videoElement) return;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.mediaStream = await navigator.mediaDevices.getUserMedia({video: true});
        videoElement.srcObject = this.mediaStream;
        videoElement.play();
        const trackSettings = this.mediaStream.getVideoTracks()[0].getSettings();
        this.outputSize.update(e => {
          return {
            width: trackSettings.width ?? e.width,
            height: trackSettings.height ?? e.height
          };
        });
        this.mediaStreamStarted.set(true);
        this.isWebcamBlocked.set(false);
      } catch (e) {
        this.toastr.error('Webcam Access Blocked');
        this.isWebcamBlocked.set(true);
        console.error('Error accessing webcam:', e);
      }
    }
  }

  private drawImageToCanvas(image: any) {
    this.canvas()?.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.outputSize().width, this.outputSize().height);
  }
}


import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SelectOptionService {

  /**
   * Create an {@link SelectOption} list out of a data list.
   * @param data Data objects to create from. Objects will also be returned in `SelectOption.data`.
   * @param valueKey Key from the data object to use in `SelectOption.value`
   * @param labelKey Key form the data object to use in `SelectOption.label`
   */
   toOptions<T>(data: T[], valueKey: keyof T, labelKey: keyof T): SelectOption<T>[] {
    const options: SelectOption[] = [];
    data.forEach(e => {
      options.push({
        value: e[valueKey],
        label: String(e[labelKey]),
        data: e
      });
    });
    return options;
  }
}
export interface SelectOption<T = any> {
  data?: T;
  label: string;
  value: any;
}

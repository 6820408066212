import { Component, computed, effect, HostBinding, input, viewChildren } from '@angular/core';
import {
  FormElement,
  InputElement,
  ParagraphElement
} from "src/app/modules/shared/components/dynamic-form/dynamic-form.types";
import { ConditionalEvaluatorService } from "src/app/services/conditional-evaluator.service";
import {
  InputControlMapping,
  InputElementComponent
} from "src/app/modules/shared/components/dynamic-form/form-element/input-element/input-element.component";


@Component({
  selector: 'app-form-element',
  templateUrl: './form-element.component.html',
  styleUrl: './form-element.component.css'
})
export class FormElementComponent {
  formElement = input.required<FormElement>();
  // Input to inform of value changes in the rest of the form.
  valueChanges = input<{[p: string]: any}>();
  protected canShowElement = computed<boolean>(() => {
    if (!this.valueChanges()) return false; // trigger update on valueChanges
    return this.conditionService.evaluateGroups(this.formElement().show_element);
  });
  protected inputElement = computed<InputElement | undefined>(() =>
    this.formElement().element_data.type == "input"
      ? this.formElement().element_data as InputElement
      : undefined
  );
  @HostBinding('hidden') protected isHidden?: boolean;
  protected paragraphElement = computed<ParagraphElement | undefined>(() =>
    this.formElement().element_data.type == "paragraph"
      ? this.formElement().element_data as ParagraphElement
      : undefined
  );
  private viewFormElements = viewChildren(FormElementComponent);
  private viewInputElements = viewChildren(InputElementComponent);
  /** An array of {@link InputControlMapping} for all nested {@link InputElementComponent InputElements}. */
  inputControlMappings = computed<InputControlMapping[]>(() => this.canShowElement() ? [
    ...this.viewInputElements().map(e => e.inputControlMapping()),
    ...this.viewFormElements().flatMap(e => e.inputControlMappings())
  ] : []);

  constructor(private readonly conditionService: ConditionalEvaluatorService) {
    // Toggle component hidden
    effect(() => this.isHidden = !this.canShowElement());
  }
}
